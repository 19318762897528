import ScaleLoader from 'react-spinners/ScaleLoader'
import { LoadingOutlined } from '@ant-design/icons'
import React from 'react'
import Header from '../insightui/Header'
import Slider, {
    LayeredStyle,
    SliderSize,
    calcPositionLeft,
    calcPositionRight,
    getImpactScoreParams,
} from '../insightui/Slider'
import Screening, {
    getScreeningProfileByName,
    getScreeningProfileByAllSelected,
} from '../insightui/Screening'
import Button from '../insightui/Button'
import Table, { TableParams } from '../insightui/Table'
import axios from 'axios'
import { config } from '../models/config'
import { store } from '../store'
import { css } from 'glamor'
import { Search } from '@styled-icons/boxicons-regular/Search'
import { CheckmarkCircle } from '@styled-icons/fluentui-system-regular/CheckmarkCircle'
import { Circle } from '@styled-icons/fluentui-system-regular/Circle'
import { Row, Col } from 'react-flexbox-grid'
import { globals } from '../utils/globals'
import { getPseudoHeaders3 } from '../utils/DummyData'
import { getPseudoSectorData } from '../utils/DummyData'
import { getDefaultScreeningProfiles } from '../utils/DummyData'
import { getPesudoScreeningCategories } from '../utils/DummyData'
import Footer from '../insightui/Footer'
import { TagData } from '../models/state'
import {
    IAdvancedSearchRequest,
    ICategoryFilter,
    IPortfolioHolding,
} from '../models/api'
import { Screens } from '../insightui/Screening'
import { ScreeningProfileItem } from '../models/state'
import { fetchAllProfiles } from '../services/screeningProfileService'
import { toast } from 'react-toastify'

import Modal from '../components/Modal'
import { isMobile, isTablet } from 'react-device-detect'

import { Check2 } from '@styled-icons/bootstrap/Check2'
import { Redo } from '@styled-icons/evil/Redo'
import { Download } from '@styled-icons/feather/Download'
import { Add } from '@styled-icons/fluentui-system-regular/Add'

import { numberFormatter } from '../components/InstrumentDetails/Fundamentals'
import { Input } from '../insightui/Input'
import { checkExport } from '../components/InstrumentDetails/DetailsHeader'
import { Cookies } from 'react-cookie'
import { RouteComponentProps, withRouter } from 'react-router-dom'

const READY: boolean = true
const DEBUG: boolean = false
const DEFAULT: boolean = false

export interface SearchParams {
    placeholder?: string
    style?: Record<string, any>
    fcn?: (e: any) => void
}

interface Exchanges {
    domestic?: boolean
    international?: boolean
}

interface SliderParams {
    width?: number
}

interface Sectors {
    consumerdurables?: boolean
    consumernondurables?: boolean
    commercialservices?: boolean
    consumerservices?: boolean
    communications?: boolean
    distributionservices?: boolean
    electronictechnology?: boolean
    energyminerals?: boolean
    finance?: boolean
    government?: boolean
    healthservices?: boolean
    healthtechnology?: boolean
    industrialservices?: boolean
    retailtrade?: boolean
    miscellaneous?: boolean
    nonenergyminerals?: boolean
    processindustries?: boolean
    producermanufacturing?: boolean
    technologyservices?: boolean
    transportation?: boolean
    utilities?: boolean
}

interface MarketCap {
    megacap?: boolean
    largecap?: boolean
    midcap?: boolean
    smallcap?: boolean
    microcap?: boolean
    nanocap?: boolean
}

interface Types {
    stock?: boolean
    etf?: boolean
    mutualfund?: boolean
    notavailable?: boolean
    preferredshare?: boolean
}

export interface Referential {
    [name: string]: number
}

interface AdvSearchTagProps {
    toggle: boolean
    title: string
    setToggle: (title: string, bool?: boolean) => void
}

export class AdvSearchTag extends React.Component<AdvSearchTagProps> {
    getTagStyles(toggle?: boolean) {
        const isToggled: boolean = toggle ? toggle : false

        const normal: Record<string, any> = {
            marginTop: '0.5em',
            marginRight: '0.5em',
            marginBottom: '0.5em',
            padding: '7px 10px 7px 7px',
            borderTop: '1px solid rgba(0, 0, 0, 0.05)',
            borderLeft: '1px solid rgba(0, 0, 0, 0.05)',
            borderBottom: '1px solid transparent',
            borderRight: '1px solid transparent',
            borderRadius: '4px',
            backgroundColor: '#2c8aaf',
            boxShadow: '1px 1px 5px -3px rgba(0, 0, 0, 0.9)',
            WebkitTransition: 'all 300ms ease',
            transition: 'all 300ms ease',
            fontFamily: "'URW Geometric Cond', sans-serif",
            color: 'white',
            fontWeight: 600,
            textAlign: 'center',
            textDecoration: 'none',
            boxSizing: 'border-box',
            fontSize: 14,
            height: 32,
            cursor: 'pointer',
        }

        const onHover: Record<string, any> = {
            ':mousedown': {
                boxShadow: '-1px -1px 5px -3px rgba(0, 0, 0, 0.9)',
            },
        }

        const onDisable: Record<string, any> = !isToggled
            ? {
                  backgroundColor: 'hsla(196.947, 26.83%, 89.98%, 1)',
                  border: '1px solid transparent',
                  color: 'black',
              }
            : {}

        const onResolution_min300: Record<string, any> = {
            '@media(min-width: 300px)': {},
        }

        return {
            ...normal,
            ...onHover,
            //...onMouseDown,
            ...onDisable,
            ...onResolution_min300,
        }
    }

    render() {
        return (
            <>
                <div
                    {...css({
                        ...globals.styles.presets.noselect,
                        ...this.getTagStyles(this.props.toggle),
                        display: 'inline-block',
                    })}
                    onClick={() => {
                        this.props.setToggle(this.props.title)
                    }}
                >
                    {this.props.toggle ? (
                        <CheckmarkCircle
                            style={{
                                width: 16,
                                marginRight: 5,
                                position: 'relative',
                                bottom: 1,
                            }}
                        />
                    ) : (
                        <Circle
                            style={{
                                width: 16,
                                marginRight: 5,
                                position: 'relative',
                                bottom: 1,
                            }}
                        />
                    )}
                    {this.props.title}
                </div>
            </>
        )
    }
}

class AdvancedSearch extends React.Component<RouteComponentProps> {
    state = {
        searchParams: {
            ticker: '',
        },
        ready: false,
        searching: false,
        createPortfolioLoading: false,
        token: '',
        query: '',
        data: [],
        currentProfile: '',
        table: this.renderTable(),
        screeningProfiles: getDefaultScreeningProfiles(),
        screeningCustom: [],
        impactScore: {
            min: SliderSize.SLIDER_INIT_MIN,
            max: SliderSize.SLIDER_INIT_MAX,
        },
        types: {
            stock: READY,
            etf: DEFAULT,
            mutualfund: DEFAULT,
            notavailable: true,
            preferredshare: true,
        },
        exchanges: { domestic: DEFAULT, international: DEFAULT },
        marketcap: {
            megacap: DEFAULT,
            largecap: DEFAULT,
            midcap: DEFAULT,
            smallcap: DEFAULT,
            microcap: DEFAULT,
            nanocap: DEFAULT,
        },
        sectors: {
            consumerdurables: DEFAULT,
            consumernondurables: DEFAULT,
            commercialservices: DEFAULT,
            consumerservices: DEFAULT,
            communications: DEFAULT,
            distributionservices: DEFAULT,
            electronictechnology: DEFAULT,
            energyminerals: DEFAULT,
            finance: DEFAULT,
            government: DEFAULT,
            healthservices: DEFAULT,
            healthtechnology: DEFAULT,
            industrialservices: DEFAULT,
            retailtrade: DEFAULT,
            miscellaneous: DEFAULT,
            nonenergyminerals: DEFAULT,
            processindustries: DEFAULT,
            producermanufacturing: DEFAULT,
            technologyservices: DEFAULT,
            transportation: DEFAULT,
            utilities: DEFAULT,
        },
        screens: this.getDefaultScreenValues(),
        screensCustom: [],
        createPortfolioModal: false,
        portfolioTitle: '',
        createProfileModal: false,
        profileTitle: '',
        newProfileModal: false,
    }

    getDefaultScreenValues(params?: {
        positive?: Array<string>
        negative?: Array<string>
    }) {
        interface Ref {
            [name: string]: number
        }
        const screens: Ref = {
            ABORTIFACIENTS: Screens.BLANK,
            ABORTION_LEGISLATION: Screens.BLANK,
            ABORTION_PHILANTHROPY: Screens.BLANK,
            ABORTION_PROMOTION: Screens.BLANK,
            ACCESS_AND_AFFORDABILITY_BIC: Screens.BLANK,
            AIR_QUALITY_BIC: Screens.BLANK,
            ALCOHOL: Screens.BLANK,
            ALCOHOL_5PERCENT_REVENUE: Screens.BLANK,
            ANIMAL_TESTING: Screens.BLANK,
            BUSINESS_ETHICS_BIC: Screens.BLANK,
            BUSINESS_MODEL_RESILIENCE_BIC: Screens.BLANK,
            CANNABIS_CBD_ONLY: Screens.BLANK,
            CANNABIS_CULTIVATION_PROCESSING: Screens.BLANK,
            CANNABIS_PHARMACEUTICAL: Screens.BLANK,
            CANNABIS_RETAIL_THC: Screens.BLANK,
            CONTRACEPTIVES_BARRIER: Screens.BLANK, // contraceptives (horminal) on Andrea's version but not found in backend
            CRITICAL_INCIDENT_RISK_MANAGEMENT_BIC: Screens.BLANK,
            CUSTOMER_PRIVACY_BIC: Screens.BLANK,
            CUSTOMER_WELFARE_BIC: Screens.BLANK,
            COMPETITIVE_BEHAVIOR_BIC: Screens.BLANK, // not on frontend
            DATA_SECURITY_BIC: Screens.BLANK,
            ECOLOGICAL_IMPACTS_BIC: Screens.BLANK,
            EMBRYONIC_STEM_CELL: Screens.BLANK,
            EMBRYONIC_STEM_RESEARCH: Screens.BLANK,
            EMBRYONIC_STEM_PRODUCTION: Screens.BLANK,
            EMPLOYEE_ENGAGEMENT_DIVERSITY_AND_INCLUSION_BIC: Screens.BLANK,
            EMPLOYEE_HEALTH_AND_SAFETY_BIC: Screens.BLANK,
            ENERGY_MANAGEMENT_BIC: Screens.BLANK,
            EXPLOITATION: Screens.BLANK,
            GAMBLING: Screens.BLANK,
            GAMBLING_5PERCENT_REVENUE: Screens.BLANK, // not on frontend
            GHG_EMISSIONS_BIC: Screens.BLANK,
            GMO_PRODUCTS: Screens.BLANK,
            HUMAN_RIGHTS_AND_COMMUNITY_RELATIONS_BIC: Screens.BLANK,
            INVITRO_FERTILIZATION: Screens.BLANK,
            LABOR_PRACTICES_BIC: Screens.BLANK,
            LGBTA_LEGISLATION: Screens.BLANK,
            LGBTA_PHILANTHROPY: Screens.BLANK,
            LGBTA_PROMOTION: Screens.BLANK,
            MANAGEMENT_OF_THE_LEGAL_AND_REGULATORY_ENVIRONMENT_BIC:
                Screens.BLANK,
            MATERIALS_SOURCING_AND_EFFICIENCY_BIC: Screens.BLANK,
            NUCLEAR: Screens.BLANK,
            NUCLEAR_5PERCENT_REVENUE: Screens.BLANK, // not on frontend
            PHYSICAL_IMPACTS_OF_CLIMATE_CHANGE_BIC: Screens.BLANK,
            PORNOGRAPHY: Screens.BLANK,
            PRODUCT_DESIGN_AND_LIFECYCLE_MANAGEMENT_BIC: Screens.BLANK,
            PRODUCT_QUALITY_AND_SAFETY_BIC: Screens.BLANK,
            SELLING_PRACTICES_AND_PRODUCT_LABELING_BIC: Screens.BLANK,
            STATE_OWNED_ENTERPRISE: Screens.BLANK,
            SUPPLY_CHAIN_MANAGEMENT_BIC: Screens.BLANK,
            SYSTEMIC_RISK_MANAGEMENT_BIC: Screens.BLANK,
            TOBACCO: Screens.BLANK,
            TOBACCO_5PERCENT_REVENUE: Screens.BLANK, // not on frontend
            WASTE_AND_HAZARDOUS_MATERIALS_MANAGEMENT_BIC: Screens.BLANK,
            WATER_AND_WASTEWATER_MANAGEMENT_BIC: Screens.BLANK,
            WEAPONS_COMPONENTS: Screens.BLANK,
            WEAPONS_COMPONENTS_5PERCENT_REVENUE: Screens.BLANK, // not on frontend
            WEAPONS_MILITARY: Screens.BLANK,
        }

        if (params && params.negative && params.negative.length > 0)
            params.negative.forEach((item: string) => {
                screens[item] = Screens.EXCLUDE
            })

        if (params && params.positive && params.positive.length > 0)
            params.positive.forEach((item: string) => {
                screens[item] = Screens.INCLUDE
            })

        return screens
    }

    componentDidMount() {
        new Cookies().set('noheader', true)

        interface Ref {
            [name: string]: {
                [name: string]: number
            }
        }

        const statekey: string = 'screeningProfiles'
        const title: string = 'Faith Based (Evangelical)'
        this.setScreeningProfileButtonState(statekey, title)
        this.setScreeningProfileByName('Faith Based (Evangelical)')
        this.setState({ ready: READY, token: store.getState().token })
        //screensCustom
        fetchAllProfiles((data: any) => {
            let customs: Ref = {}
            const tagData: TagData[] = []
            data.forEach((datum: any) => {
                customs = {
                    ...customs,
                    ...{
                        [datum.name]: this.getDefaultScreenValues({
                            negative: datum.negative,
                            positive: datum.positive,
                        }),
                    },
                }
                tagData.push({
                    title: datum.name,
                    toggle: false,
                })
            })

            this.setState({
                screeningCustom: tagData,
                screensCustom: customs,
            })
        })
    }

    toTableItem(value: any, prevIndex?: number) {
        const nullPlaceHolder: string = 'N/A'
        const exchangeName: string = value.exchangeLocation.description

        const sectorName: string =
            value.sector !== null ? value.sector.description : nullPlaceHolder

        const ticker: string = value.ticker
        const score: number =
            value.impactScore === '' ? 'N/A' : value.impactScore
        const name: string = value.name
        const type: string = value.type.description
        const sector: string = sectorName
        const marketcap: number = value.marketCapitalization
        const key: number = prevIndex ? prevIndex : 0

        return {
            key: ifNull(key, nullPlaceHolder),
            ticker: ifNull(ticker, nullPlaceHolder),
            name: ifNull(name, nullPlaceHolder),
            score: ifNull(score, nullPlaceHolder),

            /*
        score: (
            <ImpactScore
                withAnimation={false}
                score={score}
                type="small"
                style={{ marginLeft: 50 }}
            ></ImpactScore>
        ),
        */
            //score: ifNull(score, nullPlaceHolder),
            type: ifNull(type, nullPlaceHolder),
            country: exchangeName,
            sector: ifNull(sector, nullPlaceHolder),
            marketcap: ifNull(marketcap, nullPlaceHolder),
        }

        function ifNull(value: any, replacement: string) {
            if (value === null) return replacement
            return value
        }
        function containsKey(data: any, key: string) {
            let match: boolean = false
            if (data)
                Object.keys(data).forEach((fieldName: string) => {
                    if (fieldName === key) match = true
                })
            return match
        }
    }

    toTableRows(response: any) {
        if (!response || !response.data) return []
        const data: any[] = response.data
        let datum: any
        let dataSource: any[] = []
        let index: number = 0
        data.forEach((item) => {
            index = data.indexOf(item)
            datum = this.toTableItem(item, index)
            dataSource.push(datum)
        })

        return dataSource
    }

    handleOnClick = (items: Array<any>) => {
        this.props.history.push(`/${items[0]}`)
    }

    renderTable(params?: TableParams) {
        const style: Record<string, any> = css({
            marginTop: 40,
            ...(params && params.style ? params.style : {}),
        })

        const dataSource: any[] = params && params.data ? params.data : []
        return (
            <div style={{ paddingTop: 40 }}>
                <Table
                    {...style}
                    key={Math.random() * 1000}
                    value={'Advanced Search Results'}
                    style={{
                        marginBottom: globals.sizes.component.verticalSpace,
                    }}
                    headers={getPseudoHeaders3()}
                    onClick={this.handleOnClick}
                    dataSource={dataSource}
                />
            </div>
        )
    }

    getTypes() {
        const types: Types = this.state.types
        let typesFound: string[] = []
        if (types.etf) typesFound.push('EXCHANGE_TRADED_FUND')
        if (types.stock) typesFound.push('COMMON_STOCK')
        if (types.mutualfund) typesFound.push('MUTUAL_FUND')
        //typesFound.push('NOT_AVAILABLE')
        typesFound.push('PREFERRED_SHARE')
        return typesFound
    }

    getExchanges() {
        const exchange: Exchanges = this.state.exchanges
        let typesFound: string[] = []
        if (exchange.domestic) typesFound.push('DOMESTIC')
        if (exchange.international) typesFound.push('INTERNATIONAL')
        return typesFound
    }

    getMarketCap() {
        const marketcap: MarketCap = this.state.marketcap
        let typesFound: string[] = []
        if (marketcap.megacap) typesFound.push('MEGA_CAP')
        if (marketcap.largecap) typesFound.push('LARGE_CAP')
        if (marketcap.midcap) typesFound.push('MID_CAP')
        if (marketcap.smallcap) typesFound.push('SMALL_CAP')
        if (marketcap.microcap) typesFound.push('MICRO_CAP')
        if (marketcap.nanocap) typesFound.push('NANO_CAP')
        return typesFound
    }

    getSectors() {
        const state: Sectors = this.state.sectors
        let sectors: string[] = []
        if (state.consumerdurables) sectors.push('CONSUMER_DURABLES')
        if (state.consumernondurables) sectors.push('CONSUMER_NON_DURABLES')
        if (state.commercialservices) sectors.push('COMMERCIAL_SERVICES')
        if (state.consumerservices) sectors.push('CONSUMER_SERVICES')
        if (state.communications) sectors.push('COMMUNICATIONS')
        if (state.distributionservices) sectors.push('DISTRIBUTION_SERVICES')
        if (state.electronictechnology) sectors.push('ELECTRONIC_TECHNOLOGY')
        if (state.energyminerals) sectors.push('ENERGY_MINERALS')
        if (state.finance) sectors.push('FINANCE')
        if (state.government) sectors.push('GOVERNMENT')
        if (state.healthservices) sectors.push('HEALTH_SERVICES')
        if (state.healthtechnology) sectors.push('HEALTH_TECHNOLOGY')
        if (state.industrialservices) sectors.push('INDUSTRIAL_SERVICES')
        if (state.retailtrade) sectors.push('RETAIL_TRADE')
        if (state.miscellaneous) sectors.push('MISCELLANEOUS')
        if (state.nonenergyminerals) sectors.push('NON_ENERGY_MINERALS')
        if (state.processindustries) sectors.push('PROCESS_INDUSTRIES')
        if (state.producermanufacturing) sectors.push('PRODUCER_MANUFACTURING')
        if (state.technologyservices) sectors.push('TECHNOLOGY_SERVICES')
        if (state.transportation) sectors.push('TRANSPORTATION')
        if (state.utilities) sectors.push('UTILITIES')
        return sectors
    }

    getESGState() {
        const screens: ICategoryFilter[] = []
        const screensObject: {} = this.state.screens
        let value: number, isValid: boolean

        add(screensObject, 'ABORTIFACIENTS')
        add(screensObject, 'ABORTION_LEGISLATION')
        add(screensObject, 'ABORTION_PHILANTHROPY')
        add(screensObject, 'ABORTION_PROMOTION')
        add(screensObject, 'ACCESS_AND_AFFORDABILITY_BIC')
        add(screensObject, 'AIR_QUALITY_BIC')
        add(screensObject, 'ALCOHOL')
        add(screensObject, 'ALCOHOL_5PERCENT_REVENUE')
        add(screensObject, 'ANIMAL_TESTING')
        add(screensObject, 'BUSINESS_ETHICS_BIC')
        add(screensObject, 'BUSINESS_MODEL_RESILIENCE_BIC')
        add(screensObject, 'CANNABIS_CBD_ONLY')
        add(screensObject, 'CANNABIS_CULTIVATION_PROCESSING')
        add(screensObject, 'CANNABIS_PHARMACEUTICAL')
        add(screensObject, 'CANNABIS_RETAIL_THC')
        add(screensObject, 'COMPETITIVE_BEHAVIOR_BIC')
        add(screensObject, 'CONTRACEPTIVES_BARRIER')
        add(screensObject, 'CRITICAL_INCIDENT_RISK_MANAGEMENT_BIC')
        add(screensObject, 'CUSTOMER_PRIVACY_BIC')
        add(screensObject, 'CUSTOMER_WELFARE_BIC')
        add(screensObject, 'DATA_SECURITY_BIC')
        add(screensObject, 'ECOLOGICAL_IMPACTS_BIC')
        add(screensObject, 'EMBRYONIC_STEM_CELL')
        add(screensObject, 'EMBRYONIC_STEM_RESEARCH')
        add(screensObject, 'EMBRYONIC_STEM_PRODUCTION')
        add(screensObject, 'EMPLOYEE_ENGAGEMENT_DIVERSITY_AND_INCLUSION_BIC')
        add(screensObject, 'EMPLOYEE_HEALTH_AND_SAFETY_BIC')
        add(screensObject, 'ENERGY_MANAGEMENT_BIC')
        add(screensObject, 'EXPLOITATION')
        add(screensObject, 'GAMBLING')
        add(screensObject, 'GAMBLING_5PERCENT_REVENUE')
        add(screensObject, 'GHG_EMISSIONS_BIC')
        add(screensObject, 'GMO_PRODUCTS')
        add(screensObject, 'HUMAN_RIGHTS_AND_COMMUNITY_RELATIONS_BIC')
        add(screensObject, 'INVITRO_FERTILIZATION')
        add(screensObject, 'LABOR_PRACTICES_BIC')
        add(screensObject, 'LGBTA_LEGISLATION')
        add(screensObject, 'LGBTA_PHILANTHROPY')
        add(screensObject, 'LGBTA_PROMOTION')
        add(
            screensObject,
            'MANAGEMENT_OF_THE_LEGAL_AND_REGULATORY_ENVIRONMENT_BIC'
        )
        add(screensObject, 'MATERIALS_SOURCING_AND_EFFICIENCY_BIC')
        add(screensObject, 'NUCLEAR')
        add(screensObject, 'NUCLEAR_5PERCENT_REVENUE')
        add(screensObject, 'PHYSICAL_IMPACTS_OF_CLIMATE_CHANGE_BIC')
        add(screensObject, 'PORNOGRAPHY')
        add(screensObject, 'PRODUCT_DESIGN_AND_LIFECYCLE_MANAGEMENT_BIC')
        add(screensObject, 'PRODUCT_QUALITY_AND_SAFETY_BIC')
        add(screensObject, 'SELLING_PRACTICES_AND_PRODUCT_LABELING_BIC')
        add(screensObject, 'STATE_OWNED_ENTERPRISE')
        add(screensObject, 'SUPPLY_CHAIN_MANAGEMENT_BIC')
        add(screensObject, 'SYSTEMIC_RISK_MANAGEMENT_BIC')
        add(screensObject, 'TOBACCO')
        add(screensObject, 'TOBACCO_5PERCENT_REVENUE')
        add(screensObject, 'WASTE_AND_HAZARDOUS_MATERIALS_MANAGEMENT_BIC')
        add(screensObject, 'WATER_AND_WASTEWATER_MANAGEMENT_BIC')
        add(screensObject, 'WEAPONS_COMPONENTS')
        add(screensObject, 'WEAPONS_COMPONENTS_5PERCENT_REVENUE')
        add(screensObject, 'WEAPONS_MILITARY')

        return screens

        // in-scope funtion to minimize code duplication
        function add(screensObject: Referential, name: string) {
            isValid =
                screensObject &&
                (screensObject[name] === Screens.INCLUDE ||
                    screensObject[name] === Screens.EXCLUDE)

            if (isValid) {
                value = screensObject[name]
                const filter: ICategoryFilter = {
                    criterion: name,
                    present: value === Screens.INCLUDE ? true : false,
                }
                screens.push(filter)
            }
        }
    }

    getDateString() {
        const date: any = new Date()
        const year: string = `20${(date.getFullYear() % 100).toString()}`
        const monthNumber = date.getMonth() + 1
        const dayNumber = date.getDay() - 2
        const month: string =
            monthNumber < 10
                ? '0' + monthNumber.toString()
                : monthNumber.toString()
        const day: string =
            dayNumber < 10 ? '0' + dayNumber.toString() : dayNumber.toString()
        let name: string = `${year}-${month}-${day}`
        return name
    }

    getFilename() {
        let name: string =
            'inspire-insight-search-results-' + `${this.getDateString()}.csv`
        return name
    }

    async exportSearchResults(request: IAdvancedSearchRequest, passFcn?: any) {
        const filename: string = this.getFilename()
        axios
            .post(
                `${config.api}/api/export-advanced-search`,
                {
                    search: request,
                    responseType: 'blob',
                    filename: filename,
                },
                {
                    headers: {
                        Authorization: `Bearer ${store.getState().token}`,
                    },
                }
            )
            .then((response: any) => {
                const url = window.URL.createObjectURL(
                    new Blob([response.data])
                )
                const link = document.createElement('a')
                link.href = url
                link.setAttribute('download', filename)
                document.body.appendChild(link)
                link.click()
            })
            .catch((error) => {
                toast.error('You must be a premium user to export!')
            })
    }

    async createPortfolio() {
        this.setState({
            createPortfolioModal: false,
            createPortfolioLoading: true,
        })
        let newHoldings: Array<IPortfolioHolding> = []

        this.state.data.forEach((item: any) => {
            newHoldings.push(item.id)
        })

        if (newHoldings.length > 500) {
            toast.info(
                'Please allow 3-5 minutes for us to process your holdings. We will email you when complete'
            )
        }

        const request = {
            name: this.state.portfolioTitle,
            type: 'individual',
            holdings: newHoldings,
        }

        await axios
            .post(`${config.api}/api/portfolios/holdings-batch`, request, {
                headers: {
                    Authorization: `Bearer ${store.getState().token}`,
                },
            })
            .then((response) => {
                toast.success(
                    'Portfolio has been created! Redirecting to portfolios page.',
                    {
                        onClose: () => {
                            this.props.history.push('/portfolios')
                            this.setState({ createPortfolioLoading: false })
                            window.location.reload()
                        },
                    }
                )
            })
    }

    getHoldingsFromResults() {
        const holdings: string[] = []
        this.state.data.forEach((datum: any) => {
            holdings.push(datum.id)
        })
        return holdings
    }

    createScreeningProfile = () => {
        let profile: any = {
            negative: [],
            positive: [],
            name: this.state.profileTitle,
        }

        for (const [key, value] of Object.entries(this.state.screens)) {
            switch (value) {
                case 2:
                    profile.negative.push(key)
                    break
                case 3:
                    profile.positive.push(key)
                    break
            }
        }

        axios
            .post(
                `${config.api}/api/screening-profiles/create`,
                {
                    headers: {
                        Authorization: `Bearer ${store.getState().token}`,
                    },
                },
                profile
            )
            .then((response) => {
                toast.success('Screen has been created! Reload page...', {
                    onClose: () => window.location.reload(),
                })
            })
            .catch((error) => {
                toast.error('There was an error. Please try again later')
            })
    }

    batchAddToPortfolio(portfolioId: number, test: string[]) {
        axios
            .post(
                `${config.api}/api/portfolios/${portfolioId}/holdings-batch`,
                test,
                {
                    headers: {
                        Authorization: `Bearer ${store.getState().token}`,
                    },
                }
            )
            .then((response) => {})
            .catch((error) => {
                toast.error('Error, please try again later')
            })
    }

    render() {
        const bodyStyle: Record<string, any> = css({
            marginTop: isMobile && !isTablet ? '40' : 0,
            padding: 10,
            paddingRight: 0,
        })

        return (
            <div
                style={{
                    backgroundColor: globals.colors.inspire.background,
                }}
            >
                <Modal isActive={this.state.createPortfolioModal}>
                    <Row>
                        <h2>Create Portfolio</h2>
                    </Row>
                    <Row style={{ padding: 10 }}>
                        <Input
                            placeholder="Portfolio Name"
                            value={this.state.portfolioTitle}
                            width={'100%'}
                            onChange={(e) =>
                                this.setState({ portfolioTitle: e })
                            }
                        />
                    </Row>
                    <Row style={{ marginTop: 20 }}>
                        <Col xs>
                            <Button
                                style={{ width: '90%' }}
                                type="outlined"
                                onClick={() =>
                                    this.setState({
                                        createPortfolioModal: false,
                                    })
                                }
                            >
                                Cancel
                            </Button>
                        </Col>
                        <Col xs>
                            <Button
                                style={{ width: '95%' }}
                                type="primary"
                                onClick={() => this.createPortfolio()}
                            >
                                Create Portfolio
                            </Button>
                        </Col>
                    </Row>
                </Modal>
                <Modal isActive={this.state.createProfileModal}>
                    <Row>
                        <h2>Create Screening Profile</h2>
                    </Row>
                    <Row>
                        <p>
                            Your current selection will be saved as a new
                            screening profile to use later{' '}
                        </p>
                    </Row>

                    <Row style={{ padding: 10 }}>
                        <Input
                            placeholder="Screening Profile Name"
                            value={this.state.profileTitle}
                            width={'100%'}
                            onChange={(e) => this.setState({ profileTitle: e })}
                        />
                    </Row>
                    <Row style={{ marginTop: 20 }}>
                        <Col xs>
                            <Button
                                style={{ width: '90%' }}
                                type="outlined"
                                onClick={() =>
                                    this.setState({
                                        createProfileModal: false,
                                    })
                                }
                            >
                                Cancel
                            </Button>
                        </Col>
                        <Col xs>
                            <Button
                                style={{ width: '95%' }}
                                type="primary"
                                onClick={() => this.createScreeningProfile()}
                            >
                                Create Screening Profile
                            </Button>
                        </Col>
                    </Row>
                </Modal>

                {!this.state.ready ? (
                    <div
                        style={{
                            paddingLeft: '50%',
                            paddingTop: '25%',
                            backgroundColor: 'white',
                        }}
                    >
                        {<ScaleLoader color={'black'} loading={true} />}
                    </div>
                ) : (
                    <>
                        {this.renderComponentHeader()}
                        <div {...bodyStyle}>
                            <Row
                                style={{
                                    position: 'sticky',
                                    backgroundColor: '#F7F7F7',
                                    paddingTop: 10,
                                    paddingRight: 0,
                                    top: isMobile && !isTablet ? 122 : 80,
                                    paddingLeft:
                                        isMobile && !isTablet ? 9 : '0',
                                    zIndex: 3,
                                    paddingBottom: isMobile ? 20 : 0,
                                }}
                            >
                                <Col xs={12} lg={3}>
                                    <p
                                        {...this.getTitleStyles().style}
                                        style={{
                                            margin: 0,
                                            marginTop: 10,
                                            marginBottom: 15,
                                        }}
                                    >
                                        Advanced Search
                                    </p>
                                </Col>
                                <Col
                                    xs={12}
                                    lg={9}
                                    style={{
                                        paddingLeft:
                                            isMobile && !isTablet ? 0 : 8,
                                    }}
                                >
                                    <Button
                                        type="outlined"
                                        style={{
                                            fontSize: isMobile ? 13 : 16,
                                            paddingRight: isMobile ? 5 : 0,
                                            width: isMobile ? 150 : 200,
                                            marginRight: 15,
                                            marginBottom: 10,
                                        }}
                                        onClick={() => {
                                            this.setState({
                                                createPortfolioModal: true,
                                            })
                                        }}
                                        disabled={
                                            this.state.createPortfolioLoading
                                        }
                                    >
                                        {this.state.createPortfolioLoading ? (
                                            <LoadingOutlined
                                                style={{ marginRight: 4 }}
                                            />
                                        ) : (
                                            <Add width={20} />
                                        )}{' '}
                                        Create Portfolio
                                    </Button>
                                    <Button
                                        type="outlined"
                                        style={{
                                            marginRight: 15,
                                            fontSize: isMobile ? 13 : 16,
                                            paddingRight: isMobile ? 5 : 0,
                                            width: isMobile ? 150 : 200,
                                        }}
                                        onClick={() => {
                                            // checkExport() &&
                                            this.exportSearchResults(
                                                this.getSearchRequest()
                                            )
                                        }}
                                    >
                                        <Download width={20} /> Export Results
                                    </Button>
                                    <Button
                                        type="outlined"
                                        style={{
                                            marginRight: 15,
                                            fontSize: isMobile ? 13 : 16,
                                            paddingRight: isMobile ? 5 : 0,
                                            width: isMobile ? 150 : 200,
                                        }}
                                        onClick={() => {
                                            this.resetTagState()
                                            this.resetTable()
                                            this.resetScreening()
                                            this.resetQuery()
                                            this.resetSliderScore() // must follow all other calls
                                        }}
                                    >
                                        <Redo
                                            width={20}
                                            style={{ marginLeft: -4 }}
                                        />{' '}
                                        Reset
                                    </Button>
                                    <Button
                                        type="outlined"
                                        style={{
                                            backgroundColor: '#D17930',
                                            color: 'white',
                                            cursor: 'pointer',
                                            marginRight: 15,
                                            fontSize: isMobile ? 13 : 16,
                                            paddingRight: isMobile ? 5 : 0,
                                            width: isMobile ? 150 : 200,
                                        }}
                                        onClick={() => {
                                            this.setState({ searching: true })
                                            const request: IAdvancedSearchRequest =
                                                this.getSearchRequest()
                                            this.getTableData(
                                                request,
                                                (results: any[]) => {
                                                    if (results.length < 1)
                                                        return
                                                    const Table =
                                                        this.renderTable({
                                                            data: results,
                                                        })
                                                    this.emptyTable() // have to flush table state
                                                    this.setState({
                                                        table: (
                                                            <div>{Table}</div>
                                                        ),
                                                    })
                                                }
                                            )
                                        }}
                                    >
                                        {this.state.searching ? (
                                            <LoadingOutlined
                                                style={{ marginRight: 4 }}
                                            />
                                        ) : (
                                            <Check2 width={20} />
                                        )}{' '}
                                        Generate Results
                                    </Button>
                                </Col>
                            </Row>
                            <Row style={{ paddingLeft: 5 }}>
                                <div>
                                    {this.renderSearch(
                                        this.getSearchParams(
                                            'Security name contains...'
                                        )
                                    )}

                                    {this.renderSlider()}
                                    {this.renderTagsSection({
                                        title: 'Type',
                                        components: this.arrayToTagComponent({
                                            values: this.getTypeData(),
                                            category: 'types',
                                        }),
                                    })}
                                    {this.renderTagsSection({
                                        title: 'Location',
                                        components: this.arrayToTagComponent({
                                            values: this.getExchangeData(),
                                            category: 'exchanges',
                                        }),
                                    })}
                                    {this.renderTagsSection({
                                        title: 'Market Cap',
                                        components: this.arrayToTagComponent({
                                            values: this.getMarketCapData(),
                                            category: 'marketcap',
                                        }),
                                    })}
                                    {this.renderTagsSection({
                                        title: 'Sectors A-Z',
                                        components: this.arrayToTagComponent({
                                            values: getPseudoSectorData(),
                                            category: 'sectors',
                                        }),
                                    })}
                                    {this.renderScreening()}
                                    {this.state.table}
                                </div>
                            </Row>
                            {/*this.renderTable()*/}
                        </div>
                        {this.renderFooter()}
                    </>
                )}
            </div>
        )
    }

    renderSlider(params?: SliderParams) {
        return (
            <Slider
                title={'Impact Score'}
                //width={SliderSize.SLIDER_WIDTH} // min 390
                disableCross={true}
                toParent={(values: number[]) => {
                    this.setState({
                        impactScore: {
                            min: values[0],
                            max: values[1],
                        },
                    })
                }}
                value={{
                    min: this.state.impactScore.min,
                    max: this.state.impactScore.max,
                }}
            />
        )
    }

    getTypeData() {
        return [
            { title: 'Stock', toggle: false },
            { title: 'ETF', toggle: false },
            { title: 'Mutual Fund', toggle: false },
        ]
    }

    getExchangeData() {
        return [
            { title: 'Domestic', toggle: false },
            { title: 'International', toggle: false },
        ]
    }

    getMarketCapData() {
        return [
            { title: 'Mega Cap', toggle: false },
            { title: 'Large Cap', toggle: false },
            { title: 'Mid Cap', toggle: false },
            { title: 'Small Cap', toggle: false },
            { title: 'Micro Cap', toggle: false },
            { title: 'Nano Cap', toggle: false },
        ]
    }

    toAttributeNameFormat(value: string) {
        return value.replace(/ /g, '').replace(/-/g, '').toLowerCase()
    }

    arrayToTagComponent(params: any) {
        interface Ref {
            [name: string]: any
        }
        const values: TagData[] = params.values ? params.values : []
        const category: string = params.category ? params.category : ''
        let title: string = String()

        return values.map((val) => {
            title = this.toAttributeNameFormat(val.title)

            const setToggleState = (key: string) => {
                key = this.toAttributeNameFormat(key)
                const currentState: Ref = this.state
                const currentTypesState: Ref = currentState[category]
                const currentTypesValueState: Ref = currentTypesState[key]

                this.setState({
                    [category]: {
                        ...currentTypesState,
                        [key]: !currentTypesValueState,
                    },
                })
            }

            return (
                <AdvSearchTag
                    title={val.title}
                    toggle={(this.state as Ref)[category][title]}
                    setToggle={setToggleState}
                />
            )
        })
    }

    renderTagsSection(params: any) {
        const styleOverrides: Record<string, any> =
            params && params.style ? params.style : ({} as Record<string, any>)

        const style: Record<string, any> = css({
            backgroundColor: 'transparent',
            width: 'auto',
            display: 'inline-block',
            verticalAlign: 'top',
            marginRight: 15,
            borderBottom: '1px solid ' + globals.colors.inspire.arcpath,
            ...styleOverrides,
        })

        const title: string = params && params.title ? params.title : ''

        return (
            <div {...style}>
                {this.renderTextBox(title)}
                {params.components}
            </div>
        )
    }

    renderTextBox(title: string) {
        return (
            <p
                style={{
                    ...globals.styles.presets.noselect,
                    width: 'auto',
                    display: 'inline-block',
                    paddingRight: 20,
                    ...globals.typeface.advancedSearch.subtitle,
                }}
            >
                {title}
            </p>
        )
    }

    renderSearch(params?: SearchParams) {
        const defaultValue: string = 'Search...'
        const placeholder: any =
            params && params.placeholder ? params.placeholder : defaultValue
        const override: Record<string, any> =
            params && params.style ? params.style : {}

        const onDefault: Record<string, any> = {
            marginTop: 9,
            height: 40,
            width: '59%',
            marginLeft: 10,
            boxShadow: 'none',
            outline: 'none',
            backgroundColor: 'transparent',
            border: 'none',
            verticalAlign: 'top',
            color: globals.colors.inspire.darknavy,
        }

        const fcn: any = params && params.fcn ? params.fcn : () => {}
        return (
            <Row style={{ margin: 0 }}>
                <Col
                    xs={11}
                    sm={6}
                    style={{
                        backgroundColor: 'white',
                        margin: 0,
                    }}
                >
                    <Search
                        width={25}
                        style={{
                            verticalAlign: 'top',
                            margin: 15,
                        }}
                    />

                    <input
                        {...css({
                            ...onDefault,
                            ...globals.typeface.advancedSearch.placeHolder,
                        })}
                        placeholder={placeholder}
                        onChange={fcn}
                        value={this.state.searchParams.ticker}
                    ></input>
                </Col>
            </Row>
        )
    }

    getSliderStyle() {
        const width: number = 500
        const containerStyle: Record<string, any> = css({
            height: 10,
            width: width,
            marginRight: 16,
            marginTop: -25,
            display: 'inline-block',
        })

        const style: LayeredStyle = {
            text: {
                ...globals.typeface.advancedSearch.subtitle,
            },
        }

        return {
            style: style,
            containerStyle: containerStyle,
        }
    }

    async getTableData(request: IAdvancedSearchRequest, passFcn?: any) {
        axios
            .post(`${config.api}/api/advanced-search`, request, {
                headers: {
                    Authorization: `Bearer ${store.getState().token}`,
                },
            })
            .then((response: any) => {
                this.setState({ searching: false })
                const dataSource: any[] = this.toTableRows(response)
                if (response.data) this.setState({ data: response.data })
                if (passFcn) passFcn(dataSource)
                toast.success('Search complete!')
            })
            .catch((error) => toast.error('Error, please try again later'))
    }

    emptyTable() {
        const emptyTable = this.renderTable({ data: [] })
        this.setState({
            table: emptyTable,
        })
    }

    getSearchRequest() {
        const params = getImpactScoreParams()
        let impactMin = calcPositionLeft(
            this.state.impactScore.min,
            params.factor,
            params.subtraction
        )
        let impactMax = calcPositionRight(
            this.state.impactScore.max,
            params.factor,
            params.subtraction
        )

        const query: string = this.state.searchParams.ticker
        const types: string[] = this.getTypes()
        const exchanges: string[] = this.getExchanges()
        const marketcap: string[] = this.getMarketCap()
        const sectors: string[] = this.getSectors()
        const filtersESG: ICategoryFilter[] = this.getESGState()
        if (impactMin <= 0) {
            this.state.screeningCustom.forEach((profile) => {
                if (profile.title.includes('Faith') && profile.toggle) {
                    impactMin = 0
                }
            })
            this.state.screeningProfiles.forEach((profile) => {
                if (profile.title.includes('Faith') && profile.toggle) {
                    impactMin = 0
                }
            })
        }
        const request: IAdvancedSearchRequest = {
            query: query,
            types: types,
            marketCapitalizations: marketcap,
            sectors: sectors,
            impactScoreMinimum: impactMin,
            impactScoreMaximum: impactMax,
            environmentalScoreMinimum: 0,
            environmentalScoreMaximum: 100,
            socialScoreMinimum: 0,
            socialScoreMaximum: 100,
            governanceScoreMinimum: 0,
            governanceScoreMaximum: 100,
            categoryFilters: [...filtersESG],
            listedExchanges: exchanges,
            page: '0',
            size: '10000',
            sort: 'ticker',
            direction: 'ASC',
        }
        return request
    }

    renderComponentHeader() {
        const headerWithRelativeSpacing: Record<string, any> = css({
            width: '100%',
            height: 65,
        })
        return (
            <div {...headerWithRelativeSpacing}>
                <Header logo />
            </div>
        )
    }

    renderFooter() {
        return (
            <div
                style={{
                    paddingTop: 20,
                }}
            >
                <Footer></Footer>
            </div>
        )
    }

    getSearchParams(placeholderValue?: string) {
        const params: SearchParams = {
            placeholder: placeholderValue ? placeholderValue : 'Search...',
            style: {},
            fcn: (e: any) => {
                const val: string = e.target.value
                this.setState({
                    searchParams: {
                        ticker: val,
                    },
                })
            },
        }
        return params
    }

    getTitleStyles() {
        const m: number = 5
        const style: Record<string, any> = css({
            ...globals.typeface.advancedSearch.title,
            width: 'auto',
            height: 30,
            display: 'inline-block',
            verticalAlign: 'bottom',
        })
        const btnOverrides: Record<string, any> = {
            width: 'auto',
            marginLeft: m,
            marginRight: m,
        }
        const firstBtnOvrride: Record<string, any> = {
            ...btnOverrides,
            color: 'white',
            backgroundColor: '#d17930',
        }
        return {
            style: style,
            btnOverrides: btnOverrides,
            firstBtnOvrride: firstBtnOvrride,
        }
    }

    resetTagState() {
        interface Ref {
            [name: string]: any
        }
        const keys: string[] = ['types', 'exchanges', 'marketcap', 'sectors']

        let newState: Ref = this.state

        keys.forEach((key) => {
            Object.keys(newState[key]).forEach((type: string) => {
                newState[key] = false
            })
        })

        this.setState(newState)
    }

    resetQuery() {
        this.setState({
            query: String(),
            searchParams: {
                ticker: '',
            },
        })
    }

    resetSliderScore() {
        // NOTE: this must be called last (for now) as one of the
        // previous grabs previous values before rerender thus conflicting
        // with state update
        this.setState({
            impactScore: {
                min: 0,
                max:
                    SliderSize.SLIDER_WIDTH -
                    SliderSize.SLIDER_TEXTBOX_WIDTH -
                    SliderSize.SLIDER_HANDLE_BAR_SIZE,
            },
        })
    }
    resetTable() {
        this.setState({
            table: this.renderTable(),
        })
    }

    resetScreening() {
        const statekey: string = 'screeningProfiles'
        const title: string = 'Faith Based (Evangelical)'
        this.setScreeningProfileByName(title)
        this.setScreeningProfileButtonState(statekey, title)
    }
    renderResetBtn(override?: Record<string, any>) {
        return this.renderHeaderButton(
            'Reset',
            (e: any) => {
                this.resetTagState()
                this.resetTable()
                this.resetScreening()
                this.resetQuery()
                this.resetSliderScore() // must follow all other calls
            },
            override
        )
    }

    setScreeningProfileByName(name: string) {
        type Ref = {
            [name: string]: any
        }
        const newState: Ref = this.state
        const screeningState: Ref = newState.screens
        const screeningCustomState: Ref = this.state.screensCustom
        const isCustom: boolean = screeningCustomState[name] !== undefined

        if (!isCustom) {
            let profile: any
            if (
                name === 'allOff' ||
                name === 'allInclude' ||
                name === 'allExclude'
            ) {
                switch (name) {
                    case 'allOff':
                        profile = getScreeningProfileByAllSelected('off')
                        break
                    case 'allInclude':
                        profile = getScreeningProfileByAllSelected('include')
                        break
                    case 'allExclude':
                        profile = getScreeningProfileByAllSelected('exclude')
                        break
                }
            } else {
                profile = getScreeningProfileByName(name)
            }

            profile.forEach((template: any) => {
                screeningState[template.title] = template.value
            })
            newState['screens'] = screeningState
        } else {
            newState['screens'] = {
                ...screeningState,
                ...screeningCustomState[name],
            }
        }

        this.setState(newState)
    }

    setScreeningProfileAll = (column: 'include' | 'exclude' | 'off') => {
        const profile = getScreeningProfileByAllSelected(column)
    }

    renderHeaderButton(
        title: string,
        fcn: any,
        overrides?: Record<string, any>
    ) {
        const style: Record<string, any> = {
            verticalAlign: 'top',
            float: 'right',
            borderColor: 'transparent',
            ...overrides,
        }
        return (
            <Button
                onClick={(e?: any) => {
                    if (fcn) fcn(e)
                }}
                type="outlinedGrey"
                style={style}
            >
                {title}
            </Button>
        )
    }

    renderTagData(data: TagData[], params?: any) {
        const overrides: Record<string, any> =
            params && params.overrides ? params.overrides : {}
        let components: any[] = [],
            disabled: boolean = true
        let onClick = params.onClick ? params.onClick : () => {}

        data.forEach((val) => {
            disabled = !val.toggle
            components.push(
                <Button
                    disabled={disabled}
                    style={overrides}
                    type={'tag'}
                    onClickOnDisabled={() => {
                        onClick(val.title)
                    }}
                >
                    {val.toggle ? (
                        <CheckmarkCircle
                            style={{
                                width: 16,
                                marginRight: 5,
                            }}
                        />
                    ) : (
                        <Circle
                            style={{
                                width: 16,
                                marginRight: 5,
                            }}
                        />
                    )}
                    {val.title}
                </Button>
            )
            return
        })
        return components
    }

    renderScreening() {
        const style: Record<string, any> = {
            marginBottom: globals.sizes.component.verticalSpace,
            backgroundColor: 'white',
        }
        const state = this.state as { [name: string]: any }
        const setScreeningProfile = (name: string) => {
            this.setScreeningProfileByName(name)
            this.setState({
                currentProfile: '',
            })
        }

        const setScreeningCustom = (data: TagData[]) => {
            //this.setScreeningProfileByName(name)
            this.setState({
                screeningCustom: data,
            })
        }

        const getProfileData = (profileName: string) => {
            interface Ref {
                [name: string]: any
            }
            const stateRef: Ref = this.state
            return stateRef[profileName]
        }
        return (
            <Screening
                profiles={this.state.screeningProfiles}
                categories={getPesudoScreeningCategories()}
                style={style}
                currentProfile={this.state.currentProfile}
                onSetScreen={(attribute: string, value: number) => {
                    const category: string = 'screens'
                    const screenCategory: { [value: string]: {} } =
                        state[category]

                    this.setState({
                        [category]: {
                            ...screenCategory,
                            ...{ [attribute]: value },
                        },
                    })
                }}
                onNewProfile={() => {
                    this.setState({ createProfileModal: true })
                }}
                getRadioState={(key: string) => {
                    const screensObject: Referential = this.state.screens
                    // falsy should not return false, in this case:
                    // (false is red, true is blue, null is grey)
                    const val: number = screensObject[key]
                        ? screensObject[key]
                        : 1
                    return val
                }}
                setScreeningCustom={setScreeningCustom}
                setScreeningProfile={setScreeningProfile}
                setScreeningProfileButtonState={(
                    title: string,
                    toggle: boolean
                ) => {
                    this.setScreeningProfileButtonState('screeningCustom')

                    this.setScreeningProfileButtonState(
                        'screeningProfiles',
                        'Custom'
                    )
                }}
                renderTagData={(statekey: string, params?: any) => {
                    let data: TagData[] = getProfileData(statekey)

                    params = {
                        ...params,
                        onClick: (title: string) => {
                            this.setScreeningProfileButtonState(
                                'screeningProfiles'
                            )
                            this.setScreeningProfileButtonState(
                                'screeningCustom'
                            )
                            this.setScreeningProfileButtonState(statekey, title)
                            // turn back on after testing
                            //if (title.toLowerCase() !== 'custom')
                            this.setScreeningProfileByName(title)
                        },
                    }
                    return this.renderTagData(data, params)
                }}
            ></Screening>
        )
    }

    setScreeningProfileButtonState(statekey: string, title?: string) {
        interface Ref {
            [name: string]: any
        }

        const stateRef: Ref = this.state
        const key: string = title ? title : ''

        const screeningProfilesUpdated: TagData[] = stateRef[statekey].map(
            (item: any) => {
                if (item.title === key) item.toggle = true
                else item.toggle = false
                return item
            }
        )
        this.setState({
            [statekey]: screeningProfilesUpdated,
        })
    }

    setScreeningProfileValues(profile: ScreeningProfileItem[]) {
        let screensObject: Referential = this.state.screens
        let title: string, value: number
        profile.forEach((item) => {
            title = item.title
            value = item.value
            screensObject[title] = value
        })
        this.setState({ screens: screensObject })
    }
}

function indexOfNested(
    arrayOfObjects: Referential[],
    nestedKey: string,
    searchKey: string
) {
    return (
        arrayOfObjects.map((x: Referential) => {
            return x[nestedKey]
        }) as unknown as string[]
    ).indexOf(searchKey)
}

export default withRouter(AdvancedSearch)
