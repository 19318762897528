import trendingIssues from '../models/trending_issue.json'
import { useEffect, useState } from 'react'
import { RankedComponent } from './RankedComponent'
import { ArrowTrending } from '@styled-icons/fluentui-system-regular/ArrowTrending'
import { Col, Row } from 'react-flexbox-grid'

export default function TrendingIssue() {
    const [data, setData] = useState([])
    useEffect(() => {
        if (data.length === 0) {
            let batches = loadData()
            setData(batches)
        }
    })

    const loadData = () => {
        const BATCH_SIZE = 5
        let resultSet = []
        while (trendingIssues['companies'].length) {
            resultSet.push(trendingIssues['companies'].splice(0, 5))
        }
        return resultSet
    }

    return (
        <>
            <Row style={{ marginTop: 30 }}>
                <Col xs style={{ textAlign: 'center' }}>
                    <h3 style={{ fontWeight: 350 }}>
                        <ArrowTrending width={30} style={{ marginRight: 10 }} />
                        Trending Issue:{' '}
                        <strong>Companies Paying For Abortion Travel</strong>
                    </h3>
                </Col>
            </Row>
            <Row>
                {data.length > 0 ? (
                    <>
                        {data.map((batch) => (
                            <Col xs={12} md={6} lg={4}>
                                <RankedComponent
                                    items={batch}
                                    onClick={() => {}}
                                    disableAll
                                />
                            </Col>
                        ))}
                    </>
                ) : (
                    <></>
                )}
            </Row>
        </>
    )
}
