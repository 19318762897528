import { CSSProperties } from 'react'

import React from 'react'

type TagColors = 'blue' | 'red' | 'navy'

interface TagProps {
    color: TagColors
    width?: number
    style?: Record<string, unknown>
    height?: number
    fullWidth?: boolean
}

const tagStyles: Record<string, unknown> = {
    padding: 7,
    color: 'white',
    fontWeight: 800,
    fontSize: 14,
}

const tagColoredStyles: Record<string, any> = {
    blue: {
        ...tagStyles,
        backgroundColor: '#2c8aae',
    },
    red: {
        ...tagStyles,
        backgroundColor: '#be4259',
    },
    navy: {
        ...tagStyles,
        backgroundColor: '#0b192b',
    },
}

export default class Tag extends React.Component<TagProps> {
    getStyle = () => {
        const extraStyles = {
            height: this.props.height,
            width: this.props.width,
        }
        let style = { ...extraStyles, ...tagColoredStyles[this.props.color] }

        return this.props.fullWidth ? { ...style, ...{ width: '100%' } } : style
    }

    render() {
        return (
            <span style={{ ...this.getStyle(), ...this.props.style }}>
                {this.props.children}
            </span>
        )
    }
}
