import React from 'react'
import { css } from 'glamor'
import { globals } from '../utils/globals'
import { isMobile } from 'react-device-detect'

const STATE: { min: number; max: number } = {
    min: 0,
    max: 0,
}

enum HandleBar {
    LEFT,
    RIGHT,
}

export enum SliderSize {
    SLIDER_WIDTH = 390,
    SLIDER_TEXTBOX_WIDTH = 150,
    SLIDER_HANDLE_BAR_SIZE = 40,
    SLIDER_INIT_MIN = 0,
    SLIDER_INIT_MAX = SliderSize.SLIDER_WIDTH -
        SliderSize.SLIDER_TEXTBOX_WIDTH -
        SliderSize.SLIDER_HANDLE_BAR_SIZE,
}

export interface LayeredStyle {
    container?: Record<string, any>
    text?: Record<string, any>
}

export interface SliderParams {
    w: number
    h: number
    tw: number
    x: number
    size: number
    max: number
    factor: number
    subtraction: number
}

export function getImpactScoreParams() {
    const w: number = SliderSize.SLIDER_WIDTH
    const tw: number = SliderSize.SLIDER_TEXTBOX_WIDTH // width of title component
    const x: number = SliderSize.SLIDER_HANDLE_BAR_SIZE
    const max: number = w - tw - x * 2
    const factor: number = 200 / max
    const subtraction: number = ((x * 100) << 1) / max + 100
    const h: number = 63
    const size: number = w - tw
    return {
        h: h,
        w: w,
        tw: tw,
        x: x,
        size: size,
        max: max,
        factor: factor,
        subtraction: subtraction,
    }
}

export function calcPositionLeft(
    value: number,
    factor: number,
    subtraction: number
) {
    const diff: number = 50
    const val: number = value * factor - subtraction + diff
    return Math.ceil(val)
}

export function calcPositionRight(
    value: number,
    factor: number,
    subtraction: number
) {
    const val: number = value * factor - subtraction
    return Math.ceil(val)
}

function getPositionRight(
    prev: number,
    offset: number,
    size: number,
    sibling?: number
) {
    // if value for sibling is added, the moving component
    // will not cross paths
    const siblingVal: number = sibling ? sibling : 0
    const hasSibling: boolean = sibling || sibling === 0 ? true : false
    if (hasSibling && prev + offset < 40) return 40
    if (hasSibling && prev + offset < siblingVal + 40) return siblingVal + 40
    if (prev + offset > size - 40) return size - 40
    if (prev + offset < 0) return 0
    return prev + offset
}

function getPositionLeft(
    prev: number,
    offset: number,
    size: number,
    sibling?: number
) {
    // if value for sibling is added, the moving component
    // will not cross paths
    const siblingVal: number = sibling ? sibling : 0
    const hasSibling: boolean = sibling || sibling === 0 ? true : false
    if (hasSibling && prev + offset > size - 80) return size - 80
    if (hasSibling && prev + offset > siblingVal - 40) return siblingVal - 40
    if (prev + offset > size - 40) return size - 40
    if (prev + offset < 0) return 0
    return prev + offset
}

interface SliderProps {
    title?: string
    value: { min: number; max: number }
    toParent?: (values: number[]) => void
    disableCross?: boolean
}

export default class Slider extends React.Component<SliderProps> {
    state = {
        offset: 0,
        x1: this.props.value.min,
        x2: this.props.value.max,
    }

    componentDidMount() {
        STATE.min = this.props.value.min
        STATE.max = this.props.value.max

        const params: SliderParams = getImpactScoreParams()

        const valR: number = this.formatHandleBarOutputRight(STATE.max, {
            max: params.max,
            x: params.x,
            factor: params.factor,
            subtraction: params.subtraction,
        })

        const valL: number = this.formatHandleBarOutputLeft(STATE.min, {
            max: params.max,
            x: params.x,
            factor: params.factor,
            subtraction: params.subtraction,
        })

        const handleBarL: any = document.getElementById('leftHandleBar')
        if (handleBarL) {
            handleBarL.childNodes[0].innerHTML = valL.toString()
        }
        const handleBarR: any = document.getElementById('rightHandleBar')
        if (handleBarR) {
            handleBarR.childNodes[0].innerHTML = valR.toString()
        }
    }

    render() {
        STATE.min = this.props.value.min
        STATE.max = this.props.value.max

        const title: string = this.props.title ? this.props.title : String()
        const disableCross: boolean = this.props.disableCross
            ? this.props.disableCross
            : false

        const params: SliderParams = getImpactScoreParams()

        const outSourceL = () => {
            return this.formatHandleBarOutputLeft(STATE.min, {
                max: params.max,
                x: params.x,
                factor: params.factor,
                subtraction: params.subtraction,
            })
        }
        const outSourceR = () => {
            return this.formatHandleBarOutputRight(STATE.max, {
                max: params.max,
                x: params.x,
                factor: params.factor,
                subtraction: params.subtraction,
            })
        }

        return (
            <div
                style={{
                    width: params.w,
                    height: params.h,
                    backgroundColor: 'transparent',
                    position: 'relative',
                    overflow: 'hidden',
                    display: 'inline-block',
                    borderBottom: `1px solid ${globals.colors.inspire.arcpath}`,
                    marginRight: 20,
                }}
            >
                <p
                    style={{
                        ...globals.styles.presets.noselect,
                        ...globals.typeface.advancedSearch.subtitle,
                        width: params.tw,
                        display: 'inline-block',
                        backgroundColor: 'transparent',
                        verticalAlign: 'top',
                    }}
                >
                    {title}
                </p>
                <div
                    style={{
                        width: params.w - params.tw,
                        height: params.h,
                        backgroundColor: 'transparent',
                        display: 'inline-block',
                        verticalAlign: 'top',
                        float: 'right',
                    }}
                >
                    <div
                        style={{
                            position: 'absolute',
                            width: params.w - params.tw,
                            height: params.h,
                            backgroundColor: 'transparent',
                        }}
                    >
                        <div
                            style={{
                                top: params.h / 2 - 2,
                                width: params.w - params.tw - params.x * 2,
                                height: 4,
                                marginLeft: params.x,
                                backgroundColor: globals.colors.positive.blue,
                                position: 'absolute',
                            }}
                        ></div>

                        <div
                            id={'leftHandleBar'}
                            style={{
                                ...globals.styles.presets.noselect,
                                top: params.h / 2 - (params.x >> 1),
                                width: params.x,
                                height: params.x,
                                backgroundColor: 'white',
                                position: 'absolute',
                                cursor: 'pointer',
                                left: this.props.value.min,
                            }}
                            onMouseDown={(e: any) => {
                                e = e || window.event
                                const start: any = e.pageX || e.clientX

                                this.onMouseDown(
                                    outSourceL,
                                    outSourceR,
                                    start,
                                    params.size,
                                    HandleBar.LEFT,
                                    disableCross
                                )
                            }}
                            onPointerDown={(e: any) => {
                                e = e || window.event
                                const start: any = e.pageX || e.clientX

                                this.onMouseDown(
                                    outSourceL,
                                    outSourceR,
                                    start,
                                    params.size,
                                    HandleBar.LEFT,
                                    disableCross
                                )
                            }}
                        >
                            <p
                                {...css({
                                    ...this.getStyleHandleBars(),
                                    ...globals.styles.presets.noselect,
                                })}
                            >
                                {this.formatHandleBarOutputLeft(STATE.min, {
                                    max: params.max,
                                    x: params.x,
                                    factor: params.factor,
                                    subtraction: params.subtraction,
                                })}
                                {/*STATE.min*/}
                            </p>
                        </div>
                        <div
                            id={'rightHandleBar'}
                            style={{
                                ...globals.styles.presets.noselect,
                                top: params.h / 2 - (params.x >> 1),
                                width: params.x,
                                height: params.x,
                                backgroundColor: 'white',
                                position: 'absolute',
                                cursor: 'pointer',
                                left: this.props.value.max,
                            }}
                            onMouseDown={(e: any) => {
                                e = e || window.event
                                const start: any = e.pageX || e.clientX
                                this.onMouseDown(
                                    outSourceL,
                                    outSourceR,
                                    start,
                                    params.size,
                                    HandleBar.RIGHT,
                                    disableCross
                                )
                            }}
                            onPointerDown={(e: any) => {
                                e = e || window.event
                                const start: any = e.pageX || e.clientX
                                this.onMouseDown(
                                    outSourceL,
                                    outSourceR,
                                    start,
                                    params.size,
                                    HandleBar.RIGHT,
                                    disableCross
                                )
                            }}
                        >
                            <p
                                {...css({
                                    ...this.getStyleHandleBars(),
                                    ...globals.styles.presets.noselect,
                                })}
                            >
                                {this.formatHandleBarOutputRight(
                                    this.props.value.max,
                                    {
                                        max: params.max,
                                        x: params.x,
                                        factor: params.factor,
                                        subtraction: params.subtraction,
                                    }
                                )}
                                {/*STATE.max*/}
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        )
    }

    onMouseDown(
        fcnPassedL: any,
        fcnPassedR: any,
        start: number,
        size: number,
        enumId: number,
        disableCross?: boolean
    ) {
        const isLeft: boolean = HandleBar.LEFT === enumId
        const prevX: number = isLeft ? STATE.min : STATE.max
        const siblingX: number = isLeft ? STATE.max : STATE.min
        const key: string = isLeft ? 'min' : 'max'
        const id: string = isLeft ? 'leftHandleBar' : 'rightHandleBar'

        const conditionalFunction = isLeft ? getPositionLeft : getPositionRight

        const setState = (offset: number) => {
            const val: number = conditionalFunction(
                prevX,
                offset,
                size,
                disableCross ? siblingX : undefined
            )

            const handleBar: any = document.getElementById(id)
            const TYPESAFE_STATE: { [name: string]: number } = STATE
            if (handleBar) {
                handleBar.style.left = `${val}px`
                handleBar.style.pointerEvents = 'none'
                TYPESAFE_STATE[key] = val
            }
        }

        const updateState = () => {
            if (this.props.toParent) this.props.toParent([STATE.min, STATE.max])
        }

        document.body.onpointermove = function (e: any) {
            e = e || window.event
            setState(e.pageX - start)

            const handleBar: any = document.getElementById(id)
            handleBar.childNodes[0].innerHTML = isLeft
                ? fcnPassedL(STATE.min)
                : fcnPassedR(STATE.max)
            if (isMobile) {
                document.body.style.overflow = 'hidden'
            }
        }
        document.body.onpointerup = function (e: any) {
            const handleBar: any = document.getElementById(id)
            if (handleBar !== null) {
                handleBar.style.pointerEvents = ''
            }
            document.body.onpointermove = () => {}
            document.body.style.overflow = 'visible'
            updateState()
        }

        setState(0)
    }

    formatHandleBarOutputLeft(
        value: number,
        precompute: {
            max: number
            x: number
            factor: number
            subtraction: number
        }
    ) {
        const val: number = calcPositionLeft(
            value,
            precompute.factor,
            precompute.subtraction
        )
        return val
    }

    formatHandleBarOutputRight(
        value: number,
        precompute: {
            max: number
            x: number
            factor: number
            subtraction: number
        }
    ) {
        const val: number = calcPositionRight(
            value,
            precompute.factor,
            precompute.subtraction
        )
        return val
    }

    getStyleHandleBars() {
        const implicitMarginTop: number = 13
        return {
            ...globals.typeface.advancedSearch.handles,
            verticalAlign: 'top',
            margin: 0,
            paddingTop: implicitMarginTop,
            height: `calc(100% - ${implicitMarginTop}px)`,
        }
    }
}
