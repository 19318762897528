import React from 'react'
import { Row, Col } from 'react-flexbox-grid'
import { esgCriterionCategories, esgCategories } from './ESGCriterion'
import Count from './Count'
import { IEsgCriterionCount } from '../../models/api'

import { ChevronUp } from '@styled-icons/bootstrap/ChevronUp'
import { ChevronDown } from '@styled-icons/bootstrap/ChevronDown'
import { config } from '../../models/config'
import axios from 'axios'
import { IDetailsAPI } from '../../models/api'

const categoryStyle: Record<string, unknown> = {}

type CategoryEnvironmentType = 'positive' | 'negative'

interface CategoryProps {
    environment: CategoryEnvironmentType
    esgCriterion: {
        negative: esgCriterionCategories
        positive: esgCriterionCategories
    }
    title: string
    allCategories?: boolean
    fiId: number
    displayDetails: (details: IDetailsAPI) => void
}

export default class Category extends React.Component<CategoryProps> {
    state: { collapsed: boolean } = {
        collapsed: true,
    }

    handleDetails = (category: string) => {
        axios
            .get(
                `${config.api}/api/tickers/${this.props.fiId}/esg-issues/${category}?page=0&&size=50`
            )
            .then((response) => {
                this.props.displayDetails(response.data as IDetailsAPI)
            })
    }
    renderCategoryLabel = () => {
        let categoryTotal =
            this.props.esgCriterion[this.props.environment][
                this.props.title.toLowerCase() as esgCategories
            ].length
        return categoryTotal + (categoryTotal > 1 ? ' Categories' : ' Category')
    }
    render() {
        const { environment, esgCriterion, title, allCategories } = this.props

        return (
            <>
                {esgCriterion[environment][title.toLowerCase() as esgCategories]
                    .length === 0 ? (
                    <></>
                ) : (
                    <div
                        style={{
                            backgroundColor: 'white',
                            padding: '20px',
                            borderBottom: '1px solid #e1e8ed',
                        }}
                    >
                        <Row
                            style={{
                                marginBottom: this.state.collapsed ? 0 : 15,
                            }}
                        >
                            <Col xs>
                                <span
                                    style={{
                                        fontWeight: 900,
                                        fontSize: 16,
                                        fontFamily: 'URW Geometric Cond',
                                    }}
                                >
                                    {title}
                                </span>
                            </Col>
                            <Col
                                xs
                                style={{
                                    textAlign: 'right',
                                }}
                            >
                                <span
                                    style={{
                                        fontWeight: 600,
                                        fontSize: 12,
                                        textTransform: 'uppercase',
                                        letterSpacing: 2,
                                        color:
                                            environment === 'positive'
                                                ? '#2b89ad'
                                                : '#bf435a',
                                        cursor: 'pointer',
                                    }}
                                    onClick={() =>
                                        this.setState({
                                            collapsed: !this.state.collapsed,
                                        })
                                    }
                                >
                                    {this.renderCategoryLabel()}
                                </span>
                            </Col>
                            <Col>
                                <button
                                    style={{
                                        borderRadius: '50%',
                                        backgroundColor: 'inherit',
                                        border: 0,
                                        cursor: 'pointer',
                                    }}
                                    onClick={() =>
                                        this.setState({
                                            collapsed: !this.state.collapsed,
                                        })
                                    }
                                >
                                    {this.state.collapsed ? (
                                        <ChevronDown width={20} />
                                    ) : (
                                        <ChevronUp width={20} />
                                    )}
                                </button>
                            </Col>
                        </Row>
                        {this.state.collapsed ? (
                            <></>
                        ) : (
                            <>
                                {esgCriterion[environment][
                                    title.toLowerCase() as esgCategories
                                ].map((item: IEsgCriterionCount) => (
                                    <Count
                                        item={item}
                                        environment={environment}
                                        handleDetails={this.handleDetails}
                                    />
                                ))}{' '}
                            </>
                        )}
                    </div>
                )}
            </>
        )
    }
}
