import React from 'react'
import { Row, Col } from 'react-flexbox-grid'
import Button from '../../insightui/Button'
import { config } from '../../models/config'
import { fetchPlanTitle, globals } from '../../utils/globals'
import { css } from 'glamor'
import { store } from '../../store'
import { IStockApi } from '../../models/api'
import ImpactScore from '../../insightui/ImpactScore'
import { isMobile } from 'react-device-detect'
import { CSVLink } from 'react-csv'
import { Plus } from '@styled-icons/feather/Plus'
import { ArrowDownload } from '@styled-icons/fluentui-system-regular/ArrowDownload'
import { Link, RouteComponentProps } from 'react-router-dom'
import { toast } from 'react-toastify'

export const checkExport = (event?: any, history?: any) => {
    const planTitle = fetchPlanTitle()
    const customToastWithLink = () => (
        <div>
            You must <Link to="/checkout">upgrade to a Premium Account</Link>{' '}
            for this feature
        </div>
    )

    if (['Administrator', 'Pro'].includes(planTitle)) {
        return true
    } else {
        if (history) {
            history.push('/checkout')
        } else {
            window.location.href = `${config.host}/checkout`
        }
        toast.info(customToastWithLink)
        return false
    }
}

interface DetailsHeaderProps extends RouteComponentProps {
    instrumentDetails: IStockApi
    description?: any
    mode: 'security' | 'fund' | 'portfolio'
    constituents: any
    onAdd?: () => void
}

export class DetailsHeader extends React.Component<DetailsHeaderProps> {
    state = {
        expanded: false,
        impactScore: {
            value: <>test</>,
            isSet: false,
        },
        addTo: false,
        portfolios: [],
    }

    renderImpactScoreSingleton(score?: number) {
        if (!this.state.impactScore.isSet) {
            this.setState({
                impactScore: {
                    value: (
                        <ImpactScore
                            footer
                            withAnimation
                            score={score}
                            type="large"
                            bgColor={'rgb(251, 239, 229)'}
                        />
                    ),
                    isSet: true,
                },
            })
        } else return this.state.impactScore.value
    }

    render() {
        const { instrumentDetails, description } = this.props
        return (
            <>
                <Row>
                    <Col xs={12} sm={8}>
                        <Row
                            style={{
                                paddingTop: 50,
                            }}
                        >
                            {/* {isMobile ? <Col xs /> : <></>} */}
                            <Col
                                xs={12}
                                style={{
                                    paddingLeft: 20,
                                    display: 'block',
                                }}
                            >
                                {instrumentDetails.impactScore ? (
                                    this.renderImpactScoreSingleton(
                                        instrumentDetails.impactScore
                                    )
                                ) : (
                                    <></>
                                )}
                                <h1
                                    {...css({
                                        ...globals.typeface.tickerBoldUppercase,
                                        margin: 0,
                                        marginTop: -2,
                                        marginBottom: -5,
                                    })}
                                >
                                    {instrumentDetails.ticker}
                                </h1>
                                <span
                                    {...css(
                                        globals.typeface.tickerTitleUppercase
                                    )}
                                >
                                    {instrumentDetails.name}
                                    {!instrumentDetails.isDomestic &&
                                    instrumentDetails.exchangeLocation
                                        ? ' (' +
                                          instrumentDetails.exchangeLocation
                                              .description +
                                          ')'
                                        : ''}
                                </span>
                            </Col>
                        </Row>
                        {description && (
                            <Row
                                style={{
                                    paddingTop: 20,
                                    fontWeight: 400,
                                    paddingRight: 50,
                                    fontSize: 15,
                                    lineHeight: 1.5,
                                    paddingBottom: 20,
                                }}
                            >
                                <span>
                                    {!this.state.expanded ? (
                                        <>{description.slice(0, 350)}</>
                                    ) : (
                                        description
                                    )}
                                    {description.length > 350 ? (
                                        <>
                                            {!this.state.expanded ? (
                                                <>
                                                    ...{' '}
                                                    <span
                                                        style={{
                                                            color: '#2c8aae',
                                                            cursor: 'pointer',
                                                        }}
                                                        onClick={() => {
                                                            this.setState({
                                                                expanded: true,
                                                            })
                                                        }}
                                                    >
                                                        Read More
                                                    </span>
                                                </>
                                            ) : (
                                                <span
                                                    style={{
                                                        color: '#2c8aae',
                                                        cursor: 'pointer',
                                                    }}
                                                    onClick={() =>
                                                        this.setState({
                                                            expanded: false,
                                                        })
                                                    }
                                                >
                                                    {' '}
                                                    Less
                                                </span>
                                            )}
                                        </>
                                    ) : (
                                        <></>
                                    )}
                                </span>
                            </Row>
                        )}
                    </Col>
                    <Col xs={12} sm={4}>
                        {/* <Button type="outlined" style={{ width: 300 }}>
                        Start An Engagement Campaign
                    </Button> */}
                        {isMobile ? (
                            <></>
                        ) : (
                            <>
                                <Button
                                    type="outlined"
                                    style={{ width: 300, marginTop: 15 }}
                                    onClick={() => {
                                        if (store.getState().token) {
                                            if (this.props.onAdd)
                                                this.props.onAdd()
                                        } else {
                                            this.props.history.push(
                                                '/plans-and-pricing'
                                            )
                                        }
                                    }}
                                    icon={<Plus width={25} />}
                                >
                                    Add To Portfolio
                                </Button>
                                {this.props.mode === 'fund' ? (
                                    <CSVLink
                                        data={this.props.constituents}
                                        filename={`${
                                            this.props.instrumentDetails.ticker
                                        }-holdings-${new Date().toLocaleDateString()}.csv`}
                                        onClick={() =>
                                            checkExport('', this.props.history)
                                        }
                                    >
                                        <Button
                                            type="outlined"
                                            style={{
                                                width: 300,
                                                marginTop: 15,
                                            }}
                                            icon={<ArrowDownload width={20} />}
                                        >
                                            Export Holdings
                                        </Button>
                                    </CSVLink>
                                ) : (
                                    <></>
                                )}
                            </>
                        )}
                    </Col>
                </Row>
                {isMobile ? (
                    <>
                        <Row>
                            <Col xs>
                                <Button
                                    type="outlined"
                                    style={{
                                        marginTop: 15,
                                        width: '100%',
                                    }}
                                    onClick={() => {
                                        if (store.getState().isLogged) {
                                            if (this.props.onAdd)
                                                this.props.onAdd()
                                        } else {
                                            this.props.history.push('/login')
                                        }
                                    }}
                                >
                                    Add To Portfolio
                                </Button>
                            </Col>
                        </Row>
                    </>
                ) : (
                    <></>
                )}
            </>
        )
    }
}
