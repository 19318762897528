import { Component } from 'react'
import { Screens } from '../insightui/Screening'
import ImpactScore from '../insightui/ImpactScore'
import { numberFormatter } from '../components/InstrumentDetails/Fundamentals'
import { isMobile } from 'react-device-detect'

export default class Globals extends Component {}

export function getPseudoHeaders() {
    const columns = [
        {
            title: 'Name',
            dataIndex: 'name',
            key: 'name',
        },
        {
            title: 'Age',
            dataIndex: 'age',
            key: 'age',
        },
        {
            title: 'Address',
            dataIndex: 'address',
            key: 'address',
        },
    ]
    return columns
}

export function getPseudoData() {
    let key: number = 0
    const dataSource = [
        {
            key: key++,
            name: 'Mike',
            age: 32,
            address: `${key} Downing Street`,
        },
        {
            key: key++,
            name: 'John',
            age: 42,
            address: `${key} Downing Street`,
        },
        {
            key: key++,
            name: 'Dave',
            age: 42,
            address: `${key} Downing Street`,
        },
        {
            key: key++,
            name: 'Mike',
            age: 32,
            address: `${key} Downing Street`,
        },
        {
            key: key++,
            name: 'John',
            age: 42,
            address: `${key} Downing Street`,
        },
        {
            key: key++,
            name: 'Dave',
            age: 42,
            address: `${key} Downing Street`,
        },
        {
            key: key++,
            name: 'Mike',
            age: 32,
            address: `${key} Downing Street`,
        },
        {
            key: key++,
            name: 'John',
            age: 42,
            address: `${key} Downing Street`,
        },
        {
            key: key++,
            name: 'Dave',
            age: 42,
            address: `${key} Downing Street`,
        },
        {
            key: key++,
            name: 'Mike',
            age: 32,
            address: `${key} Downing Street`,
        },
        {
            key: key++,
            name: 'John',
            age: 42,
            address: `${key} Downing Street`,
        },
        {
            key: key++,
            name: 'Dave',
            age: 42,
            address: `${key} Downing Street`,
        },
        {
            key: key++,
            name: 'Last',
            age: 42,
            address: `${key} Downing Street`,
        },
    ]
    return dataSource
}

export function getPseudoHeaders3() {
    const columns = [
        {
            title: 'Ticker',
            dataIndex: 'ticker',
            key: 'ticker',
            format: (ticker: string) => {
                return (
                    <a
                        style={{
                            color: '#2d8aaf',
                            textDecoration: 'underline',
                            cursor: 'pointer',
                        }}
                    >
                        {ticker}
                    </a>
                )
            },
        },
        {
            title: 'Name',
            dataIndex: 'name',
            key: 'name',
            format: (value: string) => {
                if (value.length > 25) {
                    return value.slice(0, 25) + '...'
                } else {
                    return value
                }
            },
        },
        {
            title: 'Type',
            dataIndex: 'type',
            key: 'type',
        },
        {
            title: 'Country',
            dataIndex: 'country',
            key: 'country',
        },
        {
            title: 'Sector',
            dataIndex: 'sector',
            key: 'sector',
        },
        {
            title: 'Market Cap',
            dataIndex: 'marketcap',
            key: 'marketcap',
            format: (number: number, row: any) =>
                number ? '$' + numberFormatter(number, 'bid') : '-',
        },
        {
            title: 'Score',
            dataIndex: 'score',
            key: 'score',
            format: (value: any) => {
                return (
                    <ImpactScore
                        withAnimation={false}
                        score={value}
                        type="small"
                        style={{ marginLeft: 50 }}
                    ></ImpactScore>
                )
            },
        },
    ]
    const mobileColumns = [
        {
            title: 'Ticker',
            dataIndex: 'ticker',
            key: 'ticker',
            format: (ticker: string) => {
                return (
                    <a
                        style={{
                            color: '#2d8aaf',
                            textDecoration: 'underline',
                            cursor: 'pointer',
                        }}
                    >
                        {ticker}
                    </a>
                )
            },
        },
        {
            title: 'Score',
            dataIndex: 'score',
            key: 'score',
            format: (value: any) => {
                return (
                    <ImpactScore
                        withAnimation={false}
                        score={value}
                        type="small"
                        style={{ marginLeft: 50 }}
                    ></ImpactScore>
                )
            },
        },
    ]
    return isMobile ? mobileColumns : columns
}

export function getPseudoHeaders2() {
    const columns = [
        {
            title: 'Ticker',
            dataIndex: 'ticker',
            key: 'ticker',
        },
        {
            title: 'Score',
            dataIndex: 'score',
            key: 'score',
        },
        {
            title: 'Name',
            dataIndex: 'name',
            key: 'name',
        },
        {
            title: 'Holding Percentage',
            dataIndex: 'holdingpercentage',
            key: 'holdingpercentage',
        },
    ]
    return columns
}

export function getPseudoData2() {
    return [
        {
            key: 0,
            ticker: 'TFX',
            name: 'Teleflex Incorporated',
            score: 32,
            holdingpercentage: 0.34607688,
        },
        {
            ticker: 'EQIX',
            name: 'Equinix Inc',
            score: 58,
            holdingpercentage: 1.4659181,
            key: 1,
        },
        {
            ticker: 'RF',
            name: 'Regions Financial Corp.',
            score: 58,
            holdingpercentage: 0.39774838,
            key: 2,
        },
        {
            ticker: 'GPN',
            name: 'Global Payments, Inc.',
            score: 28,
            holdingpercentage: 1.0185617,
            key: 3,
        },
        {
            ticker: 'CSX',
            name: 'CSX Corp.',
            score: 39,
            holdingpercentage: 1.5399756,
            key: 4,
        },
        {
            ticker: 'JBHT',
            name: 'J.B. Hunt Transport Services, Inc.',
            score: 47,
            holdingpercentage: 0.3715561,
            key: 5,
        },
        {
            ticker: 'COST',
            name: 'Costco Wholesale Corp',
            score: 28,
            holdingpercentage: 3.9915903,
            key: 6,
        },
        {
            ticker: 'GRMN',
            name: 'Garmin Ltd',
            score: 44,
            holdingpercentage: 0.6485484,
            key: 7,
        },
        {
            ticker: 'WM',
            name: 'Waste Management, Inc.',
            score: 36,
            holdingpercentage: 1.2814242,
            key: 8,
        },
        {
            ticker: 'ARE',
            name: 'Alexandria Real Estate Equities Inc.',
            score: 56,
            holdingpercentage: 0.6290686,
            key: 9,
        },
        {
            ticker: 'EMR',
            name: 'Emerson Electric Co.',
            score: 47,
            holdingpercentage: 1.2562492,
            key: 10,
        },
        {
            ticker: 'SYY',
            name: 'Sysco Corp.',
            score: 44,
            holdingpercentage: 0.81893986,
            key: 11,
        },
        {
            ticker: 'SNPS',
            name: 'Synopsys, Inc.',
            score: 64,
            holdingpercentage: 0.89728755,
            key: 12,
        },
        {
            ticker: 'NOV',
            name: 'NOV Inc',
            score: 44,
            holdingpercentage: 0.10414701,
            key: 13,
        },
        {
            ticker: 'EW',
            name: 'Edwards Lifesciences Corp',
            score: 30,
            holdingpercentage: 1.4211373,
            key: 14,
        },
        {
            ticker: 'HPE',
            name: 'Hewlett Packard Enterprise Co',
            score: 58,
            holdingpercentage: 0.39449814,
            key: 15,
        },
        {
            ticker: 'ADI',
            name: 'Analog Devices Inc.',
            score: 64,
            holdingpercentage: 1.2633232,
            key: 16,
        },
        {
            ticker: 'MKC',
            name: 'McCormick & Co., Inc. (Non Voting)',
            score: 56,
            holdingpercentage: 0.46824962,
            key: 17,
        },
        {
            ticker: 'AZN',
            name: 'Astrazeneca plc - ADR',
            score: -22,
            holdingpercentage: 0.54968935,
            key: 18,
        },
        {
            ticker: 'CAT',
            name: 'Caterpillar Inc.',
            score: 39,
            holdingpercentage: 2.4106486,
            key: 19,
        },
    ]
}

export function getPesudoScreeningCategories() {
    const best: string = ' (Best In Class)'
    return [
        { title: 'Abortifacients', type: Screens.EXCLUDE },
        { title: 'Abortion Legislation', type: Screens.EXCLUDE },
        { title: 'Abortion Philanthropy', type: Screens.EXCLUDE },
        { title: 'Abortion Promotion', type: Screens.EXCLUDE },
        {
            title: 'Access and Affordability' + best,
            type: Screens.EXCLUDE,
        },
        { title: 'Air Quality' + best, type: Screens.EXCLUDE },
        { title: 'Alcohol', type: Screens.EXCLUDE },
        { title: 'Animal Testing', type: Screens.EXCLUDE },
        { title: 'Business Ethics' + best, type: Screens.EXCLUDE },
        { title: 'Business Model Resilience', type: Screens.EXCLUDE },
        { title: 'Cannabis: CBD Only', type: Screens.EXCLUDE },
        { title: 'Cannabis: Cultivation/Processing', type: Screens.EXCLUDE },
        { title: 'Cannabis: Pharmaceutical', type: Screens.EXCLUDE },
        { title: 'Cannabis: Retail THC', type: Screens.EXCLUDE },
        { title: 'Contraceptives: Barrier', type: Screens.EXCLUDE },
        { title: 'Critical Incident Risk Management', type: Screens.EXCLUDE },
        { title: 'Customer Privacy', type: Screens.EXCLUDE },
        { title: 'Customer Welfare', type: Screens.EXCLUDE },
        { title: 'Data Security', type: Screens.EXCLUDE },
        { title: 'Ecological Impacts', type: Screens.EXCLUDE },
        { title: 'Embryonic Stem Cell Distribution', type: Screens.EXCLUDE },
        { title: 'Embryonic Stem Cell Production', type: Screens.EXCLUDE },
        { title: 'Embryonic Stem Cell Research', type: Screens.EXCLUDE },
        {
            title: 'Employee Engagement Diversity and Inclusion',
            type: Screens.EXCLUDE,
        },
        { title: 'Employee Health and Safety', type: Screens.EXCLUDE },
        { title: 'Energy Management', type: Screens.EXCLUDE },
        { title: 'Exploitation', type: Screens.EXCLUDE },
        { title: 'Gambling', type: Screens.EXCLUDE },
        { title: 'GHG Emissions', type: Screens.EXCLUDE },
        { title: 'GMO Products', type: Screens.EXCLUDE },
        {
            title: 'Human Rights and Community Relations',
            type: Screens.EXCLUDE,
        },
        { title: 'In-Vitro Fertilization', type: Screens.EXCLUDE },
        { title: 'Labor Practices', type: Screens.EXCLUDE },
        { title: 'LGBT Legislation', type: Screens.EXCLUDE },
        { title: 'LGBT Philanthropy', type: Screens.EXCLUDE },
        { title: 'LGBT Promotion', type: Screens.EXCLUDE },
        {
            title: 'Management of the Legal and Regulatory Environment',
            type: Screens.EXCLUDE,
        },
        { title: 'Materials Sourcing and Efficiency', type: Screens.EXCLUDE },
        { title: 'Nuclear', type: Screens.EXCLUDE },
        { title: 'Physical Impacts of Climate Change', type: Screens.EXCLUDE },
        { title: 'Pornography', type: Screens.EXCLUDE },
        {
            title: 'Product Design and Lifecycle Management',
            type: Screens.EXCLUDE,
        },

        { title: 'Product Quality and Safety', type: Screens.EXCLUDE },
        {
            title: 'Selling Practices and Product Labeling',
            type: Screens.EXCLUDE,
        },
        { title: 'State Owned Enterprise (SOE)', type: Screens.EXCLUDE },
        { title: 'Supply Chain Management', type: Screens.EXCLUDE },
        { title: 'Systemic Risk Management', type: Screens.EXCLUDE },
        { title: 'Tobacco', type: Screens.EXCLUDE },
        {
            title: 'Waste and Hazardous Materials Management',
            type: Screens.EXCLUDE,
        },
        { title: 'Water and Wastewater Management', type: Screens.EXCLUDE },
        { title: 'Weapons: Weapons Components', type: Screens.EXCLUDE },
        { title: 'Weapons: Military Weapons', type: Screens.EXCLUDE },
    ]
}
export function getDefaultScreeningProfiles() {
    return [
        { title: 'Faith Based (Evangelical)', toggle: false },
        { title: 'Faith Based (Catholic)', toggle: false },
        { title: 'Environmental', toggle: false },
        { title: 'Social Justice', toggle: false },
        { title: 'Progressive', toggle: false },
        { title: 'Conservative', toggle: false },
        { title: 'Socially Responsible', toggle: false },
        { title: 'Custom', toggle: false },
        //{ title: 'None', toggle: false },
    ]
}

export function getPseudoSectorData() {
    return [
        { title: 'Consumer Durables', toggle: false },
        { title: 'Consumer Non-Durables', toggle: false },
        { title: 'Commercial Services', toggle: false },
        { title: 'Consumer Services', toggle: false },
        { title: 'Communications', toggle: false },
        { title: 'Distribution Services', toggle: false },
        { title: 'Electronic Technology', toggle: false },
        { title: 'Energy Minerals', toggle: false },
        { title: 'Finance', toggle: false },
        { title: 'Government', toggle: false },
        { title: 'Health Services', toggle: false },
        { title: 'Health Technology', toggle: false },
        { title: 'Industrial Services', toggle: false },
        { title: 'Retail Trade', toggle: false },
        { title: 'Miscellaneous', toggle: false },
        { title: 'Non-Energy Minerals', toggle: false },
        { title: 'Process Industries', toggle: false },
        { title: 'Producer Manufacturing', toggle: false },
        { title: 'Technology Services', toggle: false },
        { title: 'Transportation', toggle: false },
        { title: 'Utilities', toggle: false },
    ]
}

export function renderFiller(override?: Record<string, any>) {
    return (
        <div
            style={{
                width: '100%',
                height: 100,
                backgroundColor: 'brown',
                marginBottom: 10,
                ...override,
            }}
        >
            filler
        </div>
    )
}

export function renderFillers(n?: number, override?: Record<string, any>) {
    n = n ? n : 1
    const components: any[] = []
    for (let i = 0; i < n; i++) {
        components.push(<>{renderFiller(override)}</>)
    }
    return components
}
