import React from 'react'
import { Row, Col } from 'react-flexbox-grid'
import DropdownTable from '../../insightui/DropdownTable'
import ESGFundCriterion from '../../insightui/ESGFundCriterion'
import { config } from '../../models/config'
import axios from 'axios'
import Tag from '../../insightui/Tag'
import { isNegative, isPositive } from '../../services/screeningProfileService'
import { toast } from 'react-toastify'
import ContributingSecurities from './ContributingSecurities'
import { store } from '../../store'
import Button from '../../insightui/Button'
import { ArrowRightOutlined } from '@ant-design/icons'
import { Link } from 'react-router-dom'
import { isMobile } from 'react-device-detect'

const prettyTitle = {
    ENVIRONMENTAL: 'Environmental',
    SOCIAL: 'Social',
    GOVERNANCE: 'Governance',
}

export class Representation extends React.Component<{
    instrument: any
    printing?: boolean
    negative: boolean
    category: 'ENVIRONMENTAL' | 'SOCIAL' | 'GOVERNANCE'
    other?: boolean
    handleContributors: (category: string) => void
}> {
    getMaxExposure = (category: string, negative: boolean) => {
        let items: any = []
        if (!negative && !this.props.other) {
            //positive
            this.props.instrument.esgIssueCriteriaRollup
                .filter(
                    (item: any) =>
                        item.criterion.esgType === category &&
                        isPositive(item.criterion.name)
                )
                .forEach((item: any) => items.push(item.weightedPercentage))
        } else if (negative) {
            //negative
            this.props.instrument.esgIssueCriteriaRollup
                .filter(
                    (item: any) =>
                        item.criterion.esgType === category &&
                        isNegative(item.criterion.name)
                )
                .forEach((item: any) => items.push(item.weightedPercentage))
        } else {
            //other
            this.props.instrument.esgIssueCriteriaRollup
                .filter(
                    (item: any) =>
                        item.criterion.esgType === category &&
                        !isNegative(item.criterion.name) &&
                        !isPositive(item.criterion.name)
                )
                .forEach((item: any) => items.push(item.weightedPercentage))
        }

        return Math.max.apply(Math, items)
    }

    getCategoryCount = (category: string, negative: boolean) => {
        if (!negative && !this.props.other) {
            //positive
            return this.props.instrument.esgIssueCriteriaRollup.filter(
                (item: any) =>
                    item.criterion.esgType === category &&
                    isPositive(item.criterion.name)
            ).length
        } else if (negative) {
            //negative
            return this.props.instrument.esgIssueCriteriaRollup.filter(
                (item: any) =>
                    item.criterion.esgType === category &&
                    isNegative(item.criterion.name)
            ).length
        } else {
            //other
            return this.props.instrument.esgIssueCriteriaRollup.filter(
                (item: any) =>
                    item.criterion.esgType === category &&
                    !isNegative(item.criterion.name) &&
                    !isPositive(item.criterion.name)
            ).length
        }
    }

    shouldTitleRender = (category: string, negative: boolean) => {
        let env = this.getCategoryCount('ENVIRONMENTAL', negative)
        let soc = this.getCategoryCount('SOCIAL', negative)
        if (this.props.other) {
            return false
        } else {
            if (category === 'ENVIRONMENTAL' && env) {
                return true
            } else {
                if (category === 'SOCIAL' && !env) {
                    return true
                } else {
                    if (category === 'GOVERNANCE' && !soc) {
                        return true
                    }
                }
            }
        }

        return false
    }

    renderFunds = (item: any, category: any) => {
        if (!this.props.negative && !this.props.other) {
            return (
                <>
                    {item.criterion.esgType === category &&
                    isPositive(item.criterion.name) ? (
                        <>
                            <ESGFundCriterion
                                title={item.criterion.title}
                                securities={item.numberOfHoldings}
                                printing={this.props.printing}
                                category={item.criterion.name}
                                percent={item.weightedPercentage}
                                handleContributors={
                                    this.props.handleContributors
                                }
                            />
                        </>
                    ) : (
                        <></>
                    )}
                </>
            )
        } else if (this.props.negative) {
            return (
                <>
                    {item.criterion.esgType === category &&
                    isNegative(item.criterion.name) ? (
                        <>
                            <ESGFundCriterion
                                title={item.criterion.title}
                                securities={item.numberOfHoldings}
                                printing={this.props.printing}
                                category={item.criterion.name}
                                handleContributors={
                                    this.props.handleContributors
                                }
                                percent={item.weightedPercentage}
                                negative
                            />
                        </>
                    ) : (
                        <></>
                    )}
                </>
            )
        } else {
            return (
                <>
                    {item.criterion.esgType === category &&
                    !isNegative(item.criterion.name) &&
                    !isPositive(item.criterion.name) ? (
                        <>
                            <ESGFundCriterion
                                title={item.criterion.title}
                                securities={item.numberOfHoldings}
                                category={item.criterion.name}
                                printing={this.props.printing}
                                handleContributors={
                                    this.props.handleContributors
                                }
                                percent={item.weightedPercentage}
                            />
                        </>
                    ) : (
                        <></>
                    )}
                </>
            )
        }
    }

    render() {
        const { instrument, category, negative, other } = this.props
        return (
            <>
                {this.getCategoryCount(category, negative) ? (
                    <DropdownTable
                        securities={this.getCategoryCount(category, negative)}
                        title={prettyTitle[category]}
                        total={this.getMaxExposure(category, negative)}
                        header={this.shouldTitleRender(category, negative)}
                        negative={negative}
                    >
                        {instrument.esgIssueCriteriaRollup?.map((item: any) => (
                            <>{this.renderFunds(item, category)}</>
                        ))}
                    </DropdownTable>
                ) : (
                    <>
                        {other ? (
                            <DropdownTable
                                securities={0}
                                title={prettyTitle[category]}
                                total={0}
                                printing={this.props.printing}
                                header={false}
                            />
                        ) : (
                            <>
                                {category === 'ENVIRONMENTAL' &&
                                    !this.getCategoryCount(
                                        'SOCIAL',
                                        negative
                                    ) &&
                                    !this.getCategoryCount(
                                        'GOVERNANCE',
                                        negative
                                    ) && (
                                        <DropdownTable
                                            header
                                            nocategories
                                            negative={negative}
                                            printing={this.props.printing}
                                            securities={0}
                                            title={prettyTitle[category]}
                                            total={0}
                                        />
                                    )}
                            </>
                        )}
                    </>
                )}
            </>
        )
    }
}
export interface IContributor {
    financialInstrumentId: number
    ticker: string
    name: string
}
export default class ESGCriteriaFund extends React.Component<{
    instrument: any
    compare?: boolean
    portfolio?: boolean
    printing?: boolean
}> {
    state = {
        viewContributors: false,
        contributors: [],
    }

    handleContributorsRequest = async (category: string) => {
        this.setState({ viewContributors: true })
        await axios
            .get(
                this.props.portfolio
                    ? `${config.api}/api/portfolios/${this.props.instrument.id}/esg-issues/${category}/contributors`
                    : `${config.api}/api/tickers/${this.props.instrument.financialInstrumentId}/esg-issues/${category}/contributors`,
                {
                    headers: {
                        Authorization: `Bearer ${store.getState().token}`,
                    },
                }
            )
            .then((response) => {
                const data: Array<IContributor> = response.data
                this.setState({ contributors: data })
            })
            .catch((err) => {
                toast.error('Failed to fetch contributors.')
            })
    }

    render() {
        const { instrument, compare } = this.props
        return (
            <div style={{ backgroundColor: '#F7F7F7' }}>
                <ContributingSecurities
                    isActive={this.state.viewContributors}
                    contributors={this.state.contributors}
                    onClose={() => this.setState({ viewContributors: false })}
                />
                <Row>
                    <Col
                        xs={this.props.printing ? 6 : 12}
                        lg={compare ? 12 : 6}
                        md={6}
                        sm={6}
                    >
                        <Representation
                            instrument={instrument}
                            category={'ENVIRONMENTAL'}
                            printing={this.props.printing}
                            negative={false}
                            handleContributors={this.handleContributorsRequest}
                        />
                        <Representation
                            instrument={instrument}
                            category={'SOCIAL'}
                            negative={false}
                            printing={this.props.printing}
                            handleContributors={this.handleContributorsRequest}
                        />
                        <Representation
                            instrument={instrument}
                            category={'GOVERNANCE'}
                            printing={this.props.printing}
                            handleContributors={this.handleContributorsRequest}
                            negative={false}
                        />
                    </Col>
                    <Col
                        xs={this.props.printing ? 6 : 12}
                        lg={compare ? 12 : 6}
                        md={6}
                        sm={6}
                    >
                        <Representation
                            instrument={instrument}
                            category={'ENVIRONMENTAL'}
                            printing={this.props.printing}
                            negative
                            handleContributors={this.handleContributorsRequest}
                        />
                        <Representation
                            instrument={instrument}
                            category={'SOCIAL'}
                            negative
                            printing={this.props.printing}
                            handleContributors={this.handleContributorsRequest}
                        />
                        <Representation
                            instrument={instrument}
                            category={'GOVERNANCE'}
                            printing={this.props.printing}
                            negative
                            handleContributors={this.handleContributorsRequest}
                        />
                        {!this.props.printing && !compare && (
                            <div
                                style={{
                                    backgroundColor: 'white',
                                    marginTop: 20,
                                    padding: 40,
                                    paddingTop: 20,
                                    paddingBottom: 36,
                                }}
                            >
                                {isMobile && (
                                    <Row>
                                        <h2>Find Alternative</h2>
                                    </Row>
                                )}
                                <Row>
                                    <Col xs style={{ paddingRight: 20 }}>
                                        {!isMobile && (
                                            <Row>
                                                <h2>Find Alternative</h2>
                                            </Row>
                                        )}
                                        <Row>
                                            <span style={{ fontWeight: 400 }}>
                                                Find funds and companies with
                                                comparable metrics without any
                                                violations
                                            </span>
                                        </Row>
                                    </Col>
                                    <Col>
                                        <Link to="/advanced-search">
                                            <Button
                                                iconPosition="right"
                                                icon={<ArrowRightOutlined />}
                                                type="inspireBlue"
                                                width={190}
                                                style={{
                                                    marginTop: isMobile
                                                        ? 10
                                                        : 30,
                                                }}
                                            >
                                                Find Alternative
                                            </Button>
                                        </Link>
                                    </Col>
                                </Row>
                            </div>
                        )}
                    </Col>
                </Row>
                {!this.props.printing && (
                    <>
                        <Row style={{ marginTop: 30 }}>
                            <Tag
                                color="navy"
                                fullWidth
                                style={{
                                    padding: '15px 25px',
                                    margin: '0 8px',
                                    letterSpacing: '2px',
                                    fontWeight: 1000,
                                    fontSize: 15,
                                }}
                            >
                                OTHER
                            </Tag>
                        </Row>
                        <Row>
                            <Col xs={12} lg={compare ? 12 : 4}>
                                <Representation
                                    instrument={instrument}
                                    category={'ENVIRONMENTAL'}
                                    negative={false}
                                    other
                                    handleContributors={
                                        this.handleContributorsRequest
                                    }
                                />
                            </Col>
                            <Col xs={12} lg={compare ? 12 : 4}>
                                <Representation
                                    instrument={instrument}
                                    category={'SOCIAL'}
                                    negative={false}
                                    other
                                    handleContributors={
                                        this.handleContributorsRequest
                                    }
                                />
                            </Col>
                            <Col xs={12} lg={compare ? 12 : 4}>
                                <Representation
                                    instrument={instrument}
                                    category={'GOVERNANCE'}
                                    negative={false}
                                    other
                                    handleContributors={
                                        this.handleContributorsRequest
                                    }
                                />
                            </Col>
                        </Row>
                    </>
                )}
            </div>
        )
    }
}
