import React from 'react'

import Header from '../insightui/Header'
import Footer from '../insightui/Footer'
import { Row, Col } from 'react-flexbox-grid'

function privacy() {
    return (
        <div style={{ overflowX: 'hidden' }}>
            <Header logo subscriberExempt />
            <Row style={{ justifyContent: 'center' }}>
                <Col xs={12} lg={6}>
                    <div
                        style={{ padding: '150px 30px 30px', lineHeight: 1.8 }}
                    >
                        <h1
                            style={{
                                fontSize: 55,
                                letterSpacing: -2,
                                lineHeight: '55px',
                                fontWeight: 700,
                                marginBottom: 15,
                            }}
                        >
                            Privacy Policy
                        </h1>
                        <div
                            style={{
                                textTransform: 'uppercase',
                                fontWeight: '600',
                                letterSpacing: 2,
                                marginBottom: 70,
                            }}
                        >
                            <p>Last updated: December 14, 2018</p>
                        </div>
                        <h1
                            style={{
                                fontSize: 30,
                                lineHeight: '55px',
                                fontWeight: 700,
                                marginBottom: 15,
                            }}
                        >
                            Privacy Policy
                        </h1>
                        <div
                            class="typography__body-1"
                            style={{ marginBottom: 15 }}
                        >
                            Inspire Insight, LLC (
                            <span
                                class="privacy-policy__text--bold"
                                style={{ fontWeight: 'bold' }}
                            >
                                “Insight”
                            </span>{' '}
                            or
                            <span
                                class="privacy-policy__text--bold"
                                style={{ fontWeight: 'bold' }}
                            >
                                ”We”
                            </span>
                            ) respects your privacy and is committed to
                            protecting it through our compliance with this
                            policy. This policy is intended to describe the
                            types of information we may collect from you or that
                            you may provide when you visit the website
                            www.inspireinsight.com (our
                            <span
                                class="privacy-policy__text--bold"
                                style={{ fontWeight: 'bold' }}
                            >
                                “Website”
                            </span>
                            ) and our practices for collecting, using,
                            maintaining, protecting, and disclosing that
                            information.
                        </div>
                        <div
                            class="typography__body-1"
                            style={{ marginBottom: 15 }}
                        >
                            This policy applies to information we collect or
                            that you submit on or through the Website. This
                            Privacy Policy applies to anyone accessing our
                            Website (collectively,{' '}
                            <span
                                class="privacy-policy__text--bold"
                                style={{ fontWeight: 'bold' }}
                            >
                                “you”
                            </span>
                            ), including (a) casual visitors to our Website who
                            do not sign up for an account on the Website (
                            <span
                                class="privacy-policy__text--bold"
                                style={{ fontWeight: 'bold' }}
                            >
                                “Site Visitors”
                            </span>
                            ) and (b) customers who have registered to use our
                            Website (
                            <span
                                class="privacy-policy__text--bold"
                                style={{ fontWeight: 'bold' }}
                            >
                                “Registered Users”
                            </span>
                            ).
                        </div>
                        <div
                            class="typography__body-1"
                            style={{
                                fontWeight: '600',
                                fontSize: 20,
                                marginBottom: 15,
                            }}
                        >
                            This Privacy Policy does not apply to information
                            collected by:
                        </div>
                        <ul class="typography__body-1">
                            <li>
                                us offline or through any other means, including
                                on any other website operated by Insight or any
                                third party (including our affiliates and
                                subsidiaries); or
                            </li>
                            <li>
                                any third party (including our affiliates and
                                subsidiaries), including through any application
                                or content (including advertising) that may link
                                to or be accessible from or on the Website.
                            </li>
                        </ul>
                        <h1
                            style={{
                                fontSize: 30,
                                lineHeight: '55px',
                                fontWeight: 700,
                                margin: '80px 0 15px',
                            }}
                        >
                            Children Under the Age of 13
                        </h1>
                        <div
                            class="typography__body-1"
                            style={{ marginBottom: 15 }}
                        >
                            Our Website is not intended for children under 13
                            years of age, and we do not knowingly collect
                            personal information from children under 13. If you
                            are under 13, do not use or provide any information
                            on this Website or on or through any of its features
                            or register on the Website. If we learn we have
                            collected or received personal information from a
                            child under 13 without verification of parental
                            consent, we will take steps to delete that
                            information as permitted by law. If you believe we
                            might have any information from or about a child
                            under 13, please contact us at
                            support@inspireinsight.com.
                        </div>
                        <h1
                            style={{
                                fontSize: 30,
                                lineHeight: '55px',
                                fontWeight: 700,
                                margin: '80px 0 15px',
                            }}
                        >
                            Information We Collect
                        </h1>
                        <div
                            class="typography__body-1"
                            style={{ marginBottom: 15 }}
                        >
                            For purposes of this Privacy Policy,{' '}
                            <span
                                class="privacy-policy__text--bold"
                                style={{ fontWeight: 'bold' }}
                            >
                                “personal information”
                            </span>
                            is information about you that can be used to
                            identify you, which may include, for example, your
                            name, postal address, email address, phone number,
                            credit card number and expiration date, as well as
                            other non-public information that is associated with
                            that information.
                        </div>
                        <div
                            class="typography__body-1"
                            style={{ marginBottom: 15 }}
                        >
                            We may collect and use certain personal information
                            in order to offer, maintain, provide, improve, and
                            administer our products and services, (collectively,{' '}
                            <span
                                class="privacy-policy__text--bold"
                                style={{ fontWeight: 'bold' }}
                            >
                                “Services”
                            </span>
                            ). Instances in which we may collect and use certain
                            personal information include the following:
                        </div>
                        <ul class="typography__body-1">
                            <li>
                                <span
                                    class="privacy-policy__text--bold"
                                    style={{ fontWeight: 'bold' }}
                                >
                                    When you create an account with us or make
                                    purchases.
                                </span>
                                We collect personal information when you create
                                an account with us or make purchases from us.
                                This personal information may include
                                information such as your name, address, phone
                                number, email address, payment information, and
                                username and password.
                            </li>
                            <li>
                                <span
                                    class="privacy-policy__text--bold"
                                    style={{ fontWeight: 'bold' }}
                                >
                                    When you communicate with us, engage with
                                    our content, or sign up for materials.
                                </span>{' '}
                                We collect personal information, such as your
                                contact information and other information you
                                may submit, when you communicate with us or sign
                                up to receive publications, subscriptions, news,
                                offers, and promotional materials, or other
                                information.
                            </li>
                            <li>
                                <span
                                    class="privacy-policy__text--bold"
                                    style={{ fontWeight: 'bold' }}
                                >
                                    When you engage with our online communities
                                    and forums.
                                </span>
                                We may collect personal information when you
                                engage with our online communities and forums.
                                Please note that online forums may be publicly
                                accessible and other users may view information
                                you post in the forums. We recommend that you
                                exercise care in deciding what information and
                                content you wish to disclose on the areas of the
                                Website that are accessible to the general
                                public.
                            </li>
                            <li>
                                <span
                                    class="privacy-policy__text--bold"
                                    style={{ fontWeight: 'bold' }}
                                >
                                    When we collect data from third parties or
                                    publicly-available sources.
                                </span>{' '}
                                We may obtain certain data about you from
                                third-party sources to help us provide and
                                improve the Services and for marketing and
                                advertising. We may combine your personal
                                information with data we obtain from our
                                Services, other users, or third parties to
                                enhance your experience and improve the
                                Services.
                            </li>
                            <li>
                                <span
                                    class="privacy-policy__text--bold"
                                    style={{ fontWeight: 'bold' }}
                                >
                                    When we leverage and/or collect cookies,
                                    device IDs, location data, data from the
                                    environment, and other tracking
                                    technologies.
                                </span>{' '}
                                We may collect certain personal information
                                using cookies and other technologies, such as
                                web beacons, device IDs, geolocation, HTML5
                                local storage, Flash cookies, and IP addresses.
                                We specifically use browser cookies for
                                different purposes, including cookies that are
                                strictly necessary for functionality and cookies
                                that are used for personalization,
                                performance/analytics, and advertising. Our
                                “Cookies and Similar Technologies” section
                                contains more information and options to control
                                or opt-out of certain data collection or uses.
                            </li>
                        </ul>
                        <h1
                            style={{
                                fontSize: 30,
                                lineHeight: '55px',
                                fontWeight: 700,
                                margin: '80px 0 15px',
                            }}
                        >
                            How We Use Your Information
                        </h1>
                        <div
                            class="typography__body-1"
                            style={{ marginBottom: 15 }}
                        >
                            We may use information that we collect about you or
                            that you provide to us, including any personal
                            information, in order to offer, maintain, provide,
                            improve, and administer the Website and Services and
                            for compliance purposes, including:
                        </div>
                        <ul class="typography__body-1">
                            <li>
                                To present our Website and its contents to you.
                            </li>
                            <li>
                                To provide you with information, products, or
                                services that you request from us and to fulfil
                                orders or purchases you have made.
                            </li>
                            <li>
                                To communicate with you regarding the Services,
                                including to provide you with notices about your
                                subscription, such as expiration and renewal
                                notices.
                            </li>
                            <li>
                                To carry out our obligations and enforce our
                                rights arising from any contracts or agreements
                                entered into between you and us, including for
                                billing and collection, and our Terms of Use,
                                this Privacy Policy, or agreements with third
                                parties.
                            </li>
                            <li>
                                For marketing purposes, including to contact you
                                about offers, products, promotions,
                                subscriptions, developments or services which we
                                think may be of interest to you;
                            </li>
                            <li>
                                To develop, provide, and improve our Website and
                                Services, including to better tailor the
                                features, performance and support of our
                                Services, and for statistical and analytics
                                purposes;
                            </li>
                            <li>
                                To assist in the investigation of suspected
                                illegal or wrongful activity, and to protect and
                                defend our rights and property, or the rights or
                                safety of third parties;
                            </li>
                            <li>
                                To comply with laws, regulators, court orders,
                                or other legal obligations, or pursuant to legal
                                process.
                            </li>
                            <li>
                                For other purposes where you have provided your
                                consent.
                            </li>
                        </ul>
                        <h1
                            style={{
                                fontSize: 30,
                                lineHeight: '55px',
                                fontWeight: 700,
                                margin: '80px 0 15px',
                            }}
                        >
                            Disclosure of Your Information
                        </h1>
                        <div
                            class="typography__body-1"
                            style={{ marginBottom: 15 }}
                        >
                            We may disclose personal information that we collect
                            or you provide as described in this Privacy Policy,
                            including:
                        </div>
                        <ul class="typography__body-1">
                            <li>
                                To our subsidiaries and affiliates. Where we
                                share personal information with our Affiliates,
                                we will require our Affiliates to honor this
                                Privacy Policy.
                            </li>
                            <li>
                                To contractors, service providers, and other
                                third parties we use to support our business or
                                Website or to provide our Services.
                            </li>
                            <li>
                                To comply with any court order, law, or legal
                                process, including to respond to any government
                                or regulatory request.
                            </li>
                            <li>
                                To enforce or apply our data subscription
                                agreements and other agreements, including for
                                billing and collection purposes.
                            </li>
                            <li>
                                If we believe disclosure is necessary or
                                appropriate to protect the rights, property, or
                                safety of Insight, our customers, or others.
                            </li>
                            <li>
                                Subject to applicable law, to a buyer or other
                                successor in connection with a merger,
                                acquisition, divestiture, restructuring,
                                reorganization, dissolution, or other sale or
                                transfer of some or all of Insight’s assets,
                                whether as a going concern or as part of
                                bankruptcy, liquidation, or similar proceeding,
                                in which personal information held by Insight
                                about our Website users is among the assets
                                transferred, including for the purpose of
                                permitting the due diligence required to decide
                                whether to proceed with a transaction. If the
                                sale occurs, the purchaser will be entitled to
                                use and disclose the information collected by
                                us, and the purchaser will assume the rights and
                                obligations as described in this Privacy Policy.
                            </li>
                        </ul>
                        <h1
                            style={{
                                fontSize: 30,
                                lineHeight: '55px',
                                fontWeight: 700,
                                margin: '80px 0 15px',
                            }}
                        >
                            Cookies and Similar Technologies
                        </h1>
                        <div
                            class="typography__body-1"
                            style={{ marginBottom: 15 }}
                        >
                            As you navigate through and interact with our
                            Website, we may use cookies and similar technologies
                            to collect certain information about your equipment,
                            browsing actions, and patterns, including, for
                            example:
                        </div>
                        <ul class="typography__body-1">
                            <li>
                                Details of your visits to our Website, including
                                traffic data, location data, logs, and other
                                communication data and the resources that you
                                access and use on the Website.
                            </li>
                            <li>
                                Information about your computer and Internet
                                connection, including your IP address, operating
                                system, and browser type.
                            </li>
                        </ul>
                        <div
                            class="typography__body-1"
                            style={{ marginBottom: 15 }}
                        >
                            We collect this information to help us to improve
                            our Website and Services and to deliver a better and
                            more personalized service, including to make return
                            visits easier for Registered Users, and for
                            analytics. We may also use cookies and similar
                            technologies for purposes of marketing and
                            advertising. The technologies we may use for these
                            purposes may include:
                        </div>
                        <ul class="typography__body-1">
                            <li>
                                <span
                                    class="privacy-policy__text--bold"
                                    style={{ fontWeight: 'bold' }}
                                >
                                    Cookies (or browser cookies).
                                </span>{' '}
                                A cookie is a small file placed on the hard
                                drive of your computer. The purpose of cookies
                                is to remember the browser over time and
                                distinguish one browser instance (or user) from
                                all others. Some cookies and other technologies
                                may serve to track personal information
                                previously entered by a web user on our Website.
                                Most browsers allow you to control cookies,
                                including whether or not to accept them, and how
                                to remove them. Cookies can remember login
                                information, preferences, and similar
                                information. You may refuse to accept browser
                                cookies by activating the appropriate setting on
                                your browser. However, if you select this
                                setting, please note certain parts of our
                                Website may be inaccessible or not function
                                correctly. For information on how to disable
                                cookies, refer to your browser’s documentation.
                            </li>
                            <li>
                                <span
                                    class="privacy-policy__text--bold"
                                    style={{ fontWeight: 'bold' }}
                                >
                                    Flash Cookies.
                                </span>{' '}
                                Certain features of our Website may use local
                                stored objects (or Flash cookies) to collect and
                                store information about your preferences and
                                navigation to, from, and on our Website. Flash
                                cookies are not managed by the same browser
                                settings as are used for browser cookies. You
                                can visit adobe.com for guidance on how to
                                delete and block Flash cookies.
                            </li>
                            <li>
                                <span
                                    class="privacy-policy__text--bold"
                                    style={{ fontWeight: 'bold' }}
                                >
                                    HTML5 local storage.
                                </span>{' '}
                                HTML5 local storage is another way that browsers
                                can distinguish your device from others as well
                                as remember data that may be important for the
                                functioning of the website. Typically HTML5
                                local storage is only deleted if all Internet
                                history, cache, and cookies are deleted. You
                                should check your browser software for how to
                                delete HTML5 local storage in your particular
                                case.
                            </li>
                            <li>
                                <span
                                    class="privacy-policy__text--bold"
                                    style={{ fontWeight: 'bold' }}
                                >
                                    Web Beacons.
                                </span>{' '}
                                Pages of our Website and our emails may contain
                                small electronic files known as web beacons
                                (also referred to as clear gifs, pixel tags, and
                                single-pixel gifs) that permit Insight, for
                                example, to count users who have visited those
                                pages or opened an email, clickstream patterns,
                                dates and times our Website is accessed, and for
                                other related website statistics. Web beacons
                                can be used with or without cookies. Blocking
                                cookies will not stop your IP address from being
                                shared through the use of beacons.
                            </li>
                        </ul>
                        <div
                            class="typography__body-1"
                            style={{ marginBottom: 15 }}
                        >
                            Do-Not-Track is a public-private initiative that has
                            developed a “flag” or signal that an Internet user
                            may activate in the user’s browser software to
                            notify websites that the user does not wish to be
                            “tracked” by third-parties as defined by the
                            initiative. The online community has not agreed on
                            what actions, if any, should be taken by the
                            websites that receive the “do not track” signal, and
                            therefore Do-Not-Track is not yet standardized. Our
                            Website does not alter its behavior or change its
                            services when it receives a “do-not-track” flag or
                            signal from your browser.
                        </div>
                        <h1
                            style={{
                                fontSize: 30,
                                lineHeight: '55px',
                                fontWeight: 700,
                                margin: '80px 0 15px',
                            }}
                        >
                            De-Identified or Anonymized Data
                        </h1>
                        <div
                            class="typography__body-1"
                            style={{ marginBottom: 15 }}
                        >
                            We may create de-identified or anonymous data from
                            personal information by removing data components
                            (such as your name, email address, or linkable
                            tracking ID) that makes the data personally
                            identifiable to you or through obfuscation or
                            through other means. Our use of de-identified and
                            anonymized data is not subject to this Privacy
                            Policy.
                        </div>
                        <h1
                            style={{
                                fontSize: 30,
                                lineHeight: '55px',
                                fontWeight: 700,
                                margin: '80px 0 15px',
                            }}
                        >
                            Choices About Your Information
                        </h1>
                        <div
                            class="typography__body-1"
                            style={{ marginBottom: 15 }}
                        >
                            We strive to provide you with choices regarding the
                            personal information you provide to us. We have
                            created mechanisms to provide you with the following
                            controls related to your information:
                        </div>
                        <ul class="typography__body-1">
                            <li>
                                <span
                                    class="privacy-policy__text--bold"
                                    style={{ fontWeight: 'bold' }}
                                >
                                    Cookies and Similar Technologies.
                                </span>{' '}
                                You can set your browser to refuse all or some
                                browser cookies, or to alert you when cookies
                                are being sent. If you disable or refuse
                                cookies, please note that some parts of the
                                Website may be inaccessible or not function
                                properly. Please see the “Cookies and Similar
                                Technologies” section of this Privacy Policy for
                                more information on controls pertaining to
                                cookies and similar technologies.
                            </li>
                            <li>
                                <span
                                    class="privacy-policy__text--bold"
                                    style={{ fontWeight: 'bold' }}
                                >
                                    Promotional Offers from Insight.
                                </span>{' '}
                                If you want to stop receiving future marketing
                                messages and materials, you can opt-out by
                                sending us an email stating your request to
                                support@inspireinsight.com, or by clicking the
                                “unsubscribe” link included in our email
                                marketing messages. Once you have submitted your
                                email address to "unsubscribe," please note that
                                you may continue to receive emails for a time
                                while our system updates your request. Please
                                note that you may continue to receive
                                communications from us for non- marketing
                                purposes, such as communications regarding your
                                account or the Services.
                            </li>
                        </ul>
                        <h1
                            style={{
                                fontSize: 30,
                                lineHeight: '55px',
                                fontWeight: 700,
                                margin: '80px 0 15px',
                            }}
                        >
                            Accessing and Correcting Your Information
                        </h1>
                        <div
                            class="typography__body-1"
                            style={{ marginBottom: 15 }}
                        >
                            If you have created an account with us, you can
                            review and change your personal information by
                            logging into the Website and visiting your account
                            profile page.
                        </div>
                        <div
                            class="typography__body-1"
                            style={{ marginBottom: 15 }}
                        >
                            You may also send us an email at
                            support@inspireinsight.com to request access to or
                            to correct or delete any personal information that
                            you have provided to us. Please note we may not be
                            able to accommodate certain requests to change or
                            delete information, for example, if we believe the
                            change would violate any law or legal requirement or
                            cause the information to be incorrect.
                        </div>
                        <h1
                            style={{
                                fontSize: 30,
                                lineHeight: '55px',
                                fontWeight: 700,
                                margin: '80px 0 15px',
                            }}
                        >
                            Data Security
                        </h1>
                        <div
                            class="typography__body-1"
                            style={{ marginBottom: 15 }}
                        >
                            We have implemented measures designed to protect
                            your personal information. However, please be
                            advised that we cannot fully eliminate security
                            risks associated with the transmission and storage
                            of personal information. The safety and security of
                            your information also depends on you. Registered
                            Users are responsible for keeping their password
                            confidential. We ask you not to share your password
                            with anyone.
                        </div>
                        <h1
                            style={{
                                fontSize: 30,
                                lineHeight: '55px',
                                fontWeight: 700,
                                margin: '80px 0 15px',
                            }}
                        >
                            Links to Other Sites
                        </h1>
                        <div
                            class="typography__body-1"
                            style={{ marginBottom: 15 }}
                        >
                            Our Website may contain links or otherwise provide
                            access to another website, mobile application, or
                            Internet location (collectively “Third-Party
                            Sites”). We provide these links merely for your
                            convenience. Insight has no control over, does not
                            review, and is not responsible for Third-Party
                            Sites, their content, or any goods or services
                            available through the Third-Party Sites. Our Privacy
                            Policy does not apply to Third-Party Sites. We
                            encourage you to review the privacy policies of any
                            Third-Party Sites with which you choose to interact.
                        </div>
                        <h1
                            style={{
                                fontSize: 30,
                                lineHeight: '55px',
                                fontWeight: 700,
                                margin: '80px 0 15px',
                            }}
                        >
                            Processing in the United States
                        </h1>
                        <div
                            class="typography__body-1"
                            style={{ marginBottom: 15 }}
                        >
                            Please note that if you are visiting the Website
                            from outside of the United States, your information
                            may be transferred to, stored, and/or processed in
                            the United States. The data protection and other
                            laws of the United States and other countries might
                            not be as comprehensive as those in your country. If
                            you are located outside of the United States, the
                            transfer of personal information is necessary to
                            provide you with the requested information,
                            products, and services and/or to perform any
                            requested transaction. By using any portion of the
                            Website, you acknowledge and consent to the transfer
                            of your information to our facilities in the United
                            States.
                        </div>
                        <h1
                            style={{
                                fontSize: 30,
                                lineHeight: '55px',
                                fontWeight: 700,
                                margin: '80px 0 15px',
                            }}
                        >
                            Changes to Our Privacy Policy
                        </h1>
                        <div
                            class="typography__body-1"
                            style={{ marginBottom: 15 }}
                        >
                            We may update our Privacy Policy from time to time,
                            and it is our policy to post any changes we make to
                            our Privacy Policy on this page. The date the
                            Privacy Policy was last updated is identified at the
                            top of the page. You are responsible for ensuring we
                            have an up-to-date active and deliverable email
                            address for you, and we encourage you to
                            periodically visit our Website and this Privacy
                            Policy to stay informed of how we may process your
                            information.
                        </div>
                        <h1
                            style={{
                                fontSize: 30,
                                lineHeight: '55px',
                                fontWeight: 700,
                                margin: '80px 0 15px',
                            }}
                        >
                            Contact Information
                        </h1>
                        <div
                            class="typography__body-1"
                            style={{ marginBottom: 15 }}
                        >
                            To ask questions or comment about this Privacy
                            Policy or our privacy practices, please contact us
                            at:{' '}
                            <a
                                style={{ color: '#2c8aaf' }}
                                href="mailto:support@inspireinsight.com"
                            >
                                support@inspireinsight.com
                            </a>
                        </div>
                    </div>
                </Col>
            </Row>
            <Footer />
        </div>
    )
}

export default privacy
