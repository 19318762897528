import engagement from '../../models/engagement.json'
import { Row, Col } from 'react-flexbox-grid'
import arcBackgrond from '../../images/Negative Background-01.svg'
import lilIcon from '../../images/circle-exclamation-duotone.svg'

interface PendingViolation {
    violations: Array<{
        title: string
        date: string
        description: string
        source: string
    }>
    updates: Array<{
        date: string
        text: string
    }>
}

// "255990522": {
//     "violations": ["Abortion Promotion"],
//     "updates": [
//         {
//             "date": "07-01-2022",
//             "text": "Inspire began engagement efforts"
//         }
//     ]
// },
// "201868683": {
//     "violations": ["Abortion Promotion"],
//     "updates": [
//         {
//             "date": "07-01-2022",
//             "text": "Inspire began engagement efforts"
//         }
//     ]
// },
// "254273451": {
//     "violations": ["Abortion Promotion"],
//     "updates": [
//         {
//             "date": "07-01-2022",
//             "text": "Inspire began engagement efforts"
//         }
//     ]
// },
// "8861": {
//     "violations": ["Abortion Promotion"],
//     "updates": [
//         {
//             "date": "07-01-2022",
//             "text": "Inspire began engagement efforts"
//         }
//     ]
// },
// "254273749": {
//     "violations": ["Abortion Promotion"],
//     "updates": [
//         {
//             "date": "07-01-2022",
//             "text": "Inspire began engagement efforts"
//         }
//     ]
// },
// "238671784": {
//     "violations": ["Abortion Promotion"],
//     "updates": [
//         {
//             "date": "07-01-2022",
//             "text": "Inspire began engagement efforts"
//         }
//     ]
// },
// "5788": {
//     "violations": ["Abortion Promotion"],
//     "updates": [
//         {
//             "date": "07-01-2022",
//             "text": "Inspire began engagement efforts"
//         }
//     ]
// }
export default function PendingViolations({ id }) {
    return (
        <div>
            {Object.keys(engagement).includes(id.toString()) && (
                <div
                    style={{
                        // width: '100%',
                        padding: 35,
                        backgroundColor: '#C25067',
                        marginBottom: 20,
                        color: 'white',
                        backgroundImage: `url(${arcBackgrond})`,
                    }}
                >
                    <Row>
                        <Col lg={6} xs={12}>
                            <Row>
                                <Col style={{ paddingTop: 9 }}>
                                    <img src={lilIcon} width={30} height={30} />
                                </Col>
                                <Col style={{ paddingLeft: 10 }}>
                                    <h3
                                        style={{
                                            fontWeight: 900,
                                            fontSize: 24,
                                            lineHeight: 0.5,
                                        }}
                                    >
                                        Active Engagement Campaign
                                    </h3>
                                </Col>
                            </Row>
                            <Row>
                                <p
                                    style={{
                                        fontSize: '1.0em',
                                        fontWeight: 500,
                                        lineHeight: '1.3em',
                                    }}
                                >
                                    Inspire Investing is leading an engagement
                                    campaign with this company on the following
                                    issue. If the company does not resolve the
                                    issue favorably, it may result in an adverse
                                    change to their Inspire Impact Score.
                                </p>
                            </Row>
                            <Row style={{ borderBottom: '1px solid white' }}>
                                <h4 style={{ lineHeight: 0 }}>Violation(s)</h4>
                            </Row>
                            <Row>
                                <p>
                                    {engagement[id]['violations'].map(
                                        (violation) => (
                                            <div
                                                style={{
                                                    backgroundColor: 'white',
                                                    padding: 20,
                                                    borderRadius: 6,
                                                    color: '#C25067',
                                                }}
                                            >
                                                <Row style={{ width: '100%' }}>
                                                    <Col
                                                        style={{
                                                            fontWeight: 600,
                                                        }}
                                                    >
                                                        {violation.title}
                                                    </Col>
                                                    <Col
                                                        style={{
                                                            paddingLeft: 20,
                                                            marginTop: 2,
                                                            fontSize: 13,
                                                        }}
                                                    >
                                                        {violation.date}
                                                    </Col>
                                                </Row>
                                                <Row>
                                                    <p>
                                                        {violation.description}
                                                    </p>
                                                </Row>
                                                <Row>
                                                    <a
                                                        href={violation.source}
                                                        style={{
                                                            color: '#C25067',
                                                        }}
                                                        target="_blank"
                                                    >
                                                        Source:{' '}
                                                        {violation.source}
                                                    </a>
                                                </Row>
                                            </div>
                                        )
                                    )}
                                    {/* </span> */}
                                </p>
                            </Row>
                        </Col>
                        <Col lgOffset={1} lg={4}>
                            <Row>
                                <h3>Campaign Updates</h3>
                            </Row>
                            <Row style={{ paddingLeft: 8 }}>
                                {engagement[id]['updates'].map((item) => (
                                    <Row style={{ fontSize: 14 }}>
                                        <p>
                                            <strong>{item.date}</strong>
                                            <span style={{ marginLeft: 10 }}>
                                                {item.text}
                                            </span>
                                        </p>
                                    </Row>
                                ))}
                            </Row>
                        </Col>
                    </Row>
                </div>
            )}
        </div>
    )
}
