import React, { ReactNode } from 'react'
import ScaleLoader from 'react-spinners/ScaleLoader'
import { globals } from '../../utils/globals'
import logo from '../../images/logo.svg'
import inst from '../../images/inst.svg'
import InputField from '../../insightui/InputFieldCustom'
import Disclaimer from '../../insightui/Disclaimer'
import PaymentType from '../../insightui/PaymentType'
import Button from '../../insightui/Button'
import InspireSquare from '../../images/inspire-square.jpg'
import { store } from '../../store'
import {
    MultilineNotif,
    MultilineNotifPresets,
} from '../../insightui/InlineNotif'
import { ArrowLeft } from '@styled-icons/bootstrap/ArrowLeft'
import { CheckboxState } from '../../insightui/CheckboxCustom'
import { Plans } from './LoginRegistration'
import { RegistrationRequest, CheckoutRequest } from '../../models/state'
import { PrefabNotifications } from '../../insightui/InlineNotif'
import { registerAcvivate } from '../../reducers/auth'
import { PaddingOffset } from './LoginRegistration'
import { CardData, CharacterLimits } from '../../pages/Register'
import { withRouter } from 'react-router-dom'
import { RouteComponentProps, Link } from 'react-router-dom'

import { toast, ToastContainer } from 'react-toastify'
import axios from 'axios'
import { config } from '../../models/config'
import { ICoupon } from '../../models/api'
import { LoadingOutlined } from '@ant-design/icons'
import { Row, Col } from 'react-flexbox-grid'
import { isMobile } from 'react-device-detect'

const DEBUG: boolean = false

type ViewType = 'desktop' | 'mobile'

enum Views {
    DESKTOP,
    MOBILE,
}

enum Status {
    UNPAID,
    PAID,
}

export enum Billing {
    LIFETIME = 0,
    YEARLY = 1,
}

enum BasicCost {
    YEARLY = 60,
    LIFETIME = 240,
}

enum ProCost {
    YEARLY = 250,
    LIFETIME = 1200,
}

enum StripeProduct {
    YEARLY = 'prod_KsLOSEIt1NmzYT',
    LIFETIME = 'prod_KcqwocBptq78tQ',
}

export enum Sizes {
    FIELD_WIDTH_DESKTOP = 440,
    CONTAINER_MARGIN_LEFT_DESKTOP = 283,
    CONTAINER_MOBILE = 320,
    BUTTON_WIDTH_MOBILE = 320,
    FIELD_WIDTH_MOBILE = 300,
    FIELD_MARGIN_LEFT_MOBILE = 10,
    FIELD_MARGIN_LEFT_DESKTOP = 25,
    MOBILE_FIELD = 100,
    MOBILE_BUTTON = 100,
    HALF_FIELD = 25,
    FULL_FIELD = 50,
    FULL_BUTTON = 50,
    DISCLAIMER = 50,
    DISCLAIMER_MOBILE = 100,
    VERTICAL_GAP = 40,
    MINI_FOOTER_HEIGHT = 150,
}

const TEMPLATE: any = getTemplate()

function getTemplate() {
    interface Template {
        subtitle: Record<string, any>
        subtitleII: Record<string, any>
        text: Record<string, any>
        smallText: Record<string, any>
        timeStamp: Record<string, any>
        parenthetical: Record<string, any>
    }

    const DefaultDesktop: Template = {
        subtitle: {
            ...globals.typeface.headerIcon,
            margin: 0,
            padding: 0,
            fontSize: 25,
            letterSpacing: -0.5,
            fontWeight: 800,
            textAlign: 'left',
            display: 'block',
            width: '100%',
            height: 'auto',
            backgroundColor: 'white',
            marginBottom: -10,
        },
        subtitleII: {
            ...globals.typeface.defaultText,
            fontSize: 23,
            paddingTop: Sizes.VERTICAL_GAP,
            marginBottom: -10,
            color: globals.colors.inspire.darknavy,
        },
        text: {
            ...globals.typeface.defaultText,
            fontSize: 18,
            fontWeight: 500,
            fontFamily: 'URW Geometric Ext',
            marginBottom: 8,
        },
        smallText: {
            ...globals.typeface.defaultText,
            fontSize: 13,
        },
        timeStamp: {
            ...globals.typeface.defaultText,
        },
        parenthetical: {
            ...globals.typeface.defaultText,
            fontSize: 13,
        },
    }

    const DefaultMobile: Template = {
        subtitle: {
            ...globals.typeface.headerIcon,
            margin: 0,
            padding: 0,
            fontSize: 25,
            letterSpacing: -0.5,
            fontWeight: 800,
            textAlign: 'left',
            display: 'block',
            width: '100%',
            height: 'auto',
            backgroundColor: 'white',
            marginBottom: -10,
        },
        subtitleII: {
            ...globals.typeface.defaultText,
            fontSize: 23,
            paddingTop: Sizes.VERTICAL_GAP,
            marginBottom: -10,
            color: globals.colors.inspire.darknavy,
        },
        text: {
            ...globals.typeface.defaultText,
            fontSize: 18,
            fontWeight: 500,
            fontFamily: 'URW Geometric Ext',
            marginBottom: 8,
        },
        smallText: {
            ...globals.typeface.defaultText,
            fontSize: 13,
        },
        timeStamp: {
            ...globals.typeface.defaultText,
        },
        parenthetical: {
            ...globals.typeface.defaultText,
            fontSize: 13,
        },
    }

    return {
        default: {
            desktop: DefaultDesktop,
            mobile: DefaultMobile,
        },
    }
}

export function renderIf(isRendered: boolean, node: ReactNode) {
    if (isRendered) return node
    return <></>
}

class LeftFrame extends React.Component<{
    view?: ViewType
}> {
    render() {
        return (
            <div
                style={{
                    display: 'inline-block',
                    backgroundColor: DEBUG ? 'blue' : 'transparent',
                    minHeight: '100vh',
                    height: 'auto',
                    paddingLeft: 50,
                    width: isMobile
                        ? 'calc(100vw - 50px)'
                        : 'calc(50vw - 50px)',
                }}
            >
                {this.props.children}
            </div>
        )
    }
}

class RightFrame extends React.Component<{
    view?: ViewType
}> {
    render() {
        return (
            <div
                style={{
                    top: 0,
                    display: isMobile ? 'block' : 'inline-block',
                    height: isMobile ? 'auto' : '100vh',
                    paddingLeft: 50,
                    borderRadius: isMobile ? 'none' : '0 0 0 4px',
                    position: isMobile ? 'relative' : 'sticky',
                    float: isMobile ? 'none' : 'right',
                    backgroundColor: DEBUG
                        ? 'purple'
                        : isMobile
                        ? 'transparent'
                        : 'white',
                    width: isMobile
                        ? 'calc(100vw - 50px)'
                        : 'calc(50vw - 50px)',
                }}
            >
                {this.props.children}
            </div>
        )
    }
}

class FooterFrame extends React.Component<{}> {
    render() {
        return (
            <div
                style={{
                    marginTop: 50,
                    backgroundColor: DEBUG
                        ? 'lime'
                        : 'hsla(225, 4.27%, 89.11%, 1)',
                    minHeight: 50,
                    height: 'auto',
                    width: '100vw',
                }}
            >
                {this.props.children}
            </div>
        )
    }
}

interface CheckoutProps extends RouteComponentProps {
    plan: Plans
    request: CheckoutRequest
    params?: any
    pending: boolean
    onSubmission?: (
        request: CheckoutRequest,
        cardData: CardData,
        billing: number,
        plan: Plans,
        couponId: string,
        onSuccess?: () => void,
        onFailure?: () => void
    ) => void
}

class Checkout extends React.Component<CheckoutProps> {
    state = {
        //bro, this state?
        request: this.props.request,
        userId: this.props.request.userId,
        stripeCustomerId: this.props.request.stripeCustomerId,
        plan: Plans.BASIC,
        status: Status.UNPAID,
        view: Views.DESKTOP,
        isLoggedIn: true,
        type: 'default',
        billing: Billing.YEARLY,
        firstName: this.props.request.firstName,
        lastName: this.props.request.lastName,
        email: this.props.request.email,
        zipCode: this.props.request.zipCode,
        requestInProgress: false,
        password: this.props.request.password,
        phone: '',
        aum: '',
        households: '',
        address: '',
        apartment: '',
        city: '',
        state: '',
        agreement: false,
        discount: 0,
        subtotal: 220,
        promoCode: '',
        confirmation: '#123456789',
        errors: this.getEmptyErrorState(),
        paymentMethodId: '',
        cardNumber: '',
        cardType: 'Visa',
        cardExpMonth: '',
        cardExpYear: '',
        cardCvc: '',
        promoCheckInProgress: false,
        activeCoupon: null,
    }

    componentDidMount() {
        let newState: any = {}

        let isLoggedIn: boolean = store.getState().isLogged

        if (this.props.params) {
            if (this.props.params.firstName)
                newState = {
                    ...newState,
                    firstName: this.props.params.firstName,
                }

            if (this.props.params.lastName)
                newState = {
                    ...newState,
                    lastName: this.props.params.lastName,
                }

            if (this.props.params.email)
                newState = {
                    ...newState,
                    email: this.props.params.email,
                }

            if (this.props.params.password)
                newState = {
                    ...newState,
                    password: this.props.params.password,
                }
        }

        if (isLoggedIn || this.props.params) this.setState(newState)
    }

    registration = (params: {
        request?: RegistrationRequest
        onSuccess?: (response: any) => void
        onFail?: (response: any) => void
    }) => {
        const firstName: string = this.state.firstName
        const lastName: string = this.state.lastName
        const loginEmail: string = this.state.email
        const zipCode: string = globals.util.parseIntegers(
            this.state.zipCode,
            CharacterLimits.ZIPCODE_MAX
        )
        const password: string = this.state.password

        const firstNameValid: boolean =
            firstName.length >= CharacterLimits.FIRST_NAME_MIN &&
            firstName.length <= CharacterLimits.FIRST_NAME_MAX

        const lastNameValid: boolean =
            lastName.length >= CharacterLimits.LAST_NAME_MIN &&
            lastName.length <= CharacterLimits.LAST_NAME_MAX

        const loginEmailValid: boolean =
            loginEmail.length >= CharacterLimits.EMAIL_MIN &&
            loginEmail.length <= CharacterLimits.EMAIL_MAX

        const zipCodeValid: boolean =
            zipCode.length === CharacterLimits.ZIPCODE_MIN

        const passwordValid: boolean =
            password.length >= CharacterLimits.PASSWORD_MIN &&
            password.length <= CharacterLimits.PASSWORD_MAX

        const valid: boolean =
            firstNameValid &&
            lastNameValid &&
            loginEmailValid &&
            zipCodeValid &&
            passwordValid

        const request: RegistrationRequest = {
            login: loginEmail,
            email: loginEmail,
            firstName: firstName,
            lastName: lastName,
            zipCode: zipCode,
            password: password,
        }

        if (valid)
            return registerAcvivate({
                //return registerAcvivateLogin({
                request: request,
                onSuccess: (response: any) => {
                    if (params.onSuccess) params.onSuccess(response)
                },
                onFail: (response: any) => {
                    if (params.onFail) params.onFail(response)
                },
            })
        else {
            let errorsState: any = {
                email: [],
                zipCode: [],
                firstName: [],
                lastName: [],
                password: [],
            }

            if (!firstNameValid)
                errorsState = {
                    ...errorsState,
                    firstName: [PrefabNotifications.FIRST_NAME_LENGTH_INVALID],
                }

            if (!lastNameValid)
                errorsState = {
                    ...errorsState,
                    lastName: [PrefabNotifications.LAST_NAME_LENGTH_INVALID],
                }

            if (!loginEmailValid)
                errorsState = {
                    ...errorsState,
                    email: [PrefabNotifications.EMAIL_LENGTH_INVALID],
                }

            if (!zipCodeValid)
                errorsState = {
                    ...errorsState,
                    zipCode: [PrefabNotifications.ZIPCODE_LENGTH_INVALID],
                }

            if (!passwordValid)
                errorsState = {
                    ...errorsState,
                    password: [PrefabNotifications.PASSWORD_LENGTH_INVALID],
                }

            if (
                !firstNameValid ||
                !lastNameValid ||
                !loginEmailValid ||
                !zipCodeValid ||
                !passwordValid
            ) {
                this.setState({
                    errors: errorsState,
                })
            }
        }
    }

    onChange = (e: any, key: string) => {
        this.setState({
            [key]: e.target.value,
        })
    }

    onChangeFormatToInteger = (e: any, key: string) => {
        this.setState({
            [key]: globals.util.parseIntegers(
                e.target.value,
                CharacterLimits.ZIPCODE_MAX
            ),
        })
    }

    getEmptyErrorState() {
        return {
            email: [],
            zipCode: [],
            firstName: [],
            lastName: [],
            password: [],
        }
    }

    renderLineBreak() {
        return (
            <div
                style={{
                    width: 600,
                    marginTop: 25,
                    marginBottom: 25,
                    height: 1,
                    backgroundColor: 'hsla(0, 0%, 96.67%, 1)',
                }}
            ></div>
        )
    }

    renderCostLine(name: string, value: string, height?: number) {
        const type = this.state.type
        const view = Views[this.state.view].toLowerCase()

        const h: number = height ? height : 30
        return (
            <div
                style={{
                    backgroundColor: DEBUG ? 'red' : 'transparent',
                    height: h,
                    width: 'calc(100% - 40px)',
                }}
            >
                <div
                    style={{
                        display: 'inline-block',
                        verticalAlign: 'top',
                        width: 200,
                        height: h,
                        backgroundColor: DEBUG ? 'lime' : 'transparent',
                        float: 'left',
                    }}
                >
                    <div
                        style={{
                            ...TEMPLATE[type][view].text,
                            display: 'table-cell',
                            width: 200,
                            height: h,
                            verticalAlign: 'middle',
                            textAlign: 'left',
                            overflow: 'hidden',
                        }}
                    >
                        {name}
                    </div>
                </div>

                <div
                    style={{
                        display: 'inline-block',
                        verticalAlign: 'top',
                        width: 100,
                        height: h,
                        backgroundColor: DEBUG ? 'lime' : 'transparent',
                        float: 'right',
                    }}
                >
                    <div
                        style={{
                            ...TEMPLATE[type][view].text,
                            display: 'table-cell',
                            width: 100,
                            height: h,
                            verticalAlign: 'middle',
                            textAlign: 'center',
                        }}
                    >
                        {value}
                    </div>
                </div>
            </div>
        )
    }

    renderBillingComponent(params: {
        period: string
        price: string
        parenthetical?: string
        isSelected?: boolean
        onRadioChange?: () => void
    }) {
        const radioSectionWidth: number = 40
        const type: string = this.state.type
        const view = Views[this.state.view].toLowerCase()

        const ConditionalComponent: ReactNode = (
            <div style={TEMPLATE[type][view].parenthetical}>
                {params.parenthetical ? params.parenthetical : ''}
            </div>
        )

        return (
            <>
                <div
                    style={{
                        verticalAlign: 'top',
                        width: radioSectionWidth,
                        height: '100%',
                        backgroundColor: DEBUG ? 'purple' : 'transparent',
                        display: 'inline-block',
                    }}
                >
                    <input
                        checked={params.isSelected ? params.isSelected : false}
                        name="billing"
                        type="radio"
                        onChange={() => {
                            if (params.onRadioChange) params.onRadioChange()
                        }}
                    ></input>
                </div>
                <div
                    style={{
                        verticalAlign: 'top',
                        width: `calc(100% - ${radioSectionWidth}px)`,
                        height: '100%',
                        backgroundColor: DEBUG ? 'lime' : 'transparent',
                        display: 'inline-block',
                    }}
                >
                    <div style={TEMPLATE[type][view].text}>
                        <strong>{params.period}</strong> subscription
                    </div>
                    <div style={TEMPLATE[type][view].text}>{params.price}</div>
                    {ConditionalComponent}
                </div>
            </>
        )
    }

    renderPlanSelection(params: {
        plan: Plans
        isSelected?: boolean
        onRadioChange?: () => void
    }) {
        const radioSectionWidth: number = 40
        const type: string = this.state.type
        const view = Views[this.state.view].toLowerCase()

        return (
            <>
                <div
                    style={{
                        verticalAlign: 'top',
                        width: radioSectionWidth,
                        height: '100%',
                        backgroundColor: DEBUG ? 'purple' : 'transparent',
                        display: 'inline-block',
                    }}
                >
                    <input
                        checked={params.isSelected ? params.isSelected : false}
                        name="plan_select"
                        type="radio"
                        onChange={() => {
                            if (params.onRadioChange) params.onRadioChange()
                        }}
                    />
                </div>
                <div
                    style={{
                        verticalAlign: 'top',
                        width: `calc(100% - ${radioSectionWidth}px)`,
                        height: '100%',
                        backgroundColor: DEBUG ? 'lime' : 'transparent',
                        display: 'inline-block',
                    }}
                >
                    <div style={TEMPLATE[type][view].text}>
                        Insight{' '}
                        <strong>
                            {params.plan === Plans.PRO ? 'Premium' : 'Basic'}
                        </strong>
                    </div>
                </div>
            </>
        )
    }

    renderAgreement() {
        const type: string = this.state.type
        const view: ViewType = Views[this.state.view].toLowerCase() as ViewType
        return (
            <>
                <p style={TEMPLATE[type][view].subtitleII}>Agreement</p>
                <Disclaimer
                    onChange={(checkboxState: number) => {
                        this.setState({
                            agreement:
                                checkboxState === CheckboxState.CHECKED
                                    ? true
                                    : false,
                        })
                    }}
                >
                    <>
                        I agree that my personal data will be used to support my
                        experience throughout this website and by signing up for
                        an Inspire Insight account means I agree to the &nbsp;
                        <Link to="/privacy-policy">
                            Privacy Policy and &nbsp;{' '}
                        </Link>
                        <Link to="/terms-of-service">
                            Terms of Service.&nbsp;
                        </Link>
                    </>
                </Disclaimer>
                <ToastContainer position="bottom-center" />
                <div style={{ marginTop: 40 }}>
                    <Button
                        onClick={() => {
                            this.setState({ requestInProgress: true })
                            const request: CheckoutRequest = this.props.request
                            const card: CardData = this.getCardData()
                            const billing: number = this.state.billing

                            if (this.props.onSubmission)
                                this.props.onSubmission(
                                    request,
                                    card,
                                    billing,
                                    this.state.plan,
                                    this.state.promoCode,
                                    () => {
                                        this.setState({
                                            status: Status.PAID,
                                        })
                                    },
                                    () => {
                                        this.setState({
                                            requestInProgress: false,
                                        })
                                        toast.error(
                                            'Payment has failed. Please review your fields'
                                        )
                                    }
                                )
                        }}
                        type="inspireBlue"
                        disabled={
                            !this.state.agreement ||
                            this.state.requestInProgress
                        }
                    >
                        Purchase{' '}
                        {this.state.requestInProgress && <LoadingOutlined />}
                    </Button>
                </div>
            </>
        )
    }

    getCardData = () => {
        const cardNumber: string = this.state.cardNumber

        const month: number = this.state.cardExpMonth as unknown as number

        const year: number = this.state.cardExpYear as unknown as number

        const cvc: string = this.state.cardCvc

        const zip: string = this.state.zipCode

        const liveCard: CardData = {
            cardNumber: cardNumber,
            expMonth: month,
            expYear: year,
            cvc: cvc,
            zipCode: zip, // 83646
        }
        return liveCard
    }

    verifyCouponCode = () => {
        this.setState({
            promoCheckInProgres: true,
        })
        axios
            .get(
                `${config.api}/api/payment/coupon?couponId=${this.state.promoCode}`,
                {
                    headers: {
                        Authorization: `Bearer ${store.getState().token}`,
                    },
                }
            )
            .then((response) => {
                this.setState({
                    promoCheckInProgress: false,
                })
                const coupon: ICoupon = response.data
                let discount = 0
                //check if the coupon is valid at all
                if (coupon.valid) {
                    //check if the coupon is valid for the selected plan
                    if (this.state.billing === Billing.YEARLY) {
                        if (!coupon.appliesTo.includes(StripeProduct.YEARLY)) {
                            toast.error(
                                'Promo code not available on the yearly subscription'
                            )
                            this.setState({
                                promoCheckInProgress: false,
                            })
                            return
                        }
                    } else if (this.state.billing === Billing.LIFETIME) {
                        if (
                            !coupon.appliesTo.includes(StripeProduct.LIFETIME)
                        ) {
                            toast.error(
                                'Promo code not available on the lifetime subscription'
                            )
                            this.setState({
                                promoCheckInProgress: false,
                            })
                            return
                        }
                    }
                    let subscriptionCost: number = this.fetchSubscriptionCost()

                    if (coupon.amountOff) {
                        discount = coupon.amountOff
                    } else {
                        discount = (coupon.percentOff / 100) * subscriptionCost
                    }

                    this.setState(
                        { discount: discount, activeCoupon: coupon },
                        () => {
                            toast.success('This promotion has been applied!')
                        }
                    )
                } else {
                    toast.success('This promotion is no longer valid.')
                }
            })
            .catch((e) => {
                toast.error('Promo code not found')
                this.setState({
                    promoCheckInProgress: false,
                })
            })
    }

    fetchSubscriptionCost = () => {
        if (this.state.plan === Plans.BASIC) {
            return this.state.billing === Billing.YEARLY
                ? BasicCost.YEARLY
                : BasicCost.LIFETIME
        } else {
            return this.state.billing === Billing.YEARLY
                ? ProCost.YEARLY
                : ProCost.LIFETIME
        }
    }

    computeRecurringTotal = () => {
        let subscriptionCost: number = this.fetchSubscriptionCost()
        if (this.state.billing === Billing.LIFETIME) {
            return 0
        }

        if (this.state.plan === Plans.BASIC) {
            subscriptionCost =
                this.state.billing === Billing.YEARLY
                    ? BasicCost.YEARLY
                    : BasicCost.YEARLY
        } else {
            subscriptionCost =
                this.state.billing === Billing.YEARLY
                    ? ProCost.YEARLY
                    : ProCost.YEARLY
        }

        if (
            this.state.activeCoupon &&
            this.state.activeCoupon.duration === 'forever'
        ) {
            return 0.0
        } else {
            return subscriptionCost.toFixed(2)
        }
    }

    render() {
        const type: string = this.state.type
        const view: ViewType = Views[this.state.view].toLowerCase() as ViewType
        const subscriptionCost: number = this.fetchSubscriptionCost()
        return (
            <>
                <Row
                    style={{
                        paddingBottom: 53,
                        overflowX: isMobile ? 'hidden' : 'initial',
                    }}
                >
                    <Col lg={6} xs={12}>
                        <div style={{ paddingTop: 20 }}>
                            <div
                                style={{
                                    display: 'inline-block',
                                    backgroundColor: DEBUG
                                        ? 'grey'
                                        : 'transparent',
                                }}
                            >
                                <img
                                    src={
                                        window.location.host.includes(
                                            'institutional'
                                        )
                                            ? inst
                                            : logo
                                    }
                                    style={{
                                        height: 60,
                                        paddingLeft: 0,
                                        width: 170,
                                        display: 'inline-block',
                                    }}
                                    alt="Inspire Insight"
                                />
                            </div>
                            <div
                                style={{
                                    height: 50,
                                    paddingLeft: 0,
                                    width: 240,
                                    marginTop: 10,
                                    marginLeft: 20,
                                    display: 'inline-block',
                                    verticalAlign: 'top',
                                }}
                            >
                                <Button
                                    type="whiteShadow"
                                    width={240}
                                    height={50}
                                    icon={<ArrowLeft width={20} height={20} />}
                                    onClick={() => {
                                        this.props.history.push('/')
                                        window.location.reload()
                                    }}
                                >
                                    Back
                                </Button>
                            </div>
                        </div>
                        {renderIf(
                            this.state.status === Status.UNPAID,
                            <div
                                style={{ padding: '0 10px', marginBottom: 30 }}
                            >
                                <p
                                    style={{
                                        ...globals.typeface.headerIcon,
                                        width: '100%',
                                        height: 50,
                                        fontSize: 50,
                                    }}
                                >
                                    Subscribe
                                </p>
                                <p style={TEMPLATE[type][view].subtitleII}>
                                    Choose your plan
                                </p>
                                <div
                                    style={{
                                        width: '100%',
                                        height: 80,
                                        marginTop: 20,
                                        paddingBottom: Sizes.VERTICAL_GAP,
                                    }}
                                >
                                    <div
                                        style={{
                                            verticalAlign: 'top',
                                            width: 'calc(50% - 40px)',
                                            height: 80,
                                            backgroundColor: DEBUG
                                                ? 'red'
                                                : 'transparent',
                                            display: 'inline-block',
                                        }}
                                    >
                                        {this.renderPlanSelection({
                                            plan: Plans.BASIC,
                                            isSelected:
                                                this.state.plan === Plans.BASIC,
                                            onRadioChange: () => {
                                                this.setState({
                                                    plan: Plans.BASIC,
                                                    activeCoupon: null,
                                                    discount: 0,
                                                })
                                            },
                                        })}
                                    </div>
                                    <div
                                        style={{
                                            verticalAlign: 'top',
                                            width: 'calc(50% - 40px)',
                                            height: 80,
                                            backgroundColor: DEBUG
                                                ? 'blue'
                                                : 'transparent',
                                            display: 'inline-block',
                                        }}
                                    >
                                        {this.renderPlanSelection({
                                            plan: Plans.PRO,
                                            isSelected:
                                                this.state.plan === Plans.PRO,
                                            onRadioChange: () => {
                                                this.setState({
                                                    plan: Plans.PRO,
                                                    activeCoupon: null,
                                                    discount: 0,
                                                })
                                            },
                                        })}
                                    </div>
                                </div>

                                <p style={TEMPLATE[type][view].subtitleII}>
                                    Choose your billing cycle
                                </p>
                                <div
                                    style={{
                                        width: '100%',
                                        height: 80,
                                        marginTop: 20,
                                        paddingBottom: Sizes.VERTICAL_GAP,
                                    }}
                                >
                                    <div
                                        style={{
                                            verticalAlign: 'top',
                                            width: 'calc(50% - 40px)',
                                            height: 80,
                                            backgroundColor: DEBUG
                                                ? 'red'
                                                : 'transparent',
                                            display: 'inline-block',
                                        }}
                                    >
                                        {this.renderBillingComponent({
                                            period: 'Yearly',
                                            price:
                                                this.state.plan === Plans.PRO
                                                    ? `$${ProCost.YEARLY}`
                                                    : `$${BasicCost.YEARLY}`,
                                            isSelected:
                                                this.state.billing ===
                                                Billing.YEARLY,
                                            onRadioChange: () => {
                                                this.setState({
                                                    billing: Billing.YEARLY,
                                                    activeCoupon: null,
                                                    discount: 0,
                                                })
                                            },
                                        })}
                                    </div>
                                    <div
                                        style={{
                                            verticalAlign: 'top',
                                            width: 'calc(50% - 40px)',
                                            height: 80,
                                            backgroundColor: DEBUG
                                                ? 'blue'
                                                : 'transparent',
                                            display: 'inline-block',
                                        }}
                                    >
                                        {this.renderBillingComponent({
                                            period: 'Lifetime',
                                            price:
                                                this.state.plan === Plans.PRO
                                                    ? `$${ProCost.LIFETIME}`
                                                    : `$${BasicCost.LIFETIME}`,
                                            isSelected:
                                                this.state.billing ===
                                                Billing.LIFETIME,
                                            parenthetical: '(one time payment)',
                                            onRadioChange: () => {
                                                this.setState({
                                                    billing: Billing.LIFETIME,
                                                    activeCoupon: null,
                                                    discount: 0,
                                                })
                                            },
                                        })}
                                    </div>
                                </div>

                                {renderIf(
                                    this.state.isLoggedIn,
                                    <>
                                        <p
                                            style={
                                                TEMPLATE[type][view].subtitleII
                                            }
                                        >
                                            Billing details
                                        </p>
                                        <InputField
                                            title="Billing Address"
                                            id="address"
                                            value={this.state.address}
                                            onChange={this.onChange}
                                        />
                                        <InputField
                                            title="Apartment, suite, unit etc."
                                            id="apartment"
                                            value={this.state.apartment}
                                            onChange={this.onChange}
                                        />
                                        <InputField
                                            title="City"
                                            id="city"
                                            value={this.state.city}
                                            onChange={this.onChange}
                                        />
                                        <InputField
                                            title="State"
                                            id="state"
                                            value={this.state.state}
                                            onChange={this.onChange}
                                        />
                                        <p
                                            style={
                                                TEMPLATE[type][view].subtitleII
                                            }
                                        >
                                            Promo Code
                                        </p>
                                        <InputField
                                            title="Promo Code"
                                            id="promo"
                                            value={this.state.promoCode}
                                            onChange={(e) =>
                                                this.setState({
                                                    promoCode: e.target.value,
                                                })
                                            }
                                            renderIf={true}
                                        />
                                        <Button
                                            type="primary"
                                            style={{
                                                marginTop: 10,
                                                width: 130,
                                            }}
                                            onClick={() => {
                                                this.setState(
                                                    {
                                                        promoCheckInProgress:
                                                            true,
                                                    },
                                                    this.verifyCouponCode
                                                )
                                            }}
                                            disabled={
                                                this.state.promoCheckInProgress
                                            }
                                        >
                                            Apply{' '}
                                            {this.state
                                                .promoCheckInProgress && (
                                                <LoadingOutlined />
                                            )}
                                        </Button>
                                        <p
                                            style={
                                                TEMPLATE[type][view].subtitleII
                                            }
                                        >
                                            Payment
                                        </p>
                                        <div
                                            style={{
                                                marginBottom: -20,
                                                marginTop: 20,
                                            }}
                                        >
                                            <PaymentType height={20} />
                                        </div>
                                        <InputField
                                            title="Card Number"
                                            id="cardNumber"
                                            value={this.state.cardNumber}
                                            onChange={this.onChange}
                                            renderIf={true}
                                        />
                                        <InputField
                                            title="Expiration Month"
                                            id="cardExpMonth"
                                            value={this.state.cardExpMonth}
                                            onChange={this.onChange}
                                            renderIf={true}
                                            placeholder="MM"
                                        />
                                        <InputField
                                            title="Expiration Year"
                                            id="cardExpYear"
                                            value={this.state.cardExpYear}
                                            placeholder={'YYYY'}
                                            onChange={this.onChange}
                                            renderIf={true}
                                        />
                                        <InputField
                                            title="CVC Number"
                                            id="cardCvc"
                                            value={this.state.cardCvc}
                                            onChange={this.onChange}
                                            renderIf={true}
                                        />
                                        {renderIf(
                                            !isMobile,
                                            this.renderAgreement()
                                        )}
                                    </>
                                )}
                            </div>
                        )}
                        {renderIf(
                            this.state.status === Status.PAID,
                            <>
                                <p
                                    style={{
                                        ...globals.typeface.headerIcon,
                                        width: '100%',
                                        height: 50,
                                        fontSize: 50,
                                    }}
                                >
                                    Checkout
                                </p>
                                <p style={TEMPLATE[type][view].text}>
                                    Your order is:{' '}
                                    <strong>{this.state.confirmation}</strong>{' '}
                                    and your account is now ready to use! Your
                                    receipt has been emailed to you as well.
                                </p>
                                <Button type="inspireBlue" width={200}>
                                    Your Dashboard
                                </Button>
                                <p style={TEMPLATE[type][view].subtitleII}>
                                    Order Details
                                </p>
                                {this.renderOrderDetailsSection('Account', [
                                    `${this.state.firstName} ${this.state.lastName}`,
                                    `${this.state.email}`,
                                    `${this.state.phone}`,
                                ])}
                                {this.renderOrderDetailsSection('Billing', [
                                    `${this.state.address}`,
                                    `${this.state.city}${
                                        this.state.city ? ', ' : ''
                                    }${this.state.state} ${this.state.zipCode}`,
                                ])}
                                {this.renderOrderDetailsSection('Payment', [
                                    `${
                                        this.state.cardType
                                    } ending in ${this.state.cardNumber.substring(
                                        4,
                                        this.state.cardNumber.length
                                    )}`,
                                ])}
                            </>
                        )}
                    </Col>
                    <Col
                        lg={6}
                        xs={12}
                        style={{
                            padding: '30px 50px',
                            background: 'white',
                        }}
                    >
                        <div
                            style={{
                                position: 'sticky',
                                top: 0,
                            }}
                        >
                            <p style={TEMPLATE[type][view].subtitleII}>
                                Summary
                            </p>

                            <div
                                style={{
                                    backgroundColor: DEBUG
                                        ? 'red'
                                        : 'transparent',
                                    height: 100,
                                    width: 'calc(100% - 40px)',
                                    marginTop: 20,
                                }}
                            >
                                <div
                                    style={{
                                        display: 'inline-block',
                                        verticalAlign: 'top',
                                        width: 100,
                                        height: 100,
                                        backgroundColor: DEBUG
                                            ? 'lime'
                                            : 'transparent',
                                        float: 'left',
                                    }}
                                >
                                    <div
                                        style={{
                                            display: 'table-cell',
                                            width: 100,
                                            height: 80,
                                            verticalAlign: 'middle',
                                            textAlign: 'center',
                                            overflow: 'hidden',
                                        }}
                                    >
                                        <img
                                            src={InspireSquare}
                                            style={{
                                                height: 80,
                                                paddingLeft: 0,
                                                width: 80,
                                                float: 'left',
                                                borderRadius: 10,
                                            }}
                                            alt="Inspire Insight"
                                        />
                                    </div>
                                </div>
                                <div
                                    style={{
                                        display: 'inline-block',
                                        verticalAlign: 'top',
                                        height: 90,
                                        paddingTop: 10,
                                        width: 'calc(100% - 200px)',

                                        backgroundColor: DEBUG
                                            ? 'pink'
                                            : 'transparent',
                                    }}
                                >
                                    <div
                                        style={{
                                            ...TEMPLATE[type][view].subtitle,
                                            verticalAlign: 'top',
                                            width: '100%',
                                            height: 50,
                                            backgroundColor: DEBUG
                                                ? 'blue'
                                                : 'transparent',
                                            float: 'left',
                                        }}
                                    >
                                        {this.state.plan === Plans.PRO ? (
                                            <>
                                                Inspire&nbsp;Insight&nbsp;Premium{' '}
                                            </>
                                        ) : (
                                            <>Inspire&nbsp;Insight&nbsp;Basic</>
                                        )}
                                    </div>
                                    <div
                                        style={{
                                            ...TEMPLATE[type][view].text,
                                            verticalAlign: 'top',
                                            width: '100%',
                                            height: 50,
                                            backgroundColor: DEBUG
                                                ? 'red'
                                                : 'transparent',
                                            float: 'left',
                                        }}
                                    >
                                        {this.state.billing === Billing.LIFETIME
                                            ? 'Lifetime Subscription'
                                            : 'Yearly Subscription'}
                                    </div>
                                </div>
                                <div
                                    style={{
                                        display: 'inline-block',
                                        verticalAlign: 'top',
                                        width: 100,
                                        height: 100,
                                        backgroundColor: DEBUG
                                            ? 'lime'
                                            : 'transparent',
                                        float: 'right',
                                    }}
                                >
                                    <div
                                        style={{
                                            ...TEMPLATE[type][view].text,
                                            display: 'table-cell',
                                            width: 100,
                                            height: 100,
                                            verticalAlign: 'middle',
                                            paddingTop: 15,
                                            textAlign: 'center',
                                        }}
                                    >
                                        {`$${subscriptionCost.toFixed(2)}`}
                                    </div>
                                </div>
                            </div>

                            {this.renderLineBreak()}

                            {this.renderCostLine(
                                'Promo',
                                `$${this.state.discount.toFixed(2)}`
                            )}

                            {this.renderLineBreak()}

                            {this.renderCostLine(
                                'Due Today',
                                `$${(
                                    subscriptionCost - this.state.discount
                                ).toFixed(2)}`
                            )}

                            {this.renderLineBreak()}

                            {this.renderCostLine(
                                'Recurring Total',
                                `$${this.computeRecurringTotal()}`
                            )}

                            <p style={TEMPLATE[type][view].timeStamp}>
                                First renewal: {this.getRenewalDate()}
                            </p>
                            {renderIf(isMobile, this.renderAgreement())}
                        </div>
                    </Col>
                </Row>
                <div
                    style={{
                        bottom: 0,
                        left: 0,
                        position: 'fixed',
                        backgroundColor: '#e9e9e9',
                        width: '100vw',
                        height: 45,
                        paddingLeft: 50,
                        paddingRight: 50,
                        paddingTop: 8,
                    }}
                >
                    <Row>
                        <Col>
                            <p
                                style={{
                                    color: '#717171',
                                    fontSize: 12,
                                    fontWeight: 600,
                                    cursor: 'pointer',
                                }}
                                onClick={() => {
                                    this.props.history.push(`/terms-of-service`)
                                }}
                            >
                                Terms
                            </p>
                        </Col>
                        <Col style={{ marginLeft: 30 }}>
                            <p
                                style={{
                                    color: '#717171',
                                    fontSize: 12,
                                    fontWeight: 600,
                                    cursor: 'pointer',
                                }}
                                onClick={() => {
                                    this.props.history.push(`/privacy-policy`)
                                }}
                            >
                                Privacy
                            </p>
                        </Col>
                        <Col style={{ marginLeft: 30 }}>
                            <p
                                style={{
                                    color: '#717171',
                                    fontSize: 12,
                                    fontWeight: 600,
                                    cursor: 'pointer',
                                }}
                                onClick={() => {
                                    this.props.history.push(`/help-and-support`)
                                }}
                            >
                                Help & Support
                            </p>
                        </Col>
                        <Col xs />
                        <Col style={{ paddingRight: 160 }}>
                            <p
                                style={{
                                    color: '#717171',
                                    fontSize: 12,
                                    fontWeight: 600,
                                }}
                            >
                                © Copyright - Inspire Insight
                            </p>
                        </Col>
                    </Row>
                </div>
            </>
        )
    }
    renderOrderDetailsSection(title: string, values: Array<string>) {
        return (
            <>
                <p
                    style={{
                        ...(globals.typeface.spacedUppercaseThin as Record<
                            string,
                            any
                        >),
                        fontSize: 12,
                    }}
                >
                    {title}
                </p>
                {values.map((value: string) => {
                    return (
                        <p
                            style={{
                                ...globals.typeface.spacedUppercase,
                                fontWeight: 700,
                            }}
                        >
                            {value}
                        </p>
                    )
                })}
            </>
        )
    }

    getRenewalDate() {
        if (this.state.billing === Billing.LIFETIME) {
            return 'Never'
        }
        enum Period {
            DAY_OF_WEEK,
            MONTH,
            DAY,
            YEAR,
        }

        const date: Date = new Date()

        if (!this.state.activeCoupon) {
            if (this.state.billing === Billing.YEARLY)
                date.setFullYear(date.getFullYear() + 1)
            else date.setMonth(date.getMonth() + 1)
            const periods: Array<string> = date.toString().split(' ')

            return `${periods[Period.MONTH]} ${periods[Period.DAY]}, ${
                periods[Period.YEAR]
            }`
        } else {
            if (this.state.activeCoupon.duration === 'forever') {
                return 'Never'
            } else if (this.state.activeCoupon.duration === 'once') {
                return new Date(
                    date.setMonth(date.getMonth() + 1)
                ).toLocaleDateString()
            } else {
                return new Date(
                    date.setMonth(
                        date.getMonth() +
                            this.state.activeCoupon.durationInMonths
                    )
                )
            }
        }
    }

    getTestNotif() {
        return (
            <MultilineNotif
                spacing={5}
                components={[
                    {
                        width: 700,
                        height: 30,
                        value: 'The promo code has been removed because your cart does not qualify for this promotion.',
                        type: 'failure',
                    },
                    {
                        width: 700,
                        height: 30,
                        title: 'SAVE20',
                        value: '($20 your first annual purchase) has been applied',
                        type: 'success',
                    },
                    {
                        width: 700,
                        height: 50,
                        widthIcon: 30,
                        title: 'Billed Today',
                        value: '',
                        type: 'dark',
                        style: {
                            backgroundColor: 'whitesmoke',
                        },
                        addComponent: {
                            component: <>{this.state.subtotal}</>,
                            width: 130,
                            style: {
                                backgroundColor: 'red',
                            },
                        },
                    },
                    {
                        width: 700,
                        height: 30,
                        value: 'Your password must be 8 characters long',
                        type: 'nutral',
                    },
                ]}
                style={{
                    marginTop: 26,
                }}
            />
        )
    }
    getNotifByErrorCodes(errorCodes: Array<number>) {
        return (
            <MultilineNotifPresets
                spacing={5}
                prefabCodes={errorCodes}
                style={{
                    marginTop: 26,
                }}
            />
        )
    }
}

export default withRouter(Checkout)
