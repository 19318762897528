import React from 'react'
import Button, { ButtonType } from '../../insightui/Button'
import { store } from '../../store'
import { signIn, register } from '../../reducers/auth'
import { globals } from '../../utils/globals'
import logo from '../../images/logo.svg'
import { ToastContainer } from 'react-toastify'
import { RegistrationRequest } from '../../models/state'
import CheckboxCustom, { CheckboxState } from '../../insightui/CheckboxCustom'
import { config } from '../../models/config'
import Disclaimer from '../../insightui/Disclaimer'
import InputField from '../../insightui/InputFieldCustom'
import {
    CharacterLimits,
    forgotPassword,
    changePassword,
} from '../../pages/Register'
import { isMobile, isTablet } from 'react-device-detect'
import Header from '../../insightui/Header'
import Footer from '../../insightui/Footer'
import { Link, RouteComponentProps, withRouter } from 'react-router-dom'

const DEBUG: boolean = false

enum Views {
    DESKTOP,
    MOBILE,
}

export enum Plans {
    BASIC,
    PRO,
    ADVISOR,
}

export enum Sizes {
    FIELD_WIDTH_DESKTOP = 440,
    CONTAINER_MARGIN_LEFT_DESKTOP = 283,
    CONTAINER_MOBILE = 320,
    BUTTON_WIDTH_MOBILE = 320,
    FIELD_WIDTH_MOBILE = 300,
    FIELD_MARGIN_LEFT_MOBILE = 10,
    FIELD_MARGIN_LEFT_DESKTOP = 25,
    MOBILE_FIELD = 100,
    MOBILE_BUTTON = 100,
    HALF_FIELD = 25,
    FULL_FIELD = 50,
    FULL_BUTTON = 50,
    DISCLAIMER = 50,
    DISCLAIMER_MOBILE = 100,
    VERTICAL_GAP = 40,
    MINI_FOOTER_HEIGHT = 150,
}

type LoginRegister =
    | 'login'
    | 'registration'
    | 'forgot-password'
    | 'change-password'
    | 'activation'
    | 'registration-complete'

const TEXT: any = {
    login: {
        title: 'Welcome Back to Inspire Insight',
        subtitle: 'Sign-in below and pick up right where you left off',
        links: [],
    },
    registration: {
        title: 'Create an Inspire Insight account',
        subtitle: `Fill out the form below to create a personalized Inspire Insight account. Save your portfolio(s), see what's going on within your investments and much more. You will be directed to select a plan and checkout in the next steps.`,
        disclaimer:
            'I agree that my personal data will be used to support my experience throughout this website and by signing up for an Inspire Insight account means I agree to the Privacy Policy and Terms of Service.',
    },
    activation: {
        title: 'Account Activated',
        subtitle: 'Your account has been activated. Click next to finish up.',
    },
    'change-password': {
        title: 'Reset Your Password',
        subtitle: 'Enter your new password below',
    },
    'forgot-password': {
        title: 'Reset Your Password',
        subtitle: `Enter your email and we'll send you instructions on how to reset your password.`,
    },
    'registration-complete': {
        title: 'Activate your Account',
        subtitle: 'Please check your email and follow the instructions.',
        button: 'Next',
    },
}

export enum PaddingOffset {
    MOBILE_FIELD = 120,
    MOBILE_BUTTON = 100,
    HALF_FIELD = 80,
    FULL_FIELD = 120,
    FULL_BUTTON = 100,
    DISCLAIMER = 110,
    DISCLAIMER_MOBILE = 110,
}

interface DynamicComponent {
    value: string
    componentName: string
    type?: string
    width?: number
    validated?: boolean
    style?: Record<string, any>
}

interface Template {
    title: Record<string, any>
    subtitle: Record<string, any>
    button: Record<string, any>
    logo: Record<string, any>
    container: Record<string, any>
    subcontainer: Record<string, any>
    localFooter: Record<string, any>
    field: Record<string, any>
    disclaimer: Record<string, any>
}

function getTemplates() {
    const sharedStyles: Record<string, any> = {
        title: {
            fontSize: 28,
            letterSpacing: -0.5,
            fontWeight: 800,
            textAlign: 'center',
            display: 'block',
            width: '100%',
            height: 'auto',
            backgroundColor: 'white',
            marginBottom: -10,
        },
        subtitle: {
            ...globals.typeface.searchBar,
            textAlign: 'center',
            display: 'block',
            width: '100%',
            height: 'auto',
            backgroundColor: 'white',
            marginBottom: 30,
            fontSize: 16,
        },
        container: {
            style: {
                border: '1px solid transparent',
                padding: 25,
                marginTop: 100,
                paddingBottom: 35,
                backgroundColor: 'white',
                height: 'auto',
            },
        },
        subcontainer: {
            marginLeft: `calc(50% - ${75}px)`,
            width: 150,
            height: 60,
            backgroundColor: 'white',
            marginTop: 20,
            marginBottom: 30,
        },
    }

    const sharedStylesMobile: Record<string, any> = {
        disclaimer: {
            marginLeft: 0,
            width: 'calc(100%)',
        },
        container: {
            margin: '0 auto',
            width: 330,
        },
        button: {
            right: {
                width: Sizes.BUTTON_WIDTH_MOBILE,
                style: {
                    marginLeft: 100,
                },
            },
        },
        field: {
            marginLeft: 0,
        },
    }

    const sharedStylesDesktop: Record<string, any> = {
        field: {
            marginLeft: 25,
        },
        disclaimer: {
            marginLeft: 25,
            width: 'calc(100% - 38px)',
        },
        button: {
            right: {
                width: 464,
                style: {
                    marginLeft: 100,
                },
            },
        },
    }

    const loginDesktop: Template = {
        field: {
            ...sharedStylesDesktop.field,
        },
        disclaimer: { ...sharedStylesDesktop.disclaimer },
        title: {
            value: TEXT.login.title,

            style: {
                ...globals.typeface.headerIcon,
                ...sharedStyles.title,
            },
        },
        subtitle: {
            value: TEXT.login.subtitle,
            style: {
                ...sharedStyles.subtitle,
            },
        },
        button: {
            left: {
                componentName: 'anchor',
                type: 'inspireBlue',
                value: (
                    <Link
                        style={globals.typeface.greyLink}
                        to={`${config.host}/forgot-password`}
                    >
                        I Forgot my Password
                    </Link>
                ),
            },
            right: {
                ...sharedStylesDesktop.button.right,
                value: 'Next',
                componentName: 'button',
                type: 'inspireBlue',
                validated: true,
            },
        },
        logo: {
            img: logo,
            style: {
                height: 60,
                paddingLeft: 0,
                width: '100%',
            },
        },
        container: {
            style: {
                ...sharedStyles.container.style,
                marginLeft: `calc(50% - ${Sizes.CONTAINER_MARGIN_LEFT_DESKTOP}px)`,
                width: 500,
            },
        },
        subcontainer: {
            ...sharedStyles.subcontainer,
            marginLeft: `calc(50% - ${75}px)`,
            width: '100%',
        },
        localFooter: {
            value: <></>,
            componentName: 'anchor',
        },
    }

    const loginMobile: Template = {
        disclaimer: { ...sharedStylesMobile.disclaimer },
        field: {
            ...sharedStylesMobile.field,
        },
        title: {
            value: TEXT.login.title,
            style: {
                ...globals.typeface.headerIcon,
                ...sharedStyles.title,
            },
        },
        subtitle: {
            value: TEXT.login.subtitle,
            style: {
                ...sharedStyles.subtitle,
            },
        },
        button: {
            left: {
                componentName: 'anchor',
                type: '',
                value: (
                    <Link
                        style={globals.typeface.greyLink}
                        to={`${config.host}/forgot-password`}
                    >
                        I Forgot my Password
                    </Link>
                ),
            },
            right: {
                ...sharedStylesMobile.button.right,
                value: 'Next',
                componentName: 'button',
                type: 'inspireBlue',
                validated: true,
            },
        },
        logo: {
            img: logo,
            style: {
                height: 60,
                paddingLeft: 0,
                width: '100%',
            },
        },
        container: {
            style: {
                ...sharedStylesMobile.container,
                ...sharedStyles.container.style,
            },
        },
        subcontainer: {
            ...sharedStyles.subcontainer,
            marginLeft: `calc(50% - ${75}px)`,
            width: '100%',
        },
        localFooter: {
            value: <></>,
            componentName: 'anchor',
        },
    }

    const registrationDesktop: Template = {
        disclaimer: { ...sharedStylesDesktop.disclaimer },
        field: {
            ...sharedStylesDesktop.field,
        },
        title: {
            value: TEXT.registration.title,
            style: {
                ...globals.typeface.headerIcon,
                ...sharedStyles.title,
            },
        },
        subtitle: {
            value: TEXT.registration.subtitle,
            style: {
                ...sharedStyles.subtitle,
            },
        },
        button: {
            left: {
                value: TEXT.registration.disclaimer,
                componentName: 'checkbox',
                type: '',
            },
            right: {
                ...sharedStylesDesktop.button.right,
                value: 'Create Account & Subscribe',
                componentName: 'button',
                type: 'inspireBlue',
                validated: false,
            },
        },
        logo: {
            img: logo,
            style: {
                height: 60,
                paddingLeft: 0,
                width: '100%',
            },
        },
        container: {
            style: {
                ...sharedStyles.container.style,

                marginLeft: `calc(50% - ${Sizes.CONTAINER_MARGIN_LEFT_DESKTOP}px)`,
                width: 500,
                overflow: 'hidden',
            },
        },
        subcontainer: {
            ...sharedStyles.subcontainer,
        },
        localFooter: {
            value: (
                <>
                    <p
                        style={{
                            ...globals.typeface.greyLink,
                            display: 'inline-block',
                            color: 'black',
                        }}
                    >
                        Have an account?&nbsp;
                    </p>
                    <Link
                        style={{ color: '#2c8aae', fontWeight: 'bold' }}
                        to={`/login`}
                    >
                        Login here
                    </Link>
                </>
            ),
            componentName: 'anchor',
        },
    }

    const registrationMobile: Template = {
        disclaimer: { ...sharedStylesMobile.disclaimer },
        field: {
            ...sharedStylesMobile.field,
        },
        title: {
            value: TEXT.registration.title,
            style: {
                ...globals.typeface.headerIcon,
                ...sharedStyles.title,
            },
        },
        subtitle: {
            value: TEXT.registration.subtitle,
            style: {
                ...sharedStyles.subtitle,
            },
        },
        button: {
            left: {
                value: TEXT.registration.disclaimer,
                componentName: 'checkbox',
                type: '',
            },
            right: {
                ...sharedStylesMobile.button.right,
                value: 'Create Account & Subscribe',
                componentName: 'button',
                type: 'inspireBlue',
                validated: false,
            },
        },
        logo: {
            img: logo,
            style: {
                height: 60,
                paddingLeft: 0,
                width: '100%',
            },
        },
        container: {
            style: {
                ...sharedStylesMobile.container,
                ...sharedStyles.container.style,

                overflow: 'hidden',
            },
        },
        subcontainer: {
            ...sharedStyles.subcontainer,
        },
        localFooter: {
            value: (
                <>
                    <p
                        style={{
                            ...globals.typeface.greyLink,
                            display: 'inline-block',
                        }}
                    >
                        Have an account?&nbsp;
                    </p>
                    <Link style={globals.typeface.greyLink} to={`/login`}>
                        Login here
                    </Link>
                </>
            ),
            componentName: 'anchor',
        },
    }

    const forgotPasswordDesktop: Template = {
        disclaimer: { ...sharedStylesDesktop.disclaimer },
        field: {
            ...sharedStylesDesktop.field,
        },
        title: {
            value: TEXT['forgot-password'].title,
            style: {
                ...globals.typeface.headerIcon,
                ...sharedStyles.title,
            },
        },
        subtitle: {
            value: TEXT['forgot-password'].subtitle,
            style: {
                ...sharedStyles.subtitle,
            },
        },
        button: {
            left: {
                componentName: 'null',
                type: '',
                value: <></>,
            },
            right: {
                ...sharedStylesDesktop.button.right,
                value: 'Next',
                componentName: 'button',
                type: 'inspireBlue',
                validated: true,
            },
        },
        logo: {
            img: logo,
            style: {
                height: 60,
                paddingLeft: 0,
                width: '100%',
            },
        },
        container: {
            style: {
                ...sharedStyles.container.style,

                marginLeft: `calc(50% - ${Sizes.CONTAINER_MARGIN_LEFT_DESKTOP}px)`,
                width: 500,
                overflow: 'hidden',
            },
        },
        subcontainer: {
            ...sharedStyles.subcontainer,
        },
        localFooter: {
            value: <></>,
            componentName: 'anchor',
        },
    }

    const forgotPasswordMobile: Template = {
        disclaimer: { ...sharedStylesMobile.disclaimer },
        field: {
            ...sharedStylesMobile.field,
        },
        title: {
            value: TEXT['forgot-password'].title,
            style: {
                ...globals.typeface.headerIcon,
                ...sharedStyles.title,
            },
        },
        subtitle: {
            value: TEXT['forgot-password'].subtitle,
            style: {
                ...sharedStyles.subtitle,
            },
        },
        button: {
            left: {
                componentName: 'null',
                type: '',
                value: <></>,
            },
            right: {
                ...sharedStylesMobile.button.right,
                value: 'Next',
                componentName: 'button',
                type: 'inspireBlue',
                validated: true,
            },
        },
        logo: {
            img: logo,
            style: {
                height: 60,
                paddingLeft: 0,
                width: '100%',
            },
        },
        container: {
            style: {
                ...sharedStylesMobile.container,
                ...sharedStyles.container.style,

                overflow: 'hidden',
            },
        },
        subcontainer: {
            ...sharedStyles.subcontainer,
        },
        localFooter: {
            value: <></>,
            componentName: 'anchor',
        },
    }

    const changePasswordDesktop: Template = {
        disclaimer: { ...sharedStylesDesktop.disclaimer },
        field: {
            ...sharedStylesDesktop.field,
        },
        title: {
            value: TEXT['change-password'].title,
            style: {
                ...globals.typeface.headerIcon,
                ...sharedStyles.title,
            },
        },
        subtitle: {
            value: TEXT['change-password'].subtitle,
            style: {
                ...sharedStyles.subtitle,
            },
        },
        button: {
            left: {
                componentName: 'null',
                type: '',
                value: <></>,
            },
            right: {
                ...sharedStylesDesktop.button.right,
                value: 'Save Password',
                componentName: 'button',
                type: 'inspireBlue',
                validated: true,
            },
        },
        logo: {
            img: logo,
            style: {
                height: 60,
                paddingLeft: 0,
                width: '100%',
            },
        },
        container: {
            style: {
                ...sharedStyles.container.style,

                marginLeft: `calc(50% - ${Sizes.CONTAINER_MARGIN_LEFT_DESKTOP}px)`,
                width: 500,
            },
        },
        subcontainer: {
            ...sharedStyles.subcontainer,
        },
        localFooter: {
            value: <></>,
            componentName: 'anchor',
        },
    }

    const changePasswordMobile: Template = {
        disclaimer: { ...sharedStylesMobile.disclaimer },
        field: {
            ...sharedStylesMobile.field,
        },
        title: {
            value: TEXT['change-password'].title,
            style: {
                ...globals.typeface.headerIcon,
                ...sharedStyles.title,
            },
        },
        subtitle: {
            value: TEXT['change-password'].subtitle,
            style: {
                ...sharedStyles.subtitle,
            },
        },
        button: {
            left: {
                componentName: 'null',
                type: '',
                value: <></>,
            },
            right: {
                ...sharedStylesMobile.button.right,
                value: 'Save Password',
                componentName: 'button',
                type: 'inspireBlue',
                validated: true,
            },
        },
        logo: {
            img: logo,
            style: {
                height: 60,
                paddingLeft: 0,
                width: '100%',
            },
        },
        container: {
            style: {
                ...sharedStylesMobile.container,
                ...sharedStyles.container.style,
            },
        },
        subcontainer: {
            ...sharedStyles.subcontainer,
        },
        localFooter: {
            value: <></>,
            componentName: 'anchor',
        },
    }

    const activationDesktop: Template = {
        disclaimer: { ...sharedStylesDesktop.disclaimer },
        field: {
            ...sharedStylesDesktop.field,
        },
        title: {
            value: TEXT.activation.title,
            style: {
                ...globals.typeface.headerIcon,
                ...sharedStyles.title,
            },
        },
        subtitle: {
            value: TEXT.activation.subtitle,
            style: {
                ...sharedStyles.subtitle,
            },
        },
        button: {
            left: {
                componentName: 'null',
                type: '',
                value: <></>,
            },
            right: {
                ...sharedStylesDesktop.button.right,
                value: 'Next',
                componentName: 'button',
                type: 'inspireBlue',
                validated: true,
            },
        },
        logo: {
            img: logo,
            style: {
                height: 60,
                paddingLeft: 0,
                width: '100%',
            },
        },
        container: {
            style: {
                ...sharedStyles.container.style,

                marginLeft: `calc(50% - ${Sizes.CONTAINER_MARGIN_LEFT_DESKTOP}px)`,
                width: 500,
            },
        },
        subcontainer: {
            ...sharedStyles.subcontainer,
        },
        localFooter: {
            value: <></>,
            componentName: 'anchor',
        },
    }

    const activationMobile: Template = {
        disclaimer: { ...sharedStylesMobile.disclaimer },
        field: {
            ...sharedStylesMobile.field,
        },
        title: {
            value: TEXT.activation.title,
            style: {
                ...globals.typeface.headerIcon,
                ...sharedStyles.title,
            },
        },
        subtitle: {
            value: TEXT.activation.subtitle,
            style: {
                ...sharedStyles.subtitle,
            },
        },
        button: {
            left: {
                componentName: 'null',
                type: '',
                value: <></>,
            },
            right: {
                ...sharedStylesMobile.button.right,
                value: 'Next',
                componentName: 'button',
                type: 'inspireBlue',
                validated: true,
            },
        },
        logo: {
            img: logo,
            style: {
                height: 60,
                paddingLeft: 0,
                width: '100%',
            },
        },
        container: {
            style: {
                ...sharedStylesMobile.container,
                ...sharedStyles.container.style,
            },
        },
        subcontainer: {
            ...sharedStyles.subcontainer,
        },
        localFooter: {
            value: <></>,
            componentName: 'anchor',
        },
    }

    const registrationCompleteDesktop: Template = {
        disclaimer: { ...sharedStylesDesktop.disclaimer },
        field: {
            ...sharedStylesDesktop.field,
        },
        title: {
            value: TEXT['registration-complete'].title,
            style: {
                ...globals.typeface.headerIcon,
                ...sharedStyles.title,
            },
        },
        subtitle: {
            value: TEXT['registration-complete'].subtitle,
            style: {
                ...sharedStyles.subtitle,
            },
        },
        button: {
            left: {
                componentName: 'null',
                type: '',
                value: <></>,
            },
            right: {
                ...sharedStylesDesktop.button.right,
                value: TEXT['registration-complete'].button,
                componentName: 'button',
                type: 'inspireBlue',
                validated: true,
            },
        },
        logo: {
            img: logo,
            style: {
                height: 60,
                paddingLeft: 0,
                width: '100%',
            },
        },
        container: {
            style: {
                ...sharedStyles.container.style,

                marginLeft: `calc(50% - ${Sizes.CONTAINER_MARGIN_LEFT_DESKTOP}px)`,
                width: 500,
            },
        },
        subcontainer: {
            ...sharedStyles.subcontainer,
        },
        localFooter: {
            value: <></>,
            componentName: 'anchor',
        },
    }

    const registrationCompleteMobile: Template = {
        disclaimer: { ...sharedStylesMobile.disclaimer },
        field: {
            ...sharedStylesMobile.field,
        },
        title: {
            value: TEXT['registration-complete'].title,
            style: {
                ...globals.typeface.headerIcon,
                ...sharedStyles.title,
            },
        },
        subtitle: {
            value: TEXT['registration-complete'].subtitle,
            style: {
                ...sharedStyles.subtitle,
            },
        },
        button: {
            left: {
                componentName: 'null',
                type: '',
                value: <></>,
            },
            right: {
                ...sharedStylesMobile.button.right,
                value: 'Next',
                componentName: 'button',
                type: 'inspireBlue',
                validated: true,
            },
        },
        logo: {
            img: logo,
            style: {
                height: 60,
                paddingLeft: 0,
                width: '100%',
            },
        },
        container: {
            style: {
                ...sharedStylesMobile.container,
                ...sharedStyles.container.style,
            },
        },
        subcontainer: {
            ...sharedStyles.subcontainer,
        },
        localFooter: {
            value: <></>,
            componentName: 'anchor',
        },
    }
    return {
        login: {
            desktop: loginDesktop,
            mobile: loginMobile,
        },
        registration: {
            desktop: registrationDesktop,
            mobile: registrationMobile,
        },
        'forgot-password': {
            desktop: forgotPasswordDesktop,
            mobile: forgotPasswordMobile,
        },
        'change-password': {
            desktop: changePasswordDesktop,
            mobile: changePasswordMobile,
        },
        activation: {
            desktop: activationDesktop,
            mobile: activationMobile,
        },
        'registration-complete': {
            desktop: registrationCompleteDesktop,
            mobile: registrationCompleteMobile,
        },
    }
}

function registerNewUser(params: {
    firstName: string
    lastName: string
    email: string
    zipCode: string
    password: string
    onSubmission?: (request: RegistrationRequest) => void
}) {
    const firstNameValid: boolean =
        params.firstName.length >= CharacterLimits.FIRST_NAME_MIN &&
        params.firstName.length <= CharacterLimits.FIRST_NAME_MAX

    const lastNameValid: boolean =
        params.firstName.length >= CharacterLimits.LAST_NAME_MIN &&
        params.firstName.length <= CharacterLimits.LAST_NAME_MAX

    const loginEmailValid: boolean =
        params.email.length >= CharacterLimits.EMAIL_MIN &&
        params.email.length <= CharacterLimits.EMAIL_MAX

    const zipCodeValid: boolean =
        params.zipCode.length === CharacterLimits.ZIPCODE_MIN

    const passwordValid: boolean =
        CharacterLimits.PASSWORD_MIN < params.password.length &&
        params.password.length < CharacterLimits.PASSWORD_MAX

    const valid: boolean =
        firstNameValid &&
        lastNameValid &&
        loginEmailValid &&
        zipCodeValid &&
        passwordValid

    params.onSubmission({
        login: params.email,
        email: params.email,
        firstName: params.firstName,
        lastName: params.lastName,
        zipCode: params.zipCode,
        password: params.password,
    })
}

const TYPES: any = getTemplates()

interface LoginRegistrationProps extends RouteComponentProps {
    plan?: Plans
    type: LoginRegister
    onSubmission?: (value: any) => void // required to dynamic approach
    params?: any
}

class LoginRegistration extends React.Component<LoginRegistrationProps> {
    state = {
        plan: this.props.plan ? this.props.plan : Plans.BASIC,
        view: isMobile ? Views.MOBILE : Views.DESKTOP,
        email: '',
        password: '',
        passwordVerify: '',
        errorState: null,
        store: null,
        loginStatus: 'UNBEGUN',
        zipCode: '',
        firstName: '',
        lastName: '',
        validated: false,
    }

    request(type: LoginRegister) {
        if (type === 'login') return this.signIn()
        if (type === 'forgot-password') return this.forgotPassword()
        if (type === 'change-password') return this.changePassword()
        if (type === 'registration') return this.registration()
        if (type === 'activation') return this.activation()
        if (type === 'registration-complete') return this.registrationComplete()
    }

    signIn = () => {
        signIn(this.state.email, this.state.password)
    }

    forgotPassword = () => {
        forgotPassword(this.state.email)
    }

    changePassword = () => {
        const url: string = window.location.href
        const index: number = url.indexOf('change-password/')
        const validiated: boolean = this.changePasswordValidation()

        if (!validiated) return

        const key: string = url
            .substring(index + 'change-password/'.length, url.length)
            .trim()
        changePassword(key, this.state.password)
    }

    changePasswordValidation = () => {
        const url: string = window.location.href
        const index: number = url.indexOf('change-password/')

        if (index === -1) return false

        const password1: string = this.state.password
        const password2: string = this.state.passwordVerify

        if (password1.length < CharacterLimits.PASSWORD_MIN) {
            return false
        }

        if (password2.length < CharacterLimits.PASSWORD_MIN) {
            return false
        }
        if (password2 !== password1) {
            return false
        }

        return true
    }

    activation = () => {}

    registrationComplete = () => {
        this.props.history.push('/login')
    }

    registration = (reg?: RegistrationRequest) => {
        return registerNewUser({
            firstName: this.state.firstName,
            lastName: this.state.lastName,
            email: this.state.email,
            zipCode: this.state.zipCode,
            password: this.state.password,
            onSubmission: (request: RegistrationRequest) => {
                if (this.props.onSubmission) this.props.onSubmission(request)
            },
        })
    }

    componentDidMount() {
        window.scrollTo(0, 0)
        store.subscribe(() => {
            this.setState({
                loginStatus: store.getState().authStatus,
            })
        })
        const type: string = this.props.type
        const view: string = Views[this.state.view].toLowerCase()
        if (TYPES[type][view].button.right.validated === false)
            this.setState({
                validated: false,
            })
    }

    validate = (stateindex: number) => {
        const validated: boolean =
            stateindex === CheckboxState.CHECKED ? true : false
        this.setState({
            validated: validated,
        })
    }

    onChangeFormatToInteger = (e: any, key: string) => {
        this.setState({
            [key]: globals.util.parseIntegers(
                e.target.value,
                CharacterLimits.ZIPCODE_MAX
            ),
        })
    }

    onChange = (e: any, key: string) => {
        this.setState({
            [key]: e.target.value,
        })
    }

    renderError(error: number, toggle?: boolean) {
        toggle = toggle === undefined ? true : toggle
        let value: string = ''
        value = 'Your password must be 8 characters long'
        if (toggle)
            return (
                <div
                    style={{
                        marginTop: 20,
                        width: 464,
                        height: 30,
                        marginLeft: 25,
                        borderRadius: 4,
                        backgroundColor: DEBUG ? 'red' : 'transparent',
                        border: '1px solid black',
                    }}
                >
                    <div
                        style={{
                            width: 8,
                            height: '100%',
                            borderRadius: '4px 0 0 4px',
                            display: 'inline-block',
                            backgroundColor: DEBUG ? 'blue' : 'transparent',
                        }}
                    ></div>
                    <div
                        style={{
                            display: 'inline-block',
                            width: 30,
                            height: '100%',
                            backgroundColor: DEBUG ? 'orange' : 'transparent',
                        }}
                    ></div>
                    <div
                        style={{
                            verticalAlign: 'top',
                            display: 'inline-block',
                            height: '100%',
                            marginTop: 0,
                            backgroundColor: DEBUG ? 'blue' : 'transparent',
                        }}
                    >
                        <div
                            style={{
                                display: 'table-cell',
                                height: 30,
                                verticalAlign: 'middle',
                                width: 426,
                                backgroundColor: DEBUG
                                    ? 'purple'
                                    : 'transparent',
                            }}
                        >
                            {value}
                        </div>
                    </div>
                </div>
            )
        return <></>
    }

    renderDynamicComponent(data: DynamicComponent) {
        const h: number = 58.7
        const type: string = this.props.type

        if (data.componentName === 'null') return <></>

        if (data.componentName === 'checkbox')
            return (
                <div
                    style={{
                        ...globals.typeface.disclaimer,
                        marginTop: 20,
                        marginLeft: 25,
                        width: 450,
                        height: h,
                        paddingTop: 10,
                        paddingBottom: 10,
                        paddingLeft: 10,
                        backgroundColor: '#e8f1f7',
                        borderRadius: 5,
                        display: 'inline-block',
                    }}
                >
                    <div
                        style={{
                            verticalAlign: 'top',
                            display: 'inline-block',
                            width: 20,
                            height: h,
                            backgroundColor: DEBUG ? 'red' : 'transparent',
                        }}
                    >
                        <div
                            style={{
                                display: 'table-cell',
                                verticalAlign: 'top',
                                paddingTop: 10,
                                width: 'auto',
                                height: h,
                                backgroundColor: DEBUG ? 'lime' : 'transparent',
                            }}
                        >
                            <CheckboxCustom
                                setToStateIndex={this.validate}
                                isChecked={this.state.validated}
                            />
                        </div>
                    </div>
                    <div
                        style={{
                            paddingTop: 0,
                            marginTop: 0,
                            verticalAlign: 'top',
                            display: 'inline-block',
                            width: `calc(100% - ${26}px)`,
                            height: h,
                            backgroundColor: DEBUG ? 'blue' : 'transparent',
                        }}
                    >
                        <div
                            style={{
                                display: 'table-cell',
                                width: '100%',
                                height: h,
                                verticalAlign: 'middle',
                                paddingRight: 5,
                                paddingLeft: 5,
                            }}
                        >
                            {data.value}
                        </div>
                    </div>
                </div>
            )

        if (data.componentName === 'anchor' && type !== 'login')
            return (
                <div
                    style={{
                        width: 308,
                        marginLeft: 25,
                        paddingLeft: 20,
                        height: 58.7,
                        marginTop: 20,
                        verticalAlign: 'top',
                        backgroundColor: DEBUG ? 'red' : 'transparent',
                        display: 'inline-block',
                    }}
                >
                    <div
                        style={{
                            display: 'table-cell',
                            verticalAlign: 'middle',
                            height: 58.7,
                            width: '100%',
                        }}
                    >
                        {data.value}
                    </div>
                </div>
            )

        if (data.componentName === 'button') {
            const buttonType: string = 'inspireBlue'

            return (
                <>
                    <Button
                        type={buttonType as ButtonType}
                        style={{
                            width: data.width ? data.width : 208,
                            marginLeft: isMobile ? 0 : 25,
                            marginTop: 20,
                            height: 58.7,
                        }}
                        onClick={() => {
                            this.setState({
                                loginStatus: 'WAITING',
                            })
                            this.request(type as LoginRegister)
                        }}
                        disabled={
                            !this.state.validated &&
                            this.props.type === 'registration'
                        }
                    >
                        {data.value}
                    </Button>
                </>
            )
        }
    }

    renderField(params: {
        title: string
        value: string
        onChange?: (e: any) => void
        isPassword?: boolean
        width?: number
        isRendered?: boolean
    }) {
        const isRendered: boolean = params.isRendered !== false ? true : false
        const isPass: boolean = params.isPassword !== false ? true : false
        const inputStyle: Record<string, any> = {
            width: params.width ? params.width : 440,
            display: 'inline-block',
            marginLeft: 25,
            height: 50,
            border: 0,
            boxShadow: '0 2px 8px #dbdbdc',
            paddingLeft: 20,
            fontWeight: 500,
            paddingTop: 20,
            fontSize: 14,
            color: 'black',
            borderRadius: 4,
        }

        const inputHeader: Record<string, any> = {
            ...globals.typeface.spacedUppercase,
            position: 'absolute',
            marginLeft: 45,
            marginTop: 10,
            fontWeight: 700,
        }
        if (isRendered)
            return (
                <div style={{ marginTop: 20, display: 'inline-block' }}>
                    <p style={inputHeader}>{params.title}</p>

                    <input
                        style={inputStyle}
                        type={isPass ? 'password' : ''}
                        value={params.value}
                        onChange={params.onChange}
                        placeholder="&#8212;"
                    />
                </div>
            )
        return <></>
    }

    render() {
        const type: string = this.props.type
        const view: string = Views[this.state.view].toLowerCase()

        return (
            <>
                <div
                    style={{
                        paddingTop: 70,
                    }}
                >
                    <Header logo subscriberExempt />
                    <ToastContainer position="bottom-center" hideProgressBar />
                    <div style={TYPES[type][view].container.style}>
                        <div style={TYPES[type][view].subcontainer.style}>
                            <img
                                src={TYPES[type][view].logo.img}
                                style={TYPES[type][view].logo.style}
                                alt="Inspire Insight"
                            />
                        </div>
                        <p style={TYPES[type][view].title.style}>
                            {TYPES[type][view].title.value}
                        </p>
                        <p style={TYPES[type][view].subtitle.style}>
                            {TYPES[type][view].subtitle.value}
                        </p>
                        <InputField
                            title="First Name"
                            id="firstName"
                            style={TYPES[type][view].field}
                            value={this.state.firstName}
                            onChange={this.onChange}
                            width={
                                isMobile
                                    ? `calc(${Sizes.FIELD_WIDTH_MOBILE}px)`
                                    : `calc(${Sizes.FIELD_WIDTH_DESKTOP}px)`
                            }
                            renderIf={type === 'registration'}
                        />
                        <InputField
                            title="Last Name"
                            id="lastName"
                            style={TYPES[type][view].field}
                            value={this.state.lastName}
                            onChange={this.onChange}
                            width={
                                isMobile
                                    ? `calc(${Sizes.FIELD_WIDTH_MOBILE}px)`
                                    : `calc(${Sizes.FIELD_WIDTH_DESKTOP}px)`
                            }
                            renderIf={type === 'registration'}
                        />
                        <InputField
                            title="Email"
                            id="email"
                            style={TYPES[type][view].field}
                            value={this.state.email}
                            onChange={this.onChange}
                            width={
                                isMobile
                                    ? `calc(${Sizes.FIELD_WIDTH_MOBILE}px)`
                                    : `calc(${Sizes.FIELD_WIDTH_DESKTOP}px)`
                            }
                            renderIf={
                                type === 'registration' ||
                                type === 'login' ||
                                type === 'forgot-password'
                            }
                        />
                        <InputField
                            title="Zip Code"
                            id="zipCode"
                            style={TYPES[type][view].field}
                            value={this.state.zipCode}
                            onChange={this.onChangeFormatToInteger}
                            width={
                                isMobile
                                    ? `calc(${Sizes.FIELD_WIDTH_MOBILE}px)`
                                    : `calc(${Sizes.FIELD_WIDTH_DESKTOP}px)`
                            }
                            renderIf={type === 'registration'}
                        />
                        <InputField
                            title="Password"
                            id="password"
                            style={TYPES[type][view].field}
                            value={this.state.password}
                            onChange={this.onChange}
                            width={
                                isMobile
                                    ? `calc(${Sizes.FIELD_WIDTH_MOBILE}px)`
                                    : `calc(${Sizes.FIELD_WIDTH_DESKTOP}px)`
                            }
                            renderIf={
                                type === 'login' ||
                                type === 'registration' ||
                                type === 'change-password'
                            }
                            isPassword={true}
                        />
                        {this.renderError(0, false)}
                        <InputField
                            title="Password"
                            id="passwordVerify"
                            style={TYPES[type][view].field}
                            value={this.state.passwordVerify}
                            onChange={this.onChange}
                            width={
                                isMobile
                                    ? `calc(${Sizes.FIELD_WIDTH_MOBILE}px)`
                                    : `calc(${Sizes.FIELD_WIDTH_DESKTOP}px)`
                            }
                            renderIf={type === 'change-password'}
                            isPassword={true}
                        />

                        <div
                            style={{
                                ...TYPES[type][view].disclaimer,
                                height: 'auto',
                            }}
                        >
                            {type === 'registration' && (
                                <Disclaimer
                                    width={`calc(100% - 20)`}
                                    onChange={(checkboxState: number) => {
                                        this.setState({
                                            validated:
                                                checkboxState ===
                                                CheckboxState.CHECKED
                                                    ? true
                                                    : false,
                                        })
                                    }}
                                >
                                    <>
                                        I agree that my personal data will be
                                        used to support my experience throughout
                                        this website and by signing up for an
                                        Inspire Insight account means I agree to
                                        the &nbsp;
                                        <Link
                                            style={{ color: 'black' }}
                                            to="/privacy-policy"
                                        >
                                            Privacy Policy
                                        </Link>{' '}
                                        and &nbsp;
                                        <Link
                                            style={{ color: 'black' }}
                                            to="/terms-of-service"
                                        >
                                            Terms of Service
                                        </Link>
                                        .
                                    </>
                                </Disclaimer>
                            )}
                        </div>
                        {this.renderDynamicComponent(
                            TYPES[type][view].button.right
                        )}
                        <div style={{ textAlign: 'center', marginTop: 40 }}>
                            {type === 'login' && (
                                <p>
                                    <Link
                                        to={`/forgot-password`}
                                        style={{
                                            fontWeight: 'bold',
                                            color: '#adb5b8',
                                        }}
                                    >
                                        Forgot Password?
                                    </Link>
                                </p>
                            )}
                        </div>
                    </div>
                    <div
                        style={{
                            width: '100%',
                            textAlign: 'center',
                        }}
                    >
                        <div
                            style={{
                                display: 'inline-block',
                            }}
                        >
                            {this.renderDynamicComponent(
                                TYPES[type][view].localFooter
                            )}
                        </div>
                        {type === 'login' && (
                            <p style={{ fontWeight: 'bold', color: 'black' }}>
                                Need an account?{' '}
                                <Link
                                    to={`/register`}
                                    style={{
                                        color: '#2c8aae',
                                    }}
                                >
                                    Sign-up here.
                                </Link>
                            </p>
                        )}
                    </div>
                </div>
                <Footer marginTop={isMobile ? 350 : 0} />
            </>
        )
    }
}

export default withRouter(LoginRegistration)

class FooterFrame extends React.Component<{}> {
    render() {
        return (
            <div
                style={{
                    marginTop: 50,
                    backgroundColor: DEBUG
                        ? 'lime'
                        : 'hsla(225, 4.27%, 89.11%, 1)',
                    minHeight: 50,
                    height: 'auto',
                    width: '100vw',
                }}
            >
                {this.props.children}
            </div>
        )
    }
}
