import React, { ReactNode } from 'react'
import { Search } from '@styled-icons/boxicons-regular/Search'
import { Plus } from '@styled-icons/boxicons-regular/Plus'
import { SearchParams } from '../pages/AdvancedSearch'
import { TagData } from '../models/state'
import { css } from 'glamor'
import { Row, Col } from 'react-flexbox-grid'
import { fetchPlanTitle, globals } from '../utils/globals'
import { getDefaultScreeningProfiles } from '../utils/DummyData'
import { getPesudoScreeningCategories } from '../utils/DummyData'
import { isMobile, isTablet } from 'react-device-detect'

import {
    RadioParams,
    RadioProps,
    ScreeningProfileItem,
    ScreeningCategory,
} from '../models/state'
import { ICustomProfile } from '../models/api'
import { thisExpression } from '@babel/types'
import Button from './Button'
import { withRouter, RouteComponentProps, Link } from 'react-router-dom'
import { toast, ToastContainer } from 'react-toastify'
const DEBUG: boolean = false

type ComponentType = 'Default'

export enum Screens {
    BLANK = 0,
    OFF = 1,
    EXCLUDE = 3,
    INCLUDE = 2,
}

interface ScreeningProps extends RouteComponentProps {
    type?: ComponentType
    categories?: ReactNode[]
    profiles?: ReactNode[]
    style?: Record<string, any>
    currentProfile?: string
    onNewProfile?: (isNewProfile?: boolean) => void
    onEditProfile?: () => void
    onDeleteProfile?: () => void
    onSetScreen?: (category: string, value: number) => void
    getRadioState?: (key: string) => number
    setScreeningProfile?: (name: string) => void
    setScreeningCustom?: (data: TagData[]) => void
    setScreeningProfileButtonState?: (statekey: string, toggle: boolean) => void
    renderTagData?: (profileName: string, params?: any) => any
    screeningPage?: boolean
    onSave?: () => void
    disableSave?: boolean
    fetchActiveTitle?: () => any
}

class Screening extends React.Component<ScreeningProps> {
    state = {
        categories: [],
        customProfiles: [],
        customProfilesFiltered: [],
        standardProfiles: [],
        currentProfile: this.props.currentProfile
            ? this.props.currentProfile
            : String(),
        filter: String(),
        selectAll: {
            off: false,
            include: false,
            exclude: false,
        },
    }

    componentDidMount() {
        let custom: TagData[] = [
            { title: 'Custom #1', toggle: false },
            { title: 'Custom #2', toggle: false },
            { title: 'Custom #3', toggle: false },
        ]

        let standardProfiles: TagData[] = getDefaultScreeningProfiles()
        standardProfiles.forEach((item) => {
            item.toggle = false
        })

        const activeProfile: string = this.props.currentProfile
            ? this.props.currentProfile
            : '' // extend: store and get last used profile from COOKIE

        /* Saved Comment: 
            We don't want to get the profile from db. Instead, when the profile is set store it as a cookie. You can also read it from the cookie. Look into the auth model to see an example on cookies.
            Set the expiration time to zero which will remove the cookie at the end of the browser session
        */

        this.setState({
            currentProfile: activeProfile,
            customProfiles: custom,
            customProfilesFiltered: custom,
            standardProfiles: standardProfiles,
        })

        if (this.props.setScreeningProfile && this.props.currentProfile)
            this.props.setScreeningProfile(this.props.currentProfile)
    }

    handleSelectAllChange = (column: 'off' | 'include' | 'exclude') => {
        let newState = this.state

        newState = {
            ...this.state,
            currentProfile: 'Custom',
            selectAll: {
                off: false,
                include: false,
                exclude: false,
            },
        }
        newState.selectAll[column] = true
        if (
            this.props.setScreeningProfileButtonState &&
            !this.props.screeningPage
        ) {
            this.props.setScreeningProfileButtonState('Custom', true)
        }
        if (this.props.setScreeningProfile) {
            switch (column) {
                case 'off':
                    this.props.setScreeningProfile('allOff')
                    break
                case 'include':
                    this.props.setScreeningProfile('allExclude')
                    break
                case 'exclude':
                    this.props.setScreeningProfile('allInclude')
                    break
            }
        }

        if (this.props.setScreeningProfile) this.setState(newState)
    }
    handleSelectAllMouseEvent = (action, e) => {
        if (action === 'down') {
            // console.log(e.target.style)
            e.target.style.backgroundColor = 'grey'
        } else {
            e.target.style.backgroundColor = 'white'
        }
    }

    renderSearch(params?: SearchParams) {
        interface Referential {
            [name: string]: any //ReactNode | string | number | boolean
        }

        const defaultValue: string = 'Search...'
        const placeholder: any =
            params && params.placeholder ? params.placeholder : defaultValue
        const override: Record<string, any> =
            params && params.style ? params.style : {}

        const onDefault: Record<string, any> = {
            marginTop: 6,
            width: 'calc(100% - 240px)',
            height: 40,
            marginLeft: 10,
            boxShadow: 'none !important',
            outline: 'none !important',
            backgroundColor: 'white',
            border: 'none',
            verticalAlign: 'top',
            color: globals.colors.inspire.darknavy,
        }

        return (
            <div style={{ backgroundColor: 'white', width: 500, ...override }}>
                <Search
                    width={25}
                    style={{
                        verticalAlign: 'top',
                        margin: 15,
                    }}
                />
                <input
                    {...css({
                        ...onDefault,
                        ...globals.typeface.advancedSearch.placeHolder,
                    })}
                    placeholder={placeholder}
                    onChange={(e: any) => {
                        const filterValue: string = e.target.value

                        const key: string = filterValue
                            .toLowerCase()
                            .replaceAll(/ /g, '')
                        const filtered: ScreeningCategory[] = []
                        let found: boolean, title: string, toggle: boolean
                        this.state.customProfiles.forEach(
                            (item: ScreeningCategory) => {
                                title = item.title
                                    .toLowerCase()
                                    .replaceAll(/ /g, '')
                                found = title.indexOf(key) !== -1
                                if (found) filtered.push(item)
                            }
                        )
                        this.setState({
                            customProfilesFiltered: filtered,
                        })
                    }}
                ></input>
            </div>
        )
    }

    renderSearchBar(placeholderValue?: string) {
        const params: SearchParams = {
            placeholder: placeholderValue,
            style: {
                border: '1px solid ' + globals.colors.inspire.arcpath,
            },
        }
        return this.renderSearch(params)
    }

    renderSubtitleII(value: string) {
        const styles: Record<string, any> = {
            ...globals.typeface.advancedSearch.subtitleII,
        }
        return <p style={styles}>{value}</p>
    }

    renderProfileResults() {
        const profiles: TagData[] = this.state.customProfilesFiltered
        const disableProfiles: TagData[] = this.state.standardProfiles
        this.handleSetScreeningProfileWithProp(disableProfiles)

        const params: any = {
            onClick: (title?: string) => {
                if (this.props.setScreeningProfile)
                    this.props.setScreeningProfile('Custom')
            },
        }
        const params2: any = {
            path: [],
            onClick: (title?: string) => {},
        }

        if (this.props.renderTagData)
            return this.props.renderTagData('screeningCustom', params2)

        return <>no data</>
    }

    renderCustomProfiles() {
        const placeholderValue: string = 'Search profiles...'
        const params: SearchParams = {
            placeholder: placeholderValue,
            style: {
                border: '1px solid ' + globals.colors.inspire.arcpath,
            },
        }

        return (
            <div style={{ paddingTop: 22 }}>
                <h2>Your Custom Profiles</h2>
                {/* {this.renderSearch(params)} */}
                {this.renderProfileResults()}

                <Button
                    type={'tag'}
                    onClick={() => {
                        if (this.props.screeningPage) {
                            this.handleOnSaveAs(true)
                        } else {
                            this.props.history.push('/screening-profiles')
                        }
                    }}
                >
                    <Plus
                        width={20}
                        style={{
                            position: 'relative',
                            bottom: 1,
                        }}
                    />
                    Create New Profile
                </Button>
            </div>
        )
    }

    renderVerticalSpace(y?: number) {
        const style: Record<string, any> = {
            width: '100%',
            height: y ? y : 100,
            backgroundColor: 'transparent',
        }
        return <div style={style}></div>
    }

    renderCategories(categories: ScreeningCategory[]) {
        const setScreeningProfileButtonState = () => {
            if (
                this.props.setScreeningProfileButtonState &&
                !this.props.screeningPage
            ) {
                this.props.setScreeningProfileButtonState('Custom', true)
            }
        }

        return (
            <div
                style={{
                    marginBottom: 20,
                    display: 'block',
                    // marginTop: 45,
                }}
            >
                {categories.map((val: ScreeningCategory) => {
                    return (
                        <RadioRow
                            title={val.title}
                            value={val.type}
                            onSetScreen={this.props.onSetScreen}
                            getRadioState={this.props.getRadioState}
                            setScreeningProfile={this.props.setScreeningProfile}
                            setScreeningProfileButtonState={
                                setScreeningProfileButtonState
                            }
                        ></RadioRow>
                    )
                })}
            </div>
        )
    }

    renderScreeningHeader(children?: ReactNode[]) {
        return (
            <div
                style={{
                    width: '100%',
                    height: 60,
                    backgroundColor: DEBUG ? 'pink' : 'white',
                    top: 0,
                    position: '-webkit-sticky' || 'sticky',
                }}
            >
                {children}
            </div>
        )
    }

    handleSetScreeningProfileWithProp(profiles: TagData[]) {
        type Ref = {
            title: string
            toggle: boolean
        }
        let title: string, propTitle: string, toggle: boolean
        if (this.props.currentProfile)
            profiles.forEach((item: Ref) => {
                title = item.title.toLowerCase()
                toggle = item.toggle
                propTitle = this.props.currentProfile
                    ? this.props.currentProfile.toLowerCase()
                    : 'none'
                if (title === propTitle) {
                    item.toggle = true
                } else item.toggle = false
            })
    }

    renderStandardProfiles() {
        const profiles: TagData[] = this.state.standardProfiles
        const statekey: string = 'screeningProfiles'
        this.handleSetScreeningProfileWithProp(profiles)
        const params: any = {
            path: [],
            onClick: (title?: string) => {
                if (title && this.props.setScreeningProfile)
                    this.props.setScreeningProfile(title)
            },
        }
        if (this.props.renderTagData)
            return <div>{this.props.renderTagData(statekey, params)}</div>
    }

    renderScreeningProfiles() {
        return (
            <div
                id="leftside"
                style={{
                    width: isMobile ? '100%' : '30%',
                    backgroundColor: DEBUG ? 'purple' : 'transparent',
                    display: 'inline-block',
                    verticalAlign: 'top',
                    top: this.props.screeningPage ? 65 : 145,

                    position: isMobile ? 'static' : 'sticky',
                }}
            >
                {!this.props.screeningPage ? (
                    <>
                        <h2>Screening Profiles</h2>
                        {this.renderScreeningHeader([
                            this.renderSubtitleII('Standard Profiles'),
                        ])}
                        {this.renderStandardProfiles()}
                    </>
                ) : (
                    <></>
                )}
                {this.renderCustomProfiles()}
            </div>
        )
    }

    handleOnSave = () => {
        if (this.props.onSave) this.props.onSave()
    }

    handleOnSaveAs = (isNewProfile?: boolean) => {
        if (this.props.onNewProfile) this.props.onNewProfile(isNewProfile)
    }

    handleOnEdit = () => {
        if (this.props.onEditProfile) this.props.onEditProfile()
    }

    handleOnDelete = () => {
        if (this.props.onDeleteProfile) this.props.onDeleteProfile()
    }

    screeningHeaderPositioning = () => {
        if (this.props.screeningPage && isMobile && !isTablet) {
            return 130
        } else if (this.props.screeningPage) {
            return 90
        } else if (
            !this.props.screeningPage &&
            isTablet &&
            window.innerWidth > 1100
        ) {
            return 165
        } else if (!this.props.screeningPage && isTablet) {
            return 208
        } else {
            return 140
        }
    }

    renderScreeningCategories() {
        const categories = getPesudoScreeningCategories()
        return (
            <div
                id="leftside"
                style={{
                    width: isMobile ? '100%' : '70%',
                    backgroundColor: DEBUG ? 'purple' : 'transparent',
                    display: 'inline-block',
                    verticalAlign: 'top',
                    top: 0,
                    position: isMobile ? 'static' : 'sticky',
                }}
            >
                <div
                    style={{
                        backgroundColor: 'transparent',
                        verticalAlign: 'top',
                        top: this.screeningHeaderPositioning(),
                        position: 'sticky',
                        width: '100%',
                    }}
                >
                    <div
                        style={{
                            backgroundColor: DEBUG ? 'red' : 'white',
                        }}
                    >
                        <div
                            style={{
                                backgroundColor: DEBUG ? 'pink' : 'transparent',
                            }}
                        >
                            {this.props.screeningPage &&
                            this.props.fetchActiveTitle() ? (
                                <>
                                    <Row>
                                        <Col>
                                            <h3
                                                style={{
                                                    marginBottom: 0,
                                                    fontWeight: 'normal',
                                                }}
                                            >
                                                Viewing profile:{' '}
                                                <strong>
                                                    {this.props.fetchActiveTitle()}
                                                </strong>
                                            </h3>{' '}
                                        </Col>
                                    </Row>
                                    <Row
                                        style={{
                                            borderBottom: '1px solid lightgrey',
                                            paddingBottom: 10,
                                        }}
                                    >
                                        <Col style={{ paddingTop: 10 }}>
                                            <Button
                                                type="tag"
                                                style={{
                                                    backgroundColor: this.props
                                                        .disableSave
                                                        ? '#DFE8EC'
                                                        : '#2FA773',
                                                    color: this.props
                                                        .disableSave
                                                        ? 'black'
                                                        : 'white',
                                                    pointerEvents: this.props
                                                        .disableSave
                                                        ? 'none'
                                                        : 'any',
                                                    userSelect: 'none',
                                                    cursor: this.props
                                                        .disableSave
                                                        ? 'not-allowed'
                                                        : 'pointer',
                                                }}
                                                onClick={this.handleOnSave}
                                            >
                                                Save Existing Profile
                                            </Button>
                                            <Button
                                                type="tag"
                                                style={{
                                                    backgroundColor: '#2FA773',
                                                    color: 'white',
                                                }}
                                                onClick={() => {
                                                    this.handleOnEdit()
                                                }}
                                            >
                                                Rename Profile
                                            </Button>
                                            <Button
                                                type="tag"
                                                style={{
                                                    backgroundColor: '#bd425a',
                                                    color: 'white',
                                                }}
                                                onClick={() => {
                                                    this.handleOnDelete()
                                                }}
                                            >
                                                Delete Profile
                                            </Button>
                                        </Col>
                                    </Row>
                                </>
                            ) : (
                                <></>
                            )}
                            <div
                                style={{
                                    backgroundColor: 'white',
                                }}
                            >
                                <div>
                                    <div
                                        style={{
                                            ...getStyleScreenTable(),
                                            display: 'inline-block',
                                            fontWeight: 800,
                                            fontSize: 16,
                                            verticalAlign: 'top',
                                            textAlign: 'center',
                                            backgroundColor: DEBUG
                                                ? 'green'
                                                : 'transparent',
                                            width: 50,
                                        }}
                                    >
                                        <p
                                            style={{
                                                fontSize: 12,
                                                paddingTop: 7,
                                            }}
                                        >
                                            Off
                                        </p>
                                    </div>

                                    <div
                                        style={{
                                            ...getStyleScreenTable(),
                                            display: 'inline-block',
                                            fontWeight: 800,
                                            fontSize: 16,
                                            verticalAlign: 'top',
                                            textAlign: 'center',
                                            backgroundColor: DEBUG
                                                ? 'green'
                                                : 'transparent',
                                            width: 68,
                                        }}
                                    >
                                        <p
                                            style={{
                                                fontSize: 12,
                                                paddingTop: 7,
                                            }}
                                        >
                                            {this.props.screeningPage
                                                ? 'Negative'
                                                : 'Exclude'}
                                        </p>
                                    </div>

                                    <div
                                        style={{
                                            ...getStyleScreenTable(),
                                            display: 'inline-block',
                                            fontWeight: 800,
                                            fontSize: 16,
                                            verticalAlign: 'top',
                                            textAlign: 'left',
                                            backgroundColor: DEBUG
                                                ? 'green'
                                                : 'transparent',
                                            width: 50,
                                        }}
                                    >
                                        <p
                                            style={{
                                                fontSize: 12,
                                                paddingTop: 7,
                                            }}
                                        >
                                            {this.props.screeningPage
                                                ? 'Positive'
                                                : 'Include'}
                                        </p>
                                    </div>
                                    <div
                                        style={{
                                            ...getStyleScreenTable(),
                                            display: 'inline-block',
                                            fontWeight: 800,
                                            fontSize: 16,
                                            verticalAlign: 'top',
                                            textAlign: 'left',
                                            backgroundColor: DEBUG
                                                ? 'green'
                                                : 'transparent',
                                            width: 50,
                                        }}
                                    >
                                        <p
                                            style={{
                                                fontSize: 12,
                                                paddingTop: 7,
                                                marginLeft: 13,
                                            }}
                                        >
                                            Category
                                        </p>
                                    </div>
                                </div>
                            </div>

                            <div
                                style={{
                                    backgroundColor: 'white',
                                    borderBottom: '1px solid black',
                                }}
                            >
                                <div>
                                    <input
                                        style={{
                                            appearance: 'none',
                                            width: 16,
                                            height: 16,
                                            borderRadius: '100%',
                                            border: `3px solid grey`,
                                            marginTop: 5,
                                            marginLeft: 20,
                                        }}
                                        type="radio"
                                        id={'test'}
                                        name={'test'}
                                        value={'test'}
                                        checked={false}
                                        onClick={(e: any) =>
                                            this.handleSelectAllChange('off')
                                        }
                                        onMouseDown={(e: any) =>
                                            this.handleSelectAllMouseEvent(
                                                'down',
                                                e
                                            )
                                        }
                                        onMouseUp={(e: any) =>
                                            this.handleSelectAllMouseEvent(
                                                'up',
                                                e
                                            )
                                        }
                                        onMouseOut={(e: any) =>
                                            this.handleSelectAllMouseEvent(
                                                'up',
                                                e
                                            )
                                        }
                                    ></input>
                                    <input
                                        style={{
                                            appearance: 'none',
                                            width: 16,
                                            height: 16,
                                            borderRadius: '100%',
                                            border: `3px solid grey`,
                                            marginTop: 5,
                                            marginLeft: 37,
                                        }}
                                        type="radio"
                                        id={'test'}
                                        name={'test'}
                                        value={'test'}
                                        checked={false}
                                        onClick={(e: any) =>
                                            this.handleSelectAllChange(
                                                'include'
                                            )
                                        }
                                        onMouseDown={(e: any) =>
                                            this.handleSelectAllMouseEvent(
                                                'down',
                                                e
                                            )
                                        }
                                        onMouseUp={(e: any) =>
                                            this.handleSelectAllMouseEvent(
                                                'up',
                                                e
                                            )
                                        }
                                        onMouseOut={(e: any) =>
                                            this.handleSelectAllMouseEvent(
                                                'up',
                                                e
                                            )
                                        }
                                    ></input>
                                    <input
                                        style={{
                                            appearance: 'none',
                                            width: 16,
                                            height: 16,
                                            borderRadius: '100%',
                                            border: `3px solid grey`,
                                            marginTop: 5,
                                            marginLeft: 37,
                                        }}
                                        type="radio"
                                        id={'test'}
                                        name={'test'}
                                        value={'test'}
                                        checked={false}
                                        onClick={(e: any) =>
                                            this.handleSelectAllChange(
                                                'exclude'
                                            )
                                        }
                                        onMouseDown={(e: any) =>
                                            this.handleSelectAllMouseEvent(
                                                'down',
                                                e
                                            )
                                        }
                                        onMouseUp={(e: any) =>
                                            this.handleSelectAllMouseEvent(
                                                'up',
                                                e
                                            )
                                        }
                                        onMouseOut={(e: any) =>
                                            this.handleSelectAllMouseEvent(
                                                'up',
                                                e
                                            )
                                        }
                                    ></input>
                                    <p
                                        style={{
                                            ...globals.typeface.advancedSearch
                                                .subtitle,
                                            color: globals.colors.inspire
                                                .darknavy,
                                            display: 'inline-block',
                                            fontFamily:
                                                "'URW Geometric Cond', sans-serif",
                                            fontWeight: 300,
                                            textDecoration: 'none',
                                            fontSize: 16,
                                            marginLeft: 31,
                                        }}
                                    >
                                        Select All
                                    </p>
                                </div>
                            </div>
                        </div>
                        {/* {renderHeader(
                            this.state,
                            this.handleSelectAllChange,
                            this.handleOnSave,
                            this.handleOnSaveAs,
                            this.props.screeningPage,
                            this.props.disableSave
                        )} */}
                    </div>
                </div>
                {this.renderCategories(categories)}
            </div>
        )
    }

    render() {
        return (
            <div>
                {
                    <div
                        style={{
                            paddingBottom:
                                globals.sizes.component.verticalSpaceNoPadding,
                            backgroundColor: DEBUG ? 'lime' : 'white',
                            height: 'auto   ',
                            verticalAlign: 'top',
                            padding: 0,
                        }}
                        id="container"
                    >
                        <div style={{ margin: 10 }}>
                            <div
                                style={{
                                    backgroundColor: DEBUG ? 'blue' : 'white',
                                    width: '100%',
                                    top: 0,
                                    position: 'sticky',
                                }}
                            >
                                {this.renderScreeningProfiles()}
                                {this.renderScreeningCategories()}
                            </div>
                        </div>
                    </div>
                }
            </div>
        )
    }
}

export class RadioRow extends React.Component<RadioProps> {
    state = {
        id: '',
        title: '',
    }

    componentDidMount() {
        const id: string = this.props.id
            ? this.props.id
            : globals.util.getRandomString()
        const title: string = this.props.title ? this.props.title : 'untitled'
        this.setState({
            id: id,
            title: title,
        })
    }

    renderRadio(params: RadioParams, override?: Record<string, any>) {
        const idNum: number = params.id
        const idGrp: string = params.groupId
        const isChecked: boolean = params.isChecked
        const id: string = Screens[idNum].toLowerCase()

        const style: Record<string, any> = this.getRadioStyle()
        const color: string = this.getRadioColor(idNum)
        const checked: Record<string, any> = {
            ':checked': {
                color: 'silver',
                border: '3px solid ' + color,
                backgroundColor: color,
            },
        }

        const styles: Record<string, any> = {
            ...style,
            ...checked,
            ...override,
        }

        const passedFunction: any = params.functionality
            ? params.functionality
            : (e: any) => {}

        return (
            <input
                {...css({
                    ...styles,
                })}
                type="radio"
                id={id}
                name={idGrp}
                value={id.toUpperCase()}
                checked={isChecked}
                onClick={(e: any) => {
                    passedFunction(e)
                    if (this.props.setScreeningProfileButtonState)
                        this.props.setScreeningProfileButtonState()
                }}
            ></input>
        )
    }

    getRadioColor(position: number) {
        const colors: string[] = [
            'white',
            globals.colors.inspire.arcpath,
            globals.colors.positive.blue,
            globals.colors.negative.red,
        ]
        const index: number = position < colors.length ? position : 0
        const color: string = index >= 0 ? colors[index] : colors[0]
        return color
    }

    getRadioStyle() {
        const borderSize: number = 3
        const color: string = globals.colors.inspire.arcpath
        const styleRadio: Record<string, any> = {
            appearance: 'none',
            width: 16,
            height: 16,
            borderRadius: '100%',
            border: `${borderSize}px solid ${color}`,
            marginTop: 0,
            verticalAlign: 'top',
        }
        return styleRadio
    }

    render() {
        const groupId: string = this.state.id
        const type: number = this.props.value as number
        const overrides: Record<string, any> = {
            marginRight: 20,
            marginLeft: 20,
        }
        const displayTitle: string = this.state.title
        const title: string = toConstantForm(displayTitle)
        const logString: string = `${this.state.title} : ${Screens[type]}: (${type})`
        let checkedIndex: number = this.props.getRadioState
            ? this.props.getRadioState(title)
            : 1

        const renderRadioComponent = (screenEnumId: number) => {
            const statekey: string = 'screens'
            return this.renderRadio(
                {
                    id: screenEnumId,
                    groupId: groupId,
                    functionality: () => {
                        if (this.props.onSetScreen) {
                            this.props.onSetScreen(title, screenEnumId)
                        }
                        if (this.props.getRadioState)
                            this.props.getRadioState(title)

                        if (this.props.setScreeningProfileButtonState)
                            this.props.setScreeningProfileButtonState()
                    },
                    isChecked: checkedIndex === screenEnumId,
                },
                overrides
            )
        }

        return (
            <div
                style={{
                    borderBottom: '1px solid ' + globals.colors.inspire.arcpath,
                }}
            >
                <div
                    style={{
                        paddingTop: 10,
                    }}
                >
                    {renderRadioComponent(Screens.OFF)}
                    {renderRadioComponent(Screens.EXCLUDE)}
                    {renderRadioComponent(Screens.INCLUDE)}
                    <p
                        style={{
                            ...getStyleScreenTable(),
                            marginLeft: 13,
                            marginTop: 0,
                            marginBottom: 7,
                            width: isMobile && !isTablet ? 205 : 'auto',
                        }}
                    >
                        {displayTitle}
                    </p>
                </div>
            </div>
        )
    }
}

function getStyleScreenTable() {
    return {
        ...globals.typeface.advancedSearch.subtitle,
        color: globals.colors.inspire.darknavy,
        display: 'inline-block',
        fontFamily: "'URW Geometric Cond', sans-serif",
        fontWeight: 300,
        textDecoration: 'none',
        fontSize: 16,
    }
}

export function disableAllProfiles(
    This: any,
    data: TagData[],
    except?: string
) {
    let customUpdated: TagData[] = This.state.customProfiles
    customUpdated.forEach((item) => {
        item.toggle = false
        if (except && item.title === except) item.toggle = true
    })

    let standardUpdated: TagData[] = This.state.standardProfiles
    standardUpdated.forEach((item) => {
        item.toggle = false
        if (except && item.title === except) item.toggle = true
    })

    This.setState({
        customProfiles: customUpdated,
        standardProfiles: standardUpdated,
    })

    return data
}

function toConstantForm(category: string) {
    interface Referential {
        [name: string]: string
    }
    const categories: Referential = {
        Abortifacients: 'ABORTIFACIENTS',
        'Abortion Legislation': 'ABORTION_LEGISLATION',
        'Abortion Philanthropy': 'ABORTION_PHILANTHROPY',
        'Abortion Promotion': 'ABORTION_PROMOTION',
        'Access and Affordability (Best In Class)':
            'ACCESS_AND_AFFORDABILITY_BIC',
        'Air Quality (Best In Class)': 'AIR_QUALITY_BIC',
        Alcohol: 'ALCOHOL',
        'Animal Testing': 'ANIMAL_TESTING',
        'Business Ethics (Best In Class)': 'BUSINESS_ETHICS_BIC',
        'Business Model Resilience': 'BUSINESS_MODEL_RESILIENCE_BIC',
        'Cannabis: CBD Only': 'CANNABIS_CBD_ONLY',
        'Cannabis: Cultivation/Processing': 'CANNABIS_CULTIVATION_PROCESSING',
        'Cannabis: Pharmaceutical': 'CANNABIS_PHARMACEUTICAL',
        'Cannabis: Retail THC': 'CANNABIS_RETAIL_THC',
        'Contraceptives: Barrier': 'CONTRACEPTIVES_BARRIER',
        //contraceptives (horminal) on Andrea's version but not found in backend
        'Critical Incident Risk Management':
            'CRITICAL_INCIDENT_RISK_MANAGEMENT_BIC',
        'Customer Privacy': 'CUSTOMER_PRIVACY_BIC',
        'Customer Welfare': 'CUSTOMER_WELFARE_BIC',
        //COMPETITIVE_BEHAVIOR_BIC - missing or unnecessary ?
        'Data Security': 'DATA_SECURITY_BIC',
        'Ecological Impacts': 'ECOLOGICAL_IMPACTS_BIC',
        'Embryonic Stem Cell Distribution': 'EMBRYONIC_STEM_CELL',
        'Embryonic Stem Cell Production': 'EMBRYONIC_STEM_PRODUCTION',
        'Embryonic Stem Cell Research': 'EMBRYONIC_STEM_RESEARCH',
        'Employee Engagement Diversity and Inclusion':
            'EMPLOYEE_ENGAGEMENT_DIVERSITY_AND_INCLUSION_BIC',
        'Employee Health and Safety': 'EMPLOYEE_HEALTH_AND_SAFETY_BIC',
        'Energy Management': 'ENERGY_MANAGEMENT_BIC',
        Gambling: 'GAMBLING',
        //GAMBLING_5PERCENT_REVENUE - missing or unnecessary ?
        'GHG Emissions': 'GHG_EMISSIONS_BIC',
        'GMO Products': 'GMO_PRODUCTS',
        'Human Rights and Community Relations':
            'HUMAN_RIGHTS_AND_COMMUNITY_RELATIONS_BIC',
        'Labor Practices': 'LABOR_PRACTICES_BIC',
        'LGBT Legislation': 'LGBTA_LEGISLATION',
        'LGBT Philanthropy': 'LGBTA_PHILANTHROPY',
        'LGBT Promotion': 'LGBTA_PROMOTION',
        'Management of the Legal and Regulatory Environment':
            'MANAGEMENT_OF_THE_LEGAL_AND_REGULATORY_ENVIRONMENT_BIC',
        'Materials Sourcing and Efficiency':
            'MATERIALS_SOURCING_AND_EFFICIENCY_BIC',
        Nuclear: 'NUCLEAR',
        //NUCLEAR_5PERCENT_REVENUE
        'Physical Impacts of Climate Change':
            'PHYSICAL_IMPACTS_OF_CLIMATE_CHANGE_BIC',
        Pornography: 'PORNOGRAPHY',
        'Product Design and Lifecycle Management':
            'PRODUCT_DESIGN_AND_LIFECYCLE_MANAGEMENT_BIC',
        'Product Quality and Safety': 'PRODUCT_QUALITY_AND_SAFETY_BIC',
        'Selling Practices and Product Labeling':
            'SELLING_PRACTICES_AND_PRODUCT_LABELING_BIC',
        'State Owned Enterprise (SOE)': 'STATE_OWNED_ENTERPRISE',
        Exploitation: 'EXPLOITATION',
        'In-Vitro Fertilization': 'INVITRO_FERTILIZATION',
        'Supply Chain Management': 'SUPPLY_CHAIN_MANAGEMENT_BIC',
        'Systemic Risk Management': 'SYSTEMIC_RISK_MANAGEMENT_BIC',
        Tobacco: 'TOBACCO',
        //TOBACCO_5PERCENT_REVENUE
        'Waste and Hazardous Materials Management':
            'WASTE_AND_HAZARDOUS_MATERIALS_MANAGEMENT_BIC',
        'Water and Wastewater Management':
            'WATER_AND_WASTEWATER_MANAGEMENT_BIC',
        'Weapons: Weapons Components': 'WEAPONS_COMPONENTS',
        //WEAPONS_COMPONENTS_5PERCENT_REVENUE
        'Weapons: Military Weapons': 'WEAPONS_MILITARY',
    }

    if (categories[category]) return categories[category]
    return 'N/A'
}

export function getScreeningProfileByAllSelected(
    column: 'off' | 'include' | 'exclude'
) {
    const items = [
        'ABORTIFACIENTS',
        'ABORTION_LEGISLATION',
        'ABORTION_PHILANTHROPY',
        'ABORTION_PROMOTION',
        'ACCESS_AND_AFFORDABILITY_BIC',
        'AIR_QUALITY_BIC',
        'ALCOHOL',
        'ALCOHOL_5PERCENT_REVENUE',
        'ANIMAL_TESTING',
        'BUSINESS_ETHICS_BIC',
        'BUSINESS_MODEL_RESILIENCE_BIC',
        'CANNABIS_CBD_ONLY',
        'CANNABIS_CULTIVATION_PROCESSING',
        'CANNABIS_PHARMACEUTICAL',
        'CANNABIS_RETAIL_THC',
        'COMPETITIVE_BEHAVIOR_BIC',
        'CONTRACEPTIVES_BARRIER',
        'CRITICAL_INCIDENT_RISK_MANAGEMENT_BIC',
        'CUSTOMER_PRIVACY_BIC',
        'CUSTOMER_WELFARE_BIC',
        'COMPETITIVE_BEHAVIOR_BIC',
        'DATA_SECURITY_BIC',
        'ECOLOGICAL_IMPACTS_BIC',
        'EMBRYONIC_STEM_CELL',
        'EMBRYONIC_STEM_PRODUCTION',
        'EMBRYONIC_STEM_RESEARCH',
        'EMPLOYEE_ENGAGEMENT_DIVERSITY_AND_INCLUSION_BIC',
        'EMPLOYEE_HEALTH_AND_SAFETY_BIC',
        'ENERGY_MANAGEMENT_BIC',
        'GAMBLING',
        'GAMBLING_5PERCENT_REVENUE',
        'GHG_EMISSIONS_BIC',
        'GMO_PRODUCTS',
        'HUMAN_RIGHTS_AND_COMMUNITY_RELATIONS_BIC',
        'LABOR_PRACTICES_BIC',
        'LGBTA_LEGISLATION',
        'LGBTA_PHILANTHROPY',
        'LGBTA_PROMOTION',
        'EXPLOITATION',
        'INVITRO_FERTILIZATION',
        'MANAGEMENT_OF_THE_LEGAL_AND_REGULATORY_ENVIRONMENT_BIC',
        'MATERIALS_SOURCING_AND_EFFICIENCY_BIC',
        'NUCLEAR',
        'NUCLEAR_5PERCENT_REVENUE',
        'PHYSICAL_IMPACTS_OF_CLIMATE_CHANGE_BIC',
        'PORNOGRAPHY',
        'PRODUCT_DESIGN_AND_LIFECYCLE_MANAGEMENT_BIC',
        'PRODUCT_QUALITY_AND_SAFETY_BIC',
        'SELLING_PRACTICES_AND_PRODUCT_LABELING_BIC',
        'STATE_OWNED_ENTERPRISE',
        'SUPPLY_CHAIN_MANAGEMENT_BIC',
        'SYSTEMIC_RISK_MANAGEMENT_BIC',
        'TOBACCO',
        'TOBACCO_5PERCENT_REVENUE',
        'WASTE_AND_HAZARDOUS_MATERIALS_MANAGEMENT_BIC',
        'WATER_AND_WASTEWATER_MANAGEMENT_BIC',
        'WEAPONS_COMPONENTS',
        'WEAPONS_COMPONENTS_5PERCENT_REVENUE',
        'WEAPONS_MILITARY',
    ]

    let screen: number
    switch (column) {
        case 'exclude':
            screen = Screens.EXCLUDE
            break
        case 'include':
            screen = Screens.INCLUDE
            break
        case 'off':
            screen = Screens.OFF
            break
    }

    const profile: ScreeningProfileItem[] = []

    items.forEach((item) => {
        profile.push({ title: item, value: screen })
    })

    return profile
}

export function getScreeningProfileByName(name: string) {
    const evangelical: ScreeningProfileItem[] = [
        get('ABORTIFACIENTS', Screens.EXCLUDE),
        get('ABORTION_LEGISLATION', Screens.EXCLUDE),
        get('ABORTION_PHILANTHROPY', Screens.EXCLUDE),
        get('ABORTION_PROMOTION', Screens.EXCLUDE),
        get('ACCESS_AND_AFFORDABILITY_BIC', Screens.INCLUDE),
        get('AIR_QUALITY_BIC', Screens.INCLUDE),
        get('ALCOHOL', Screens.EXCLUDE),
        get('ALCOHOL_5PERCENT_REVENUE', Screens.EXCLUDE),
        get('ANIMAL_TESTING', Screens.OFF),
        get('BUSINESS_ETHICS_BIC', Screens.INCLUDE),
        get('BUSINESS_MODEL_RESILIENCE_BIC', Screens.INCLUDE),
        get('CANNABIS_CBD_ONLY', Screens.OFF),
        get('CANNABIS_CULTIVATION_PROCESSING', Screens.EXCLUDE),
        get('CANNABIS_PHARMACEUTICAL', Screens.OFF),
        get('CANNABIS_RETAIL_THC', Screens.EXCLUDE),
        get('COMPETITIVE_BEHAVIOR_BIC', Screens.INCLUDE),
        get('CONTRACEPTIVES_BARRIER', Screens.OFF),
        get('CRITICAL_INCIDENT_RISK_MANAGEMENT_BIC', Screens.INCLUDE),
        get('CUSTOMER_PRIVACY_BIC', Screens.INCLUDE),
        get('CUSTOMER_WELFARE_BIC', Screens.INCLUDE),
        get('COMPETITIVE_BEHAVIOR_BIC', Screens.INCLUDE),
        get('DATA_SECURITY_BIC', Screens.INCLUDE),
        get('ECOLOGICAL_IMPACTS_BIC', Screens.INCLUDE),
        get('EMBRYONIC_STEM_CELL', Screens.EXCLUDE),
        get('EMBRYONIC_STEM_PRODUCTION', Screens.EXCLUDE),
        get('EMBRYONIC_STEM_RESEARCH', Screens.EXCLUDE),
        get('EMPLOYEE_ENGAGEMENT_DIVERSITY_AND_INCLUSION_BIC', Screens.INCLUDE),
        get('EMPLOYEE_HEALTH_AND_SAFETY_BIC', Screens.INCLUDE),
        get('ENERGY_MANAGEMENT_BIC', Screens.INCLUDE),
        get('EXPLOITATION', Screens.EXCLUDE),
        get('GAMBLING', Screens.EXCLUDE),
        get('GAMBLING_5PERCENT_REVENUE', Screens.EXCLUDE),
        get('GHG_EMISSIONS_BIC', Screens.INCLUDE),
        get('GMO_PRODUCTS', Screens.OFF),
        get('HUMAN_RIGHTS_AND_COMMUNITY_RELATIONS_BIC', Screens.INCLUDE),
        get('INVITRO_FERTILIZATION', Screens.EXCLUDE),
        get('LABOR_PRACTICES_BIC', Screens.INCLUDE),
        get('LGBTA_LEGISLATION', Screens.EXCLUDE),
        get('LGBTA_PHILANTHROPY', Screens.EXCLUDE),
        get('LGBTA_PROMOTION', Screens.EXCLUDE),
        get(
            'MANAGEMENT_OF_THE_LEGAL_AND_REGULATORY_ENVIRONMENT_BIC',
            Screens.INCLUDE
        ),
        get('MATERIALS_SOURCING_AND_EFFICIENCY_BIC', Screens.INCLUDE),
        get('NUCLEAR', Screens.OFF),
        get('NUCLEAR_5PERCENT_REVENUE', Screens.OFF),
        get('PHYSICAL_IMPACTS_OF_CLIMATE_CHANGE_BIC', Screens.INCLUDE),
        get('PORNOGRAPHY', Screens.EXCLUDE),
        get('PRODUCT_DESIGN_AND_LIFECYCLE_MANAGEMENT_BIC', Screens.INCLUDE),
        get('PRODUCT_QUALITY_AND_SAFETY_BIC', Screens.INCLUDE),
        get('SELLING_PRACTICES_AND_PRODUCT_LABELING_BIC', Screens.INCLUDE),
        get('STATE_OWNED_ENTERPRISE', Screens.EXCLUDE),
        get('SUPPLY_CHAIN_MANAGEMENT_BIC', Screens.INCLUDE),
        get('SYSTEMIC_RISK_MANAGEMENT_BIC', Screens.INCLUDE),
        get('TOBACCO', Screens.EXCLUDE),
        get('TOBACCO_5PERCENT_REVENUE', Screens.EXCLUDE),
        get('WASTE_AND_HAZARDOUS_MATERIALS_MANAGEMENT_BIC', Screens.INCLUDE),
        get('WATER_AND_WASTEWATER_MANAGEMENT_BIC', Screens.INCLUDE),
        get('WEAPONS_COMPONENTS', Screens.OFF),
        get('WEAPONS_COMPONENTS_5PERCENT_REVENUE', Screens.OFF),
        get('WEAPONS_MILITARY', Screens.OFF),
    ]

    const catholic: ScreeningProfileItem[] = [
        get('ABORTIFACIENTS', Screens.EXCLUDE),
        get('ABORTION_LEGISLATION', Screens.EXCLUDE),
        get('ABORTION_PHILANTHROPY', Screens.EXCLUDE),
        get('ABORTION_PROMOTION', Screens.EXCLUDE),
        get('ACCESS_AND_AFFORDABILITY_BIC', Screens.INCLUDE),
        get('AIR_QUALITY_BIC', Screens.INCLUDE),
        get('ALCOHOL', Screens.EXCLUDE),
        get('ALCOHOL_5PERCENT_REVENUE', Screens.EXCLUDE),
        get('ANIMAL_TESTING', Screens.OFF),
        get('BUSINESS_ETHICS_BIC', Screens.INCLUDE),
        get('BUSINESS_MODEL_RESILIENCE_BIC', Screens.INCLUDE),
        get('INVITRO_FERTILIZATION', Screens.EXCLUDE),
        get('EXPLOITATION', Screens.EXCLUDE),
        get('CANNABIS_CBD_ONLY', Screens.OFF),
        get('CANNABIS_CULTIVATION_PROCESSING', Screens.EXCLUDE),
        get('CANNABIS_PHARMACEUTICAL', Screens.OFF),
        get('CANNABIS_RETAIL_THC', Screens.EXCLUDE),
        get('COMPETITIVE_BEHAVIOR_BIC', Screens.EXCLUDE),
        get('CONTRACEPTIVES_BARRIER', Screens.EXCLUDE),
        get('CRITICAL_INCIDENT_RISK_MANAGEMENT_BIC', Screens.INCLUDE),
        get('CUSTOMER_PRIVACY_BIC', Screens.INCLUDE),
        get('CUSTOMER_WELFARE_BIC', Screens.INCLUDE),
        get('COMPETITIVE_BEHAVIOR_BIC', Screens.INCLUDE),
        get('DATA_SECURITY_BIC', Screens.INCLUDE),
        get('ECOLOGICAL_IMPACTS_BIC', Screens.INCLUDE),
        get('EMBRYONIC_STEM_CELL', Screens.EXCLUDE),
        get('EMBRYONIC_STEM_PRODUCTION', Screens.EXCLUDE),
        get('EMBRYONIC_STEM_RESEARCH', Screens.EXCLUDE),
        get('EMPLOYEE_ENGAGEMENT_DIVERSITY_AND_INCLUSION_BIC', Screens.INCLUDE),
        get('EMPLOYEE_HEALTH_AND_SAFETY_BIC', Screens.INCLUDE),
        get('ENERGY_MANAGEMENT_BIC', Screens.INCLUDE),
        get('GAMBLING', Screens.EXCLUDE),
        get('GAMBLING_5PERCENT_REVENUE', Screens.EXCLUDE),
        get('GHG_EMISSIONS_BIC', Screens.INCLUDE),
        get('GMO_PRODUCTS', Screens.OFF),
        get('HUMAN_RIGHTS_AND_COMMUNITY_RELATIONS_BIC', Screens.INCLUDE),
        get('LABOR_PRACTICES_BIC', Screens.INCLUDE),
        get('LGBTA_LEGISLATION', Screens.OFF),
        get('LGBTA_PHILANTHROPY', Screens.OFF),
        get('LGBTA_PROMOTION', Screens.EXCLUDE),
        get(
            'MANAGEMENT_OF_THE_LEGAL_AND_REGULATORY_ENVIRONMENT_BIC',
            Screens.INCLUDE
        ),
        get('MATERIALS_SOURCING_AND_EFFICIENCY_BIC', Screens.INCLUDE),
        get('NUCLEAR', Screens.OFF),
        get('NUCLEAR_5PERCENT_REVENUE', Screens.OFF),
        get('PHYSICAL_IMPACTS_OF_CLIMATE_CHANGE_BIC', Screens.INCLUDE),
        get('PORNOGRAPHY', Screens.EXCLUDE),
        get('PRODUCT_DESIGN_AND_LIFECYCLE_MANAGEMENT_BIC', Screens.INCLUDE),
        get('PRODUCT_QUALITY_AND_SAFETY_BIC', Screens.INCLUDE),
        get('SELLING_PRACTICES_AND_PRODUCT_LABELING_BIC', Screens.INCLUDE),
        get('STATE_OWNED_ENTERPRISE', Screens.EXCLUDE),
        get('SUPPLY_CHAIN_MANAGEMENT_BIC', Screens.INCLUDE),
        get('SYSTEMIC_RISK_MANAGEMENT_BIC', Screens.INCLUDE),
        get('TOBACCO', Screens.EXCLUDE),
        get('TOBACCO_5PERCENT_REVENUE', Screens.EXCLUDE),
        get('WASTE_AND_HAZARDOUS_MATERIALS_MANAGEMENT_BIC', Screens.INCLUDE),
        get('WATER_AND_WASTEWATER_MANAGEMENT_BIC', Screens.INCLUDE),
        get('WEAPONS_COMPONENTS', Screens.OFF),
        get('WEAPONS_COMPONENTS_5PERCENT_REVENUE', Screens.OFF),
        get('WEAPONS_MILITARY', Screens.EXCLUDE),
    ]

    const none: ScreeningProfileItem[] = [
        get('ABORTIFACIENTS', Screens.OFF),
        get('ABORTION_LEGISLATION', Screens.OFF),
        get('ABORTION_PHILANTHROPY', Screens.OFF),
        get('ABORTION_PROMOTION', Screens.OFF),
        get('ACCESS_AND_AFFORDABILITY_BIC', Screens.OFF),
        get('AIR_QUALITY_BIC', Screens.OFF),
        get('ALCOHOL', Screens.OFF),
        get('ALCOHOL_5PERCENT_REVENUE', Screens.OFF),
        get('ANIMAL_TESTING', Screens.OFF),
        get('BUSINESS_ETHICS_BIC', Screens.OFF),
        get('BUSINESS_MODEL_RESILIENCE_BIC', Screens.OFF),
        get('CANNABIS_CBD_ONLY', Screens.OFF),
        get('CANNABIS_CULTIVATION_PROCESSING', Screens.OFF),
        get('CANNABIS_PHARMACEUTICAL', Screens.OFF),
        get('CANNABIS_RETAIL_THC', Screens.OFF),
        get('COMPETITIVE_BEHAVIOR_BIC', Screens.OFF),
        get('CONTRACEPTIVES_BARRIER', Screens.OFF),
        get('CRITICAL_INCIDENT_RISK_MANAGEMENT_BIC', Screens.OFF),
        get('CUSTOMER_PRIVACY_BIC', Screens.OFF),
        get('CUSTOMER_WELFARE_BIC', Screens.OFF),
        get('COMPETITIVE_BEHAVIOR_BIC', Screens.OFF),
        get('DATA_SECURITY_BIC', Screens.OFF),
        get('ECOLOGICAL_IMPACTS_BIC', Screens.OFF),
        get('EMBRYONIC_STEM_CELL', Screens.OFF),
        get('EMBRYONIC_STEM_PRODUCTION', Screens.EXCLUDE),
        get('EMBRYONIC_STEM_RESEARCH', Screens.EXCLUDE),
        get('EMPLOYEE_ENGAGEMENT_DIVERSITY_AND_INCLUSION_BIC', Screens.OFF),
        get('EMPLOYEE_HEALTH_AND_SAFETY_BIC', Screens.OFF),
        get('ENERGY_MANAGEMENT_BIC', Screens.OFF),
        get('GAMBLING', Screens.OFF),
        get('GAMBLING_5PERCENT_REVENUE', Screens.OFF),
        get('GHG_EMISSIONS_BIC', Screens.OFF),
        get('GMO_PRODUCTS', Screens.OFF),
        get('HUMAN_RIGHTS_AND_COMMUNITY_RELATIONS_BIC', Screens.OFF),
        get('LABOR_PRACTICES_BIC', Screens.OFF),
        get('LGBTA_LEGISLATION', Screens.OFF),
        get('LGBTA_PHILANTHROPY', Screens.OFF),
        get('LGBTA_PROMOTION', Screens.OFF),
        get(
            'MANAGEMENT_OF_THE_LEGAL_AND_REGULATORY_ENVIRONMENT_BIC',
            Screens.OFF
        ),
        get('MATERIALS_SOURCING_AND_EFFICIENCY_BIC', Screens.OFF),
        get('NUCLEAR', Screens.OFF),
        get('NUCLEAR_5PERCENT_REVENUE', Screens.OFF),
        get('PHYSICAL_IMPACTS_OF_CLIMATE_CHANGE_BIC', Screens.OFF),
        get('PORNOGRAPHY', Screens.OFF),
        get('PRODUCT_DESIGN_AND_LIFECYCLE_MANAGEMENT_BIC', Screens.OFF),
        get('PRODUCT_QUALITY_AND_SAFETY_BIC', Screens.OFF),
        get('SELLING_PRACTICES_AND_PRODUCT_LABELING_BIC', Screens.OFF),
        get('STATE_OWNED_ENTERPRISE', Screens.OFF),
        get('SUPPLY_CHAIN_MANAGEMENT_BIC', Screens.OFF),
        get('SYSTEMIC_RISK_MANAGEMENT_BIC', Screens.OFF),
        get('TOBACCO', Screens.OFF),
        get('TOBACCO_5PERCENT_REVENUE', Screens.OFF),
        get('WASTE_AND_HAZARDOUS_MATERIALS_MANAGEMENT_BIC', Screens.OFF),
        get('WATER_AND_WASTEWATER_MANAGEMENT_BIC', Screens.OFF),
        get('WEAPONS_COMPONENTS', Screens.OFF),
        get('WEAPONS_COMPONENTS_5PERCENT_REVENUE', Screens.OFF),
        get('WEAPONS_MILITARY', Screens.OFF),
        get('INVITRO_FERTILIZATION', Screens.OFF),
        get('EXPLOITATION', Screens.OFF),
    ]

    const environmental: ScreeningProfileItem[] = [
        get('ABORTIFACIENTS', Screens.OFF),
        get('ABORTION_LEGISLATION', Screens.OFF),
        get('ABORTION_PHILANTHROPY', Screens.OFF),
        get('ABORTION_PROMOTION', Screens.OFF),
        get('ACCESS_AND_AFFORDABILITY_BIC', Screens.INCLUDE),
        get('AIR_QUALITY_BIC', Screens.INCLUDE),
        get('ALCOHOL', Screens.OFF),
        get('ALCOHOL_5PERCENT_REVENUE', Screens.OFF),
        get('ANIMAL_TESTING', Screens.EXCLUDE),
        get('BUSINESS_ETHICS_BIC', Screens.INCLUDE),
        get('BUSINESS_MODEL_RESILIENCE_BIC', Screens.INCLUDE),
        get('CANNABIS_CBD_ONLY', Screens.OFF),
        get('CANNABIS_CULTIVATION_PROCESSING', Screens.OFF),
        get('CANNABIS_PHARMACEUTICAL', Screens.OFF),
        get('CANNABIS_RETAIL_THC', Screens.OFF),
        get('COMPETITIVE_BEHAVIOR_BIC', Screens.INCLUDE),
        get('CONTRACEPTIVES_BARRIER', Screens.OFF),
        get('CRITICAL_INCIDENT_RISK_MANAGEMENT_BIC', Screens.INCLUDE),
        get('CUSTOMER_PRIVACY_BIC', Screens.INCLUDE),
        get('CUSTOMER_WELFARE_BIC', Screens.INCLUDE),
        get('COMPETITIVE_BEHAVIOR_BIC', Screens.OFF),
        get('DATA_SECURITY_BIC', Screens.INCLUDE),
        get('ECOLOGICAL_IMPACTS_BIC', Screens.INCLUDE),
        get('EMBRYONIC_STEM_CELL', Screens.OFF),
        get('EMBRYONIC_STEM_PRODUCTION', Screens.OFF),
        get('EMBRYONIC_STEM_RESEARCH', Screens.OFF),
        get('EMPLOYEE_ENGAGEMENT_DIVERSITY_AND_INCLUSION_BIC', Screens.INCLUDE),
        get('EMPLOYEE_HEALTH_AND_SAFETY_BIC', Screens.INCLUDE),
        get('ENERGY_MANAGEMENT_BIC', Screens.INCLUDE),
        get('GAMBLING', Screens.OFF),
        get('GAMBLING_5PERCENT_REVENUE', Screens.OFF),
        get('GHG_EMISSIONS_BIC', Screens.INCLUDE),
        get('GMO_PRODUCTS', Screens.EXCLUDE),
        get('HUMAN_RIGHTS_AND_COMMUNITY_RELATIONS_BIC', Screens.INCLUDE),
        get('LABOR_PRACTICES_BIC', Screens.INCLUDE),
        get('LGBTA_LEGISLATION', Screens.OFF),
        get('LGBTA_PHILANTHROPY', Screens.OFF),
        get('LGBTA_PROMOTION', Screens.OFF),
        get(
            'MANAGEMENT_OF_THE_LEGAL_AND_REGULATORY_ENVIRONMENT_BIC',
            Screens.INCLUDE
        ),
        get('MATERIALS_SOURCING_AND_EFFICIENCY_BIC', Screens.INCLUDE),
        get('NUCLEAR', Screens.EXCLUDE),
        get('NUCLEAR_5PERCENT_REVENUE', Screens.EXCLUDE),
        get('PHYSICAL_IMPACTS_OF_CLIMATE_CHANGE_BIC', Screens.INCLUDE),
        get('PORNOGRAPHY', Screens.OFF),
        get('PRODUCT_DESIGN_AND_LIFECYCLE_MANAGEMENT_BIC', Screens.INCLUDE),
        get('PRODUCT_QUALITY_AND_SAFETY_BIC', Screens.INCLUDE),
        get('SELLING_PRACTICES_AND_PRODUCT_LABELING_BIC', Screens.INCLUDE),
        get('STATE_OWNED_ENTERPRISE', Screens.OFF),
        get('SUPPLY_CHAIN_MANAGEMENT_BIC', Screens.INCLUDE),
        get('SYSTEMIC_RISK_MANAGEMENT_BIC', Screens.INCLUDE),
        get('TOBACCO', Screens.OFF),
        get('TOBACCO_5PERCENT_REVENUE', Screens.OFF),
        get('WASTE_AND_HAZARDOUS_MATERIALS_MANAGEMENT_BIC', Screens.INCLUDE),
        get('WATER_AND_WASTEWATER_MANAGEMENT_BIC', Screens.INCLUDE),
        get('WEAPONS_COMPONENTS', Screens.OFF),
        get('WEAPONS_COMPONENTS_5PERCENT_REVENUE', Screens.OFF),
        get('WEAPONS_MILITARY', Screens.OFF),
        get('INVITRO_FERTILIZATION', Screens.OFF),
        get('EXPLOITATION', Screens.OFF),
    ]

    const socialJustice: ScreeningProfileItem[] = [
        get('ABORTIFACIENTS', Screens.OFF),
        get('ABORTION_LEGISLATION', Screens.OFF),
        get('ABORTION_PHILANTHROPY', Screens.OFF),
        get('ABORTION_PROMOTION', Screens.OFF),
        get('ACCESS_AND_AFFORDABILITY_BIC', Screens.INCLUDE),
        get('AIR_QUALITY_BIC', Screens.INCLUDE),
        get('ALCOHOL', Screens.OFF),
        get('ALCOHOL_5PERCENT_REVENUE', Screens.OFF),
        get('ANIMAL_TESTING', Screens.OFF),
        get('BUSINESS_ETHICS_BIC', Screens.INCLUDE),
        get('BUSINESS_MODEL_RESILIENCE_BIC', Screens.INCLUDE),
        get('CANNABIS_CBD_ONLY', Screens.OFF),
        get('CANNABIS_CULTIVATION_PROCESSING', Screens.OFF),
        get('CANNABIS_PHARMACEUTICAL', Screens.OFF),
        get('CANNABIS_RETAIL_THC', Screens.OFF),
        get('COMPETITIVE_BEHAVIOR_BIC', Screens.INCLUDE),
        get('CONTRACEPTIVES_BARRIER', Screens.OFF),
        get('CRITICAL_INCIDENT_RISK_MANAGEMENT_BIC', Screens.INCLUDE),
        get('CUSTOMER_PRIVACY_BIC', Screens.INCLUDE),
        get('CUSTOMER_WELFARE_BIC', Screens.INCLUDE),
        get('COMPETITIVE_BEHAVIOR_BIC', Screens.OFF),
        get('DATA_SECURITY_BIC', Screens.INCLUDE),
        get('ECOLOGICAL_IMPACTS_BIC', Screens.INCLUDE),
        get('EMBRYONIC_STEM_CELL', Screens.OFF),
        get('EMBRYONIC_STEM_PRODUCTION', Screens.OFF),
        get('EMBRYONIC_STEM_RESEARCH', Screens.OFF),
        get('EMPLOYEE_ENGAGEMENT_DIVERSITY_AND_INCLUSION_BIC', Screens.INCLUDE),
        get('EMPLOYEE_HEALTH_AND_SAFETY_BIC', Screens.INCLUDE),
        get('ENERGY_MANAGEMENT_BIC', Screens.INCLUDE),
        get('GAMBLING', Screens.OFF),
        get('GAMBLING_5PERCENT_REVENUE', Screens.OFF),
        get('GHG_EMISSIONS_BIC', Screens.INCLUDE),
        get('GMO_PRODUCTS', Screens.OFF),
        get('HUMAN_RIGHTS_AND_COMMUNITY_RELATIONS_BIC', Screens.INCLUDE),
        get('LABOR_PRACTICES_BIC', Screens.INCLUDE),
        get('LGBTA_LEGISLATION', Screens.OFF),
        get('LGBTA_PHILANTHROPY', Screens.OFF),
        get('LGBTA_PROMOTION', Screens.OFF),
        get(
            'MANAGEMENT_OF_THE_LEGAL_AND_REGULATORY_ENVIRONMENT_BIC',
            Screens.INCLUDE
        ),
        get('MATERIALS_SOURCING_AND_EFFICIENCY_BIC', Screens.INCLUDE),
        get('NUCLEAR', Screens.OFF),
        get('NUCLEAR_5PERCENT_REVENUE', Screens.OFF),
        get('PHYSICAL_IMPACTS_OF_CLIMATE_CHANGE_BIC', Screens.INCLUDE),
        get('PORNOGRAPHY', Screens.EXCLUDE),
        get('PRODUCT_DESIGN_AND_LIFECYCLE_MANAGEMENT_BIC', Screens.INCLUDE),
        get('PRODUCT_QUALITY_AND_SAFETY_BIC', Screens.INCLUDE),
        get('SELLING_PRACTICES_AND_PRODUCT_LABELING_BIC', Screens.INCLUDE),
        get('STATE_OWNED_ENTERPRISE', Screens.EXCLUDE),
        get('SUPPLY_CHAIN_MANAGEMENT_BIC', Screens.INCLUDE),
        get('SYSTEMIC_RISK_MANAGEMENT_BIC', Screens.INCLUDE),
        get('TOBACCO', Screens.INCLUDE),
        get('TOBACCO_5PERCENT_REVENUE', Screens.INCLUDE),
        get('WASTE_AND_HAZARDOUS_MATERIALS_MANAGEMENT_BIC', Screens.OFF),
        get('WATER_AND_WASTEWATER_MANAGEMENT_BIC', Screens.OFF),
        get('WEAPONS_COMPONENTS', Screens.EXCLUDE),
        get('WEAPONS_COMPONENTS_5PERCENT_REVENUE', Screens.EXCLUDE),
        get('WEAPONS_MILITARY', Screens.EXCLUDE),
        get('INVITRO_FERTILIZATION', Screens.OFF),
        get('EXPLOITATION', Screens.OFF),
    ]

    const progressive: ScreeningProfileItem[] = [
        get('ABORTIFACIENTS', Screens.INCLUDE),
        get('ABORTION_LEGISLATION', Screens.INCLUDE),
        get('ABORTION_PHILANTHROPY', Screens.INCLUDE),
        get('ABORTION_PROMOTION', Screens.INCLUDE),
        get('ACCESS_AND_AFFORDABILITY_BIC', Screens.INCLUDE),
        get('AIR_QUALITY_BIC', Screens.INCLUDE),
        get('ALCOHOL', Screens.OFF),
        get('ALCOHOL_5PERCENT_REVENUE', Screens.OFF),
        get('ANIMAL_TESTING', Screens.EXCLUDE),
        get('BUSINESS_ETHICS_BIC', Screens.INCLUDE),
        get('BUSINESS_MODEL_RESILIENCE_BIC', Screens.INCLUDE),
        get('CANNABIS_CBD_ONLY', Screens.INCLUDE),
        get('CANNABIS_CULTIVATION_PROCESSING', Screens.INCLUDE),
        get('CANNABIS_PHARMACEUTICAL', Screens.INCLUDE),
        get('CANNABIS_RETAIL_THC', Screens.INCLUDE),
        get('COMPETITIVE_BEHAVIOR_BIC', Screens.INCLUDE),
        get('CONTRACEPTIVES_BARRIER', Screens.OFF),
        get('CRITICAL_INCIDENT_RISK_MANAGEMENT_BIC', Screens.INCLUDE),
        get('CUSTOMER_PRIVACY_BIC', Screens.INCLUDE),
        get('CUSTOMER_WELFARE_BIC', Screens.INCLUDE),
        get('COMPETITIVE_BEHAVIOR_BIC', Screens.INCLUDE),
        get('DATA_SECURITY_BIC', Screens.INCLUDE),
        get('ECOLOGICAL_IMPACTS_BIC', Screens.INCLUDE),
        get('EMBRYONIC_STEM_CELL', Screens.OFF),
        get('EMBRYONIC_STEM_PRODUCTION', Screens.OFF),
        get('EMBRYONIC_STEM_RESEARCH', Screens.OFF),
        get('EMPLOYEE_ENGAGEMENT_DIVERSITY_AND_INCLUSION_BIC', Screens.INCLUDE),
        get('EMPLOYEE_HEALTH_AND_SAFETY_BIC', Screens.INCLUDE),
        get('ENERGY_MANAGEMENT_BIC', Screens.INCLUDE),
        get('GAMBLING', Screens.OFF),
        get('GAMBLING_5PERCENT_REVENUE', Screens.OFF),
        get('GHG_EMISSIONS_BIC', Screens.INCLUDE),
        get('GMO_PRODUCTS', Screens.EXCLUDE),
        get('HUMAN_RIGHTS_AND_COMMUNITY_RELATIONS_BIC', Screens.INCLUDE),
        get('LABOR_PRACTICES_BIC', Screens.INCLUDE),
        get('LGBTA_LEGISLATION', Screens.INCLUDE),
        get('LGBTA_PHILANTHROPY', Screens.INCLUDE),
        get('LGBTA_PROMOTION', Screens.INCLUDE),
        get(
            'MANAGEMENT_OF_THE_LEGAL_AND_REGULATORY_ENVIRONMENT_BIC',
            Screens.INCLUDE
        ),
        get('MATERIALS_SOURCING_AND_EFFICIENCY_BIC', Screens.INCLUDE),
        get('NUCLEAR', Screens.EXCLUDE),
        get('NUCLEAR_5PERCENT_REVENUE', Screens.EXCLUDE),
        get('PHYSICAL_IMPACTS_OF_CLIMATE_CHANGE_BIC', Screens.INCLUDE),
        get('PORNOGRAPHY', Screens.OFF),
        get('PRODUCT_DESIGN_AND_LIFECYCLE_MANAGEMENT_BIC', Screens.INCLUDE),
        get('INVITRO_FERTILIZATION', Screens.OFF),
        get('EXPLOITATION', Screens.OFF),
        get('PRODUCT_QUALITY_AND_SAFETY_BIC', Screens.INCLUDE),
        get('SELLING_PRACTICES_AND_PRODUCT_LABELING_BIC', Screens.INCLUDE),
        get('STATE_OWNED_ENTERPRISE', Screens.EXCLUDE),
        get('SUPPLY_CHAIN_MANAGEMENT_BIC', Screens.INCLUDE),
        get('SYSTEMIC_RISK_MANAGEMENT_BIC', Screens.INCLUDE),
        get('TOBACCO', Screens.OFF),
        get('TOBACCO_5PERCENT_REVENUE', Screens.OFF),
        get('WASTE_AND_HAZARDOUS_MATERIALS_MANAGEMENT_BIC', Screens.INCLUDE),
        get('WATER_AND_WASTEWATER_MANAGEMENT_BIC', Screens.INCLUDE),
        get('WEAPONS_COMPONENTS', Screens.EXCLUDE),
        get('WEAPONS_COMPONENTS_5PERCENT_REVENUE', Screens.EXCLUDE),
        get('WEAPONS_MILITARY', Screens.EXCLUDE),
    ]

    const conservative: ScreeningProfileItem[] = [
        get('ABORTIFACIENTS', Screens.EXCLUDE),
        get('ABORTION_LEGISLATION', Screens.EXCLUDE),
        get('ABORTION_PHILANTHROPY', Screens.EXCLUDE),
        get('ABORTION_PROMOTION', Screens.EXCLUDE),
        get('ACCESS_AND_AFFORDABILITY_BIC', Screens.INCLUDE),
        get('AIR_QUALITY_BIC', Screens.INCLUDE),
        get('ALCOHOL', Screens.EXCLUDE),
        get('ALCOHOL_5PERCENT_REVENUE', Screens.EXCLUDE),
        get('ANIMAL_TESTING', Screens.OFF),
        get('BUSINESS_ETHICS_BIC', Screens.INCLUDE),
        get('BUSINESS_MODEL_RESILIENCE_BIC', Screens.INCLUDE),
        get('CANNABIS_CBD_ONLY', Screens.OFF),
        get('CANNABIS_CULTIVATION_PROCESSING', Screens.EXCLUDE),
        get('CANNABIS_PHARMACEUTICAL', Screens.OFF),
        get('INVITRO_FERTILIZATION', Screens.OFF),
        get('EXPLOITATION', Screens.EXCLUDE),
        get('CANNABIS_RETAIL_THC', Screens.EXCLUDE),
        get('COMPETITIVE_BEHAVIOR_BIC', Screens.INCLUDE),
        get('CONTRACEPTIVES_BARRIER', Screens.OFF),
        get('CRITICAL_INCIDENT_RISK_MANAGEMENT_BIC', Screens.INCLUDE),
        get('CUSTOMER_PRIVACY_BIC', Screens.INCLUDE),
        get('CUSTOMER_WELFARE_BIC', Screens.INCLUDE),
        get('COMPETITIVE_BEHAVIOR_BIC', Screens.INCLUDE),
        get('DATA_SECURITY_BIC', Screens.INCLUDE),
        get('ECOLOGICAL_IMPACTS_BIC', Screens.INCLUDE),
        get('EMBRYONIC_STEM_CELL', Screens.EXCLUDE),
        get('EMBRYONIC_STEM_PRODUCTION', Screens.EXCLUDE),
        get('EMBRYONIC_STEM_RESEARCH', Screens.EXCLUDE),
        get('EMPLOYEE_ENGAGEMENT_DIVERSITY_AND_INCLUSION_BIC', Screens.INCLUDE),
        get('EMPLOYEE_HEALTH_AND_SAFETY_BIC', Screens.INCLUDE),
        get('ENERGY_MANAGEMENT_BIC', Screens.INCLUDE),
        get('GAMBLING', Screens.EXCLUDE),
        get('GAMBLING_5PERCENT_REVENUE', Screens.EXCLUDE),
        get('GHG_EMISSIONS_BIC', Screens.INCLUDE),
        get('GMO_PRODUCTS', Screens.OFF),
        get('HUMAN_RIGHTS_AND_COMMUNITY_RELATIONS_BIC', Screens.INCLUDE),
        get('LABOR_PRACTICES_BIC', Screens.INCLUDE),
        get('LGBTA_LEGISLATION', Screens.EXCLUDE),
        get('LGBTA_PHILANTHROPY', Screens.EXCLUDE),
        get('LGBTA_PROMOTION', Screens.EXCLUDE),
        get(
            'MANAGEMENT_OF_THE_LEGAL_AND_REGULATORY_ENVIRONMENT_BIC',
            Screens.INCLUDE
        ),
        get('MATERIALS_SOURCING_AND_EFFICIENCY_BIC', Screens.INCLUDE),
        get('NUCLEAR', Screens.INCLUDE),
        get('NUCLEAR_5PERCENT_REVENUE', Screens.INCLUDE),
        get('PHYSICAL_IMPACTS_OF_CLIMATE_CHANGE_BIC', Screens.INCLUDE),
        get('PORNOGRAPHY', Screens.EXCLUDE),
        get('PRODUCT_DESIGN_AND_LIFECYCLE_MANAGEMENT_BIC', Screens.INCLUDE),
        get('PRODUCT_QUALITY_AND_SAFETY_BIC', Screens.INCLUDE),
        get('SELLING_PRACTICES_AND_PRODUCT_LABELING_BIC', Screens.INCLUDE),
        get('STATE_OWNED_ENTERPRISE', Screens.EXCLUDE),
        get('SUPPLY_CHAIN_MANAGEMENT_BIC', Screens.INCLUDE),
        get('SYSTEMIC_RISK_MANAGEMENT_BIC', Screens.INCLUDE),
        get('TOBACCO', Screens.EXCLUDE),
        get('TOBACCO_5PERCENT_REVENUE', Screens.EXCLUDE),
        get('WASTE_AND_HAZARDOUS_MATERIALS_MANAGEMENT_BIC', Screens.INCLUDE),
        get('WATER_AND_WASTEWATER_MANAGEMENT_BIC', Screens.INCLUDE),
        get('WEAPONS_COMPONENTS', Screens.INCLUDE),
        get('WEAPONS_COMPONENTS_5PERCENT_REVENUE', Screens.INCLUDE),
        get('WEAPONS_MILITARY', Screens.INCLUDE),
    ]

    const sociallyResponsible: ScreeningProfileItem[] = [
        get('ABORTIFACIENTS', Screens.OFF),
        get('ABORTION_LEGISLATION', Screens.OFF),
        get('ABORTION_PHILANTHROPY', Screens.OFF),
        get('ABORTION_PROMOTION', Screens.OFF),
        get('ACCESS_AND_AFFORDABILITY_BIC', Screens.INCLUDE),
        get('AIR_QUALITY_BIC', Screens.INCLUDE),
        get('ALCOHOL', Screens.EXCLUDE),
        get('ALCOHOL_5PERCENT_REVENUE', Screens.EXCLUDE),
        get('ANIMAL_TESTING', Screens.EXCLUDE),
        get('BUSINESS_ETHICS_BIC', Screens.INCLUDE),
        get('BUSINESS_MODEL_RESILIENCE_BIC', Screens.INCLUDE),
        get('CANNABIS_CBD_ONLY', Screens.OFF),
        get('CANNABIS_CULTIVATION_PROCESSING', Screens.OFF),
        get('CANNABIS_PHARMACEUTICAL', Screens.OFF),
        get('CANNABIS_RETAIL_THC', Screens.OFF),
        get('COMPETITIVE_BEHAVIOR_BIC', Screens.INCLUDE),
        get('CONTRACEPTIVES_BARRIER', Screens.OFF),
        get('CRITICAL_INCIDENT_RISK_MANAGEMENT_BIC', Screens.INCLUDE),
        get('CUSTOMER_PRIVACY_BIC', Screens.INCLUDE),
        get('CUSTOMER_WELFARE_BIC', Screens.INCLUDE),
        get('COMPETITIVE_BEHAVIOR_BIC', Screens.INCLUDE),
        get('DATA_SECURITY_BIC', Screens.INCLUDE),
        get('ECOLOGICAL_IMPACTS_BIC', Screens.INCLUDE),
        get('EMBRYONIC_STEM_CELL', Screens.OFF),
        get('EMBRYONIC_STEM_PRODUCTION', Screens.OFF),
        get('EMBRYONIC_STEM_RESEARCH', Screens.OFF),
        get('EMPLOYEE_ENGAGEMENT_DIVERSITY_AND_INCLUSION_BIC', Screens.INCLUDE),
        get('EMPLOYEE_HEALTH_AND_SAFETY_BIC', Screens.INCLUDE),
        get('ENERGY_MANAGEMENT_BIC', Screens.INCLUDE),
        get('GAMBLING', Screens.EXCLUDE),
        get('GAMBLING_5PERCENT_REVENUE', Screens.EXCLUDE),
        get('GHG_EMISSIONS_BIC', Screens.INCLUDE),
        get('GMO_PRODUCTS', Screens.EXCLUDE),
        get('HUMAN_RIGHTS_AND_COMMUNITY_RELATIONS_BIC', Screens.INCLUDE),
        get('LABOR_PRACTICES_BIC', Screens.INCLUDE),
        get('LGBTA_LEGISLATION', Screens.OFF),
        get('LGBTA_PHILANTHROPY', Screens.OFF),
        get('LGBTA_PROMOTION', Screens.OFF),
        get(
            'MANAGEMENT_OF_THE_LEGAL_AND_REGULATORY_ENVIRONMENT_BIC',
            Screens.INCLUDE
        ),
        get('MATERIALS_SOURCING_AND_EFFICIENCY_BIC', Screens.INCLUDE),
        get('NUCLEAR', Screens.EXCLUDE),
        get('NUCLEAR_5PERCENT_REVENUE', Screens.EXCLUDE),
        get('PHYSICAL_IMPACTS_OF_CLIMATE_CHANGE_BIC', Screens.INCLUDE),
        get('PORNOGRAPHY', Screens.EXCLUDE),
        get('PRODUCT_DESIGN_AND_LIFECYCLE_MANAGEMENT_BIC', Screens.INCLUDE),
        get('PRODUCT_QUALITY_AND_SAFETY_BIC', Screens.INCLUDE),
        get('INVITRO_FERTILIZATION', Screens.OFF),
        get('EXPLOITATION', Screens.OFF),
        get('SELLING_PRACTICES_AND_PRODUCT_LABELING_BIC', Screens.INCLUDE),
        get('STATE_OWNED_ENTERPRISE', Screens.OFF),
        get('SUPPLY_CHAIN_MANAGEMENT_BIC', Screens.INCLUDE),
        get('SYSTEMIC_RISK_MANAGEMENT_BIC', Screens.INCLUDE),
        get('TOBACCO', Screens.EXCLUDE),
        get('TOBACCO_5PERCENT_REVENUE', Screens.EXCLUDE),
        get('WASTE_AND_HAZARDOUS_MATERIALS_MANAGEMENT_BIC', Screens.INCLUDE),
        get('WATER_AND_WASTEWATER_MANAGEMENT_BIC', Screens.INCLUDE),
        get('WEAPONS_COMPONENTS', Screens.EXCLUDE),
        get('WEAPONS_COMPONENTS_5PERCENT_REVENUE', Screens.EXCLUDE),
        get('WEAPONS_MILITARY', Screens.EXCLUDE),
    ]

    const profiles: { [name: string]: ScreeningProfileItem[] } = {
        'Faith Based (Evangelical)': evangelical,
        'Faith Based (Catholic)': catholic,
        None: none,
        Custom: none,
        Environmental: environmental,
        'Social Justice': socialJustice,
        Progressive: progressive,
        Conservative: conservative,
        'Socially Responsible': sociallyResponsible,
    }

    if (profiles[name]) return profiles[name]
    else return profiles['None']

    function get(title: string, value: number) {
        return { title: title, value: value }
    }
}
export default withRouter(Screening)
