import React from 'react'
import { Row, Col } from 'react-flexbox-grid'
import { IDetailsAPI } from '../../models/api'
import Category from './Category'

import { esgCriterionCategories } from './ESGCriterion'

const allCategoriesStyle: Record<string, unknown> = {
    backgroundColor: 'white',
    padding: 15,
}

export default class AllCategories extends React.Component<{
    categories: esgCriterionCategories
    fiId: number
    displayDetails: (details: IDetailsAPI) => void
}> {
    render() {
        const { categories, displayDetails } = this.props
        return (
            <Row style={allCategoriesStyle}>
                <Col xs={12} lg={4}>
                    <Category
                        esgCriterion={{
                            positive: categories,
                            negative: {} as esgCriterionCategories,
                        }}
                        environment="positive"
                        title="Environmental"
                        fiId={this.props.fiId}
                        displayDetails={displayDetails}
                        allCategories
                    />
                </Col>
                <Col xs={12} lg={4}>
                    <Category
                        esgCriterion={{
                            positive: categories,
                            negative: {} as esgCriterionCategories,
                        }}
                        environment="positive"
                        title="Social"
                        displayDetails={displayDetails}
                        fiId={this.props.fiId}
                        allCategories
                    />
                </Col>
                <Col xs={12} lg={4}>
                    <Category
                        esgCriterion={{
                            positive: categories,
                            negative: {} as esgCriterionCategories,
                        }}
                        environment="positive"
                        fiId={this.props.fiId}
                        displayDetails={displayDetails}
                        title="Governance"
                        allCategories
                    />
                </Col>
            </Row>
        )
    }
}
