import React from 'react'
import { Row, Col } from 'react-flexbox-grid'
import { IEsgCriterionCount } from '../../models/api'

export default class Count extends React.Component<{
    item: IEsgCriterionCount
    handleDetails: (category: string) => void
    environment: string
}> {
    render() {
        const { item, handleDetails, environment } = this.props
        return (
            <Row
                style={{
                    backgroundColor: 'white',
                    padding: 12,
                    borderRadius: 10,
                    marginTop: 10,
                }}
            >
                <Col xs>
                    <span
                        style={{
                            fontWeight: 500,
                            marginRight: 10,
                            fontSize: 16,
                            fontFamily: 'URW Geometric Ext, sans-serif',
                            color:
                                environment === 'positive'
                                    ? '#2b89ad'
                                    : '#bf435a',
                        }}
                    >
                        {item.count}
                    </span>
                    <span
                        style={{
                            fontSize: 16,
                            fontWeight: 900,
                            fontFamily: '"URW Geometric Cond", sans-serif',
                        }}
                    >
                        {item.criterion.title}
                    </span>
                </Col>
                <Col xs={3} style={{ textAlign: 'right' }}>
                    <span
                        style={{
                            fontSize: 12,
                            textDecoration: 'underline',
                            color: '#2c8aae',
                            fontFamily: 'URW Geometric Ext, sans-serif',
                            textTransform: 'uppercase',
                            cursor: 'pointer',
                            fontWeight: 600,
                        }}
                        onClick={() => {
                            handleDetails(item.criterion.name)
                        }}
                    >
                        View Details
                    </span>
                </Col>
            </Row>
        )
    }
}
