import React from 'react'

import { Grid, Row, Col } from 'react-flexbox-grid'
import logo from '../images/logo.svg'
import inst from '../images/inst.svg'
import Button from '../insightui/Button'
import { isMobile, isTablet } from 'react-device-detect'
import etfImage from '../images/etfs-ad-placeholder.jpg'
import { css, nthChild } from 'glamor'

import axios from 'axios'
import { HomeState } from '../models/state'
import { config } from '../models/config'
import { store } from '../store'
import { RankedComponent } from '../components/RankedComponent'
import SearchInput from '../components/Portfolios/SearchInput'
import AccountMenu from '../components/Dashboard/AccountMenu'
import AuthMenu from '../components/Dashboard/AuthMenu'
import { RouteComponentProps, withRouter } from 'react-router-dom'
import { ChevronDoubleUp } from '@styled-icons/bootstrap/ChevronDoubleUp'
import { ArrowRightShort } from '@styled-icons/bootstrap/ArrowRightShort'
import { ChevronDoubleDown } from '@styled-icons/bootstrap/ChevronDoubleDown'
import { ArrowTrending } from '@styled-icons/fluentui-system-regular/ArrowTrending'
import TrendingIssue from '../components/TrendingIssue'

class Home extends React.Component<RouteComponentProps> {
    state: HomeState = {
        loading: false,
        scrollActive: false,
        prev: window.scrollY,
        tokens: [],
        query: '',
        lastChange: null,
        loggedIn: false,
        token: null,
        account: null,
        count: 0,
        trending: null,
        open: false,
        ready: false,
        ranked: [],
    }

    componentDidMount() {
        window.addEventListener('scroll', this.performAction)
        const { isLogged, token } = store.getState()
        this.setState({ loggedIn: isLogged, token: token })
        this.getUserDetails()
        this.fetchCount()
        this.getTrending()
        this.getRankedTrending()
    }

    performAction = () => {
        this.setState({
            scrollActive: window.scrollY > 100,
            prev: window.scrollY,
        })
    }

    componentWillUnmount = () => {
        window.removeEventListener('scroll', this.performAction)
    }

    getUserDetails = async () => {
        await axios
            .get(`${config.api}/api/account`, {
                headers: { Authorization: `Bearer ${store.getState().token}` },
            })
            .then((response: any) => {
                this.setState({ account: response.data })
            })
    }

    getTrending = async () => {
        await axios.get(`${config.api}/api/trending`).then((response: any) => {
            this.setState({ trending: response.data })
        })
    }

    fetchCount = () => {
        axios.get(`${config.api}/api/count`).then((response) => {
            this.setState({ count: response.data })
        })
    }

    getRankedTrending = () => {
        axios.get(`${config.api}/api/ranked`).then((response) => {
            this.setState({ ranked: response.data, ready: true })
        })
    }

    redirectToAdvancedSearch = (p) => null

    render() {
        const loggedIn = store.getState().isLogged
        const { ranked } = this.state

        return (
            <div
                style={{
                    paddingTop: 170,
                    overflow: 'hidden',
                    backgroundColor: '#f7f7f7',
                    overflowY: 'hidden',
                }}
            >
                <Row
                    style={{
                        position: 'absolute',
                        top: 0,
                        backgroundColor: '#f7f7f7',
                        height: 70,
                        width: '100%',
                    }}
                >
                    <Col>
                        {!isMobile ? (
                            <Row
                                style={{
                                    paddingTop: 16,
                                    position: 'absolute',
                                    left: 0,
                                    paddingLeft: 35,
                                }}
                            >
                                <Button
                                    type="ghost"
                                    style={{
                                        fontSize: '14px',
                                    }}
                                    onClick={() => {
                                        this.props.history.push(`/about`)
                                    }}
                                >
                                    About
                                </Button>
                            </Row>
                        ) : (
                            <></>
                        )}
                    </Col>
                    {this.state.ready && (
                        <>
                            {loggedIn && this.state.account ? (
                                <Col>
                                    <AuthMenu
                                        onClick={() =>
                                            this.setState({ open: true })
                                        }
                                        padding={16}
                                        account={this.state.account}
                                    />

                                    <Row style={{ paddingTop: 15 }}>
                                        <AccountMenu
                                            account={this.state.account}
                                            open={this.state.open}
                                            onClose={() =>
                                                this.setState({ open: false })
                                            }
                                        />
                                    </Row>
                                </Col>
                            ) : (
                                <>
                                    <Row
                                        style={{
                                            paddingTop: 16,
                                            position: 'absolute',
                                            right: 0,
                                            paddingRight: 35,
                                        }}
                                    >
                                        <Col xs>
                                            <Button
                                                type="ghost"
                                                style={{
                                                    fontSize: '14px',
                                                    cursor: 'pointer',
                                                }}
                                                onClick={() => {
                                                    this.props.history.push(
                                                        `/login`
                                                    )
                                                }}
                                            >
                                                Login
                                            </Button>
                                            <Button
                                                type="primary"
                                                style={{
                                                    fontSize: '14px',
                                                    cursor: 'pointer',
                                                    width: 150,
                                                }}
                                                onClick={() => {
                                                    this.props.history.push(
                                                        `/plans-and-pricing`
                                                    )
                                                }}
                                            >
                                                Create Account
                                            </Button>
                                        </Col>
                                    </Row>
                                </>
                            )}
                        </>
                    )}
                </Row>
                <Grid>
                    <Row
                        style={{
                            width: '100%',
                            verticalAlign: 'center',
                            justifyContent: 'center',
                            margin: 0,
                        }}
                    >
                        <img
                            src={
                                window.location.host.includes('institutional')
                                    ? inst
                                    : logo
                            }
                            height={120}
                            alt="logo"
                        />
                    </Row>

                    <SearchInput isHome />
                    <Row style={{ justifyContent: 'center' }}>
                        {this.state.count > 0 ? (
                            <p style={{ fontSize: 12 }}>
                                Biblical Values Data on{' '}
                                <strong>
                                    {' '}
                                    {this.state.count
                                        .toString()
                                        .replace(
                                            /\B(?=(\d{3})+(?!\d))/g,
                                            ','
                                        )}{' '}
                                    tickers
                                </strong>
                            </p>
                        ) : (
                            <></>
                        )}
                    </Row>
                    <TrendingIssue />
                    {this.state.ready && (
                        <div style={{ paddingTop: 50, paddingBottom: 40 }}>
                            <Row style={{ padding: 10 }}>
                                <Col xs={12} md={6} lg>
                                    <RankedComponent
                                        title={
                                            <span>
                                                Top Scoring{' '}
                                                <strong>Funds</strong>
                                            </span>
                                        }
                                        icon={<ChevronDoubleUp width={30} />}
                                        items={ranked['topFunds']}
                                        onClick={() =>
                                            this.redirectToAdvancedSearch(
                                                'top-funds'
                                            )
                                        }
                                        disableAll
                                    />
                                </Col>
                                <Col xs={12} md={6} lg>
                                    <RankedComponent
                                        title={
                                            <span>
                                                Top Scoring{' '}
                                                <strong>Stocks</strong>
                                            </span>
                                        }
                                        icon={<ChevronDoubleUp width={30} />}
                                        items={ranked['topStocks']}
                                        onClick={() =>
                                            this.redirectToAdvancedSearch(
                                                'top-stocks'
                                            )
                                        }
                                        disableAll
                                    />
                                </Col>
                                <Col xs={12} md={6} lg>
                                    {this.state.trending ? (
                                        <RankedComponent
                                            title="Trending Tickers"
                                            icon={<ArrowTrending width={30} />}
                                            items={this.state.trending}
                                            disableAll
                                        />
                                    ) : (
                                        <></>
                                    )}
                                </Col>
                            </Row>
                            <Row style={{ paddingTop: 50, paddingBottom: 50 }}>
                                <Col xs={12} md={6} lg>
                                    <RankedComponent
                                        title={
                                            <span>
                                                Worst Scoring{' '}
                                                <strong>Funds</strong>
                                            </span>
                                        }
                                        icon={<ChevronDoubleDown width={30} />}
                                        items={ranked['worstFunds']}
                                        onClick={() =>
                                            this.redirectToAdvancedSearch(
                                                'worst-funds'
                                            )
                                        }
                                        disableAll
                                    />
                                </Col>
                                <Col xs={12} md={6} lg>
                                    <RankedComponent
                                        title={
                                            <span>
                                                Worst Scoring{' '}
                                                <strong>Stocks</strong>
                                            </span>
                                        }
                                        icon={<ChevronDoubleDown width={30} />}
                                        items={ranked['worstStocks']}
                                        onClick={() =>
                                            this.redirectToAdvancedSearch(
                                                'worst-stocks'
                                            )
                                        }
                                        disableAll
                                    />
                                </Col>
                                <Col xs={12} md={6} lg>
                                    {/* <h3>Inspire ETFs</h3>
                                    <Row style={{ paddingTop: 5 }}>
                                        <Col xs={6}>
                                            <a
                                                href="https://inspireetf.com"
                                                target="_blank"
                                            >
                                                <img
                                                    src={etfImage}
                                                    style={{
                                                        height: 'auto',
                                                        width: '100%',
                                                        float: 'left',
                                                    }}
                                                    alt=""
                                                />
                                            </a>
                                        </Col>

                                        <Col xs={6}>
                                            <p>
                                                Inspire is the world's largest
                                                faith-based ETF provider,
                                                managing over $1.13 billion in
                                                faith-based ETF assets (as of
                                                12/31/2021)
                                            </p>
                                        </Col>
                                    </Row>
                                    <Button
                                        type="outlined"
                                        style={{ width: '100%', marginTop: 15 }}
                                        onClick={() => {
                                            window.open(
                                                'https://www.inspireetf.com/',
                                                '_blank'
                                            )
                                        }}
                                    >
                                        Learn More
                                    </Button> */}
                                </Col>
                            </Row>
                        </div>
                    )}
                </Grid>
                <div
                    style={{
                        bottom: 0,
                        left: 0,
                        position: 'fixed',
                        backgroundColor: '#e9e9e9',
                        width: '100vw',
                        height: 45,
                        paddingLeft: 50,
                        paddingRight: 50,
                        paddingTop: 8,
                    }}
                >
                    <Row>
                        <Col>
                            <p
                                style={{
                                    color: '#717171',
                                    fontSize: 12,
                                    fontWeight: 600,
                                    cursor: 'pointer',
                                }}
                                onClick={() => {
                                    this.props.history.push(`/terms-of-service`)
                                }}
                            >
                                Terms
                            </p>
                        </Col>
                        <Col style={{ marginLeft: 30 }}>
                            <p
                                style={{
                                    color: '#717171',
                                    fontSize: 12,
                                    fontWeight: 600,
                                    cursor: 'pointer',
                                }}
                                onClick={() => {
                                    this.props.history.push(`/privacy-policy`)
                                }}
                            >
                                Privacy
                            </p>
                        </Col>
                        <Col style={{ marginLeft: 30 }}>
                            <p
                                style={{
                                    color: '#717171',
                                    fontSize: 12,
                                    fontWeight: 600,
                                    cursor: 'pointer',
                                }}
                                onClick={() => {
                                    this.props.history.push(`/help-and-support`)
                                }}
                            >
                                Help & Support
                            </p>
                        </Col>
                        <Col xs />
                        <Col style={{ paddingRight: 160 }}>
                            <p
                                style={{
                                    color: '#717171',
                                    fontSize: 12,
                                    fontWeight: 600,
                                }}
                            >
                                © Copyright - Inspire Insight
                            </p>
                        </Col>
                    </Row>
                </div>
            </div>
        )
    }
}

export default withRouter(Home)
