import React from 'react'
import { IStockApi, IEsgCriterionCount } from '../../models/api'
import Tag from '../../insightui/Tag'
import { Row, Col } from 'react-flexbox-grid'
import { config } from '../../models/config'
import Category from './Category'
import AllCategories from './AllCategories'
import nocategories from '../../images/noCategories.png'
import { IDetailsAPI } from '../../models/api'

import { isPositive, isNegative } from '../../services/screeningProfileService'
import { isMobile } from 'react-device-detect'

interface ESGCriterionProps {
    instrument: IStockApi
    displayDetails: (details: IDetailsAPI) => void
}

export type esgCriterionCategories = {
    environmental: Array<IEsgCriterionCount>
    social: Array<IEsgCriterionCount>
    governance: Array<IEsgCriterionCount>
}

interface ESGCriterionState {
    esgCriterion: {
        negative: esgCriterionCategories
        positive: esgCriterionCategories
    }
    ready: boolean
    allCategories: esgCriterionCategories
    hasNegative: boolean
    hasPositive: boolean
}

export type esgCategories = 'environmental' | 'social' | 'governance'

export default class ESGCriterion extends React.Component<ESGCriterionProps> {
    state: ESGCriterionState = {
        esgCriterion: {
            negative: {
                environmental: [],
                social: [],
                governance: [],
            },
            positive: {
                environmental: [],
                social: [],
                governance: [],
            },
        },
        allCategories: {
            environmental: [],
            social: [],
            governance: [],
        },
        ready: false,
        hasNegative: false,
        hasPositive: false,
    }

    componentWillMount() {
        const { instrument } = this.props
        let newState: ESGCriterionState = this.state

        let negativeCategories = 0
        let positiveCategories = 0

        if (instrument.esgIssueCriteriaCount === undefined) {
        } else {
            instrument.esgIssueCriteriaCount.forEach((count) => {
                if (isNegative(count.criterion.name)) {
                    negativeCategories++
                    if (count.criterion.esgType !== undefined) {
                        let category: esgCategories =
                            count.criterion.esgType.toLowerCase() as esgCategories
                        newState.esgCriterion.negative[category].push(count)
                    }
                } else if (isPositive(count.criterion.name)) {
                    positiveCategories++
                    if (count.criterion.esgType !== undefined) {
                        let category: esgCategories =
                            count.criterion.esgType.toLowerCase() as esgCategories

                        newState.esgCriterion.positive[category].push(count)
                    }
                }
            })
        }
        newState.ready = true
        newState.hasNegative = negativeCategories > 0
        newState.hasPositive = positiveCategories > 0
        newState.allCategories = this.createAllCategoriesFromState(newState)
        this.setState(newState)
    }

    createAllCategoriesFromState = (state?: ESGCriterionState) => {
        let resultSet = {
            environmental: [],
            social: [],
            governance: [],
        } as any
        if (this.props.instrument.esgIssueCriteriaCount) {
            this.props.instrument.esgIssueCriteriaCount.forEach(
                (count: any) => {
                    const esgTitle = count.criterion.name //LGBT_...
                    const esgType = count.criterion.esgType.toLowerCase() //SOCIAL / ENVIRONMENTAL / GOVERNANCE

                    if (!isNegative(esgTitle) && !isPositive(esgTitle)) {
                        resultSet[esgType].push(count)
                    }
                }
            )
        }

        return resultSet
    }

    render() {
        const { displayDetails } = this.props
        return (
            <>
                <Row>
                    <Col lg={6} xs={12}>
                        <Row>
                            <Tag
                                color="blue"
                                fullWidth
                                style={{
                                    padding: '15px 25px',
                                    margin: '0 8px',
                                }}
                            >
                                <span
                                    style={{ letterSpacing: 1.4, fontSize: 15 }}
                                >
                                    POSITIVE
                                </span>
                            </Tag>
                        </Row>
                        {this.state.hasPositive ? (
                            <>
                                <Category
                                    esgCriterion={this.state.esgCriterion}
                                    environment="positive"
                                    title="Environmental"
                                    key={Math.random() * 1000}
                                    fiId={
                                        this.props.instrument
                                            .financialInstrumentId
                                    }
                                    displayDetails={displayDetails}
                                />
                                <Category
                                    esgCriterion={this.state.esgCriterion}
                                    environment="positive"
                                    title="Social"
                                    key={Math.random() * 1000}
                                    fiId={
                                        this.props.instrument
                                            .financialInstrumentId
                                    }
                                    displayDetails={displayDetails}
                                />
                                <Category
                                    esgCriterion={this.state.esgCriterion}
                                    environment="positive"
                                    key={Math.random() * 1000}
                                    title="Governance"
                                    fiId={
                                        this.props.instrument
                                            .financialInstrumentId
                                    }
                                    displayDetails={displayDetails}
                                />{' '}
                            </>
                        ) : (
                            <Row
                                style={{
                                    justifyContent: 'center',
                                    padding: 30,
                                    paddingBottom: 0,
                                    margin: 0,
                                    backgroundColor: 'white',
                                }}
                            >
                                <Col xs={8}>
                                    <Row>
                                        <span
                                            style={{
                                                fontWeight: 700,
                                                fontSize: 24,
                                            }}
                                        >
                                            No Categories
                                        </span>
                                    </Row>
                                    <Row>
                                        <span
                                            style={{
                                                fontSize: 12,
                                                marginTop: 12,
                                            }}
                                        >
                                            There are no known categories to
                                            report.
                                        </span>
                                    </Row>
                                </Col>
                                <Col xs={4}>
                                    <img
                                        style={{
                                            position: 'relative',
                                            float: 'right',
                                            bottom: 10,
                                        }}
                                        src={nocategories}
                                    />
                                </Col>
                            </Row>
                        )}
                    </Col>
                    <Col lg={6} xs={12}>
                        <Row>
                            <Tag
                                color="red"
                                fullWidth
                                style={{
                                    padding: '15px 25px',
                                    margin: '0 8px',
                                }}
                            >
                                <span
                                    style={{ letterSpacing: 1.4, fontSize: 15 }}
                                >
                                    NEGATIVE
                                </span>
                            </Tag>
                        </Row>
                        {this.state.hasNegative ? (
                            <>
                                <Category
                                    esgCriterion={this.state.esgCriterion}
                                    environment="negative"
                                    title="Environmental"
                                    fiId={
                                        this.props.instrument
                                            .financialInstrumentId
                                    }
                                    displayDetails={displayDetails}
                                />
                                <Category
                                    esgCriterion={this.state.esgCriterion}
                                    environment="negative"
                                    title="Social"
                                    fiId={
                                        this.props.instrument
                                            .financialInstrumentId
                                    }
                                    displayDetails={displayDetails}
                                />
                                <Category
                                    esgCriterion={this.state.esgCriterion}
                                    environment="negative"
                                    title="Governance"
                                    fiId={
                                        this.props.instrument
                                            .financialInstrumentId
                                    }
                                    displayDetails={displayDetails}
                                />
                            </>
                        ) : (
                            <Row
                                style={{
                                    justifyContent: 'center',
                                    padding: 30,
                                    paddingBottom: 0,
                                    backgroundColor: 'white',
                                    margin: '0',
                                }}
                            >
                                <Col xs={8}>
                                    <Row>
                                        <span
                                            style={{
                                                fontWeight: 700,
                                                fontSize: 24,
                                            }}
                                        >
                                            No Categories
                                        </span>
                                    </Row>
                                    <Row>
                                        <span
                                            style={{
                                                fontSize: 12,
                                                marginTop: 12,
                                            }}
                                        >
                                            There are no known categories to
                                            report.
                                        </span>
                                    </Row>
                                </Col>
                                <Col xs={4}>
                                    <img
                                        style={{
                                            position: 'relative',
                                            bottom: 10,
                                            float: 'right',
                                        }}
                                        src={nocategories}
                                    />
                                </Col>
                            </Row>
                        )}
                    </Col>
                </Row>
                {this.state.allCategories.environmental.length ||
                this.state.allCategories.governance.length ||
                this.state.allCategories.social.length ? (
                    <>
                        <Row
                            style={{
                                paddingTop: 90,
                            }}
                        >
                            <Tag fullWidth color="navy">
                                OTHER
                            </Tag>
                        </Row>
                        <AllCategories
                            categories={this.state.allCategories}
                            fiId={this.props.instrument.financialInstrumentId}
                            displayDetails={displayDetails}
                        />
                    </>
                ) : (
                    <></>
                )}
            </>
        )
    }
}
