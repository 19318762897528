import React from 'react'
import axios from 'axios'
import { INews, INewsResponse } from '../../models/api'
import { config } from '../../models/config'
import { Col, Row } from 'react-flexbox-grid'
import { isMobile } from 'react-device-detect'
import { RouteComponentProps } from 'react-router-dom'

interface NewsProps extends RouteComponentProps {
    financialInstrumentId: number
}

interface NewsState {
    news: Array<INews>
    ready: boolean
}

interface NewsCardProps extends RouteComponentProps {
    news: INews
}
export class NewsCard extends React.Component<NewsCardProps> {
    render() {
        const { headline, ticker, datetime, url, related } = this.props.news
        return (
            <Col
                style={{
                    width: isMobile ? '95%' : 300,
                    height: 120,
                    padding: 20,
                    backgroundColor: 'white',
                    marginRight: 10,
                    marginLeft: 10,
                    marginTop: 20,
                    cursor: 'pointer',
                }}
                onClick={() => {
                    window.open(url.toString())
                }}
            >
                <Row>
                    <Col xs />
                    <Col>
                        <span
                            style={{
                                backgroundColor: '#0B192C',
                                color: 'white',
                                padding: 4,
                                fontWeight: 600,
                            }}
                        >
                            {ticker}
                        </span>
                    </Col>
                </Row>
                <Row style={{ paddingTop: 16 }}>
                    <span
                        style={{
                            fontWeight: 600,
                        }}
                    >
                        {headline.slice(0, 100)}{' '}
                        {headline.length > 100 ? '...' : ''}
                    </span>
                </Row>
                <Row
                    style={{
                        paddingTop: 10,
                        fontSize: 12,
                        fontWeight: 300,
                        color: 'grey',
                    }}
                >
                    <span>
                        Related Stocks:{' '}
                        {related
                            .split(',')
                            .slice(0, 7)
                            .map(
                                (item, index) =>
                                    `${item}${
                                        index ===
                                        related.split(',').slice(0, 7).length -
                                            1
                                            ? ''
                                            : ', '
                                    }`
                            )}
                    </span>
                </Row>
            </Col>
        )
    }
}

export default class News extends React.Component<NewsProps> {
    state: NewsState = {
        news: [],
        ready: false,
    }
    componentDidMount() {
        axios
            .get(
                `${config.api}/api/tickers/${this.props.financialInstrumentId}/news?number=6`
            )
            .then((response) => {
                const responseData: INewsResponse = response.data
                this.setState({ news: responseData, ready: true })
            })
            .catch((err) => console.log('there was an error requesting news'))
    }
    render() {
        return (
            <Row>
                {this.state.news.map((news: INews) => (
                    <NewsCard news={news} {...this.props} />
                ))}
            </Row>
        )
    }
}
