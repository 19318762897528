import logo from '../images/logo.svg'
import { store } from '../store'
import { config } from '../models/config'
import { ReactNode } from 'react'
import { isMobile, isTablet } from 'react-device-detect'
import axios from 'axios'
import { setAuthority } from '../actions'

export const determinePlanTitle = () => {
    const authorities = store.getState().authorities
    if (authorities.includes('ROLE_ADMIN')) {
        return 'Administrator'
    } else if (authorities.includes('ROLE_ADVISOR')) {
        return 'Advisor'
    } else if (authorities.includes('ROLE_PREMIUM')) {
        return 'Pro'
    } else {
        return 'Basic'
    }
}

export const fetchPlanTitle = () => {
    try {
        return Buffer.from(localStorage.getItem('prem'), 'base64').toString(
            'ascii'
        )
    } catch (e) {
        return ''
    }
}

export const globals = {
    colors: {
        positive: {
            blue: '#2c8aaf',
            bg: '#eff7fb',
        },
        negative: {
            red: '#bd425a',
            bg: '#f8ecef',
        },
        inspire: {
            orange: '#db7a27',
            arcpath: 'lightgrey',
            darknavy: '#0b192c',
            background: '#f7f7f7',
            green: 'hsla(153.669, 55.02%, 42.15%, 1)',
        },
        text: {
            default: '#0b192c',
        },
    },
    fonts: {
        default: '"URW Geometric Cond", sans-serif',
    },
    typeface: {
        spacedUppercase: {
            // fontFamily: '"URW Geometric Ext", sans-serif',
            letterSpacing: 1.4,
            // textTransform: 'uppercase',
            fontSize: 15,
            fontWeight: 900,
        },
        spacedUppercaseThin: {
            fontFamily: '"URW Geometric Ext", sans-serif',
            letterSpacing: '1.4px',
            textTransform: 'uppercase',
            fontSize: 15,
            fontWeight: 0,
        },
        searchBar: {
            verticalAlign: 'middle',
            fontFamily: '"URW Geometric Ext", sans-serif',
            color: '#0b192c',
            fontSize: 15,
        },
        defaultText: {
            verticalAlign: 'middle',
            fontFamily: '"URW Geometric Ext", sans-serif',
            color: '#0b192c',
            fontSize: 15,
        },
        tickerBoldUppercase: {
            verticalAlign: 'middle',
            fontFamily: '"URW Geometric Cond", sans-serif',
            color: '#0b192c',
            textTransform: 'uppercase',
            fontSize: isMobile && !isTablet ? 40 : 45,
            fontWeight: 700,
            letterSpacing: -1,
        },
        tickerTitleUppercase: {
            verticalAlign: 'middle',
            fontFamily: '"URW Geometric Cond", sans-serif',
            color: '#0b192c',
            fontSize: isMobile && !isTablet ? 20 : 31,
            fontWeight: 400,
            letterSpacing: -1,
        },
        wideButtonText: {
            verticalAlign: 'middle',
            fontFamily: '"URW Geometric", sans-serif',
            color: '#0b192c',
            fontSize: 18,
            fontWeight: 800,
        },
        miniButtonText: {
            verticalAlign: 'middle',
            fontFamily: '"URW Geometric Ext", sans-serif',
            color: '#0b192c',
            fontSize: 12,
            fontWeight: 800,
            cursor: 'pointer',
        },
        titleESG: {
            fontFamily: '"URW Geometric Cond", sans-serif',
            fontSize: 20,
            lineHeight: '1em',
            fontWeight: 700,
            verticalAlign: 'top',
            color: '#0b192c',
            letterSpacing: -1,
        },
        footerLinks: {
            fontFamily: '"URW Geometric Ext", sans-serif',
            fontSize: 16,
            fontWeight: 900,
            verticalAlign: 'top',
            color: '#0b192c',
        },
        finePrint: {
            fontFamily: '"URW Geometric Ext", sans-serif',
            textTransform: 'uppercase',
            fontSize: 10,
            color: '#0b192c',
            letterSpacing: 1,
        },
        headerIcon: {
            fontFamily: '"URW Geometric Ext", sans-serif',
            fontSize: 22,
            color: '#0b192c',
            fontWeight: 700,
        },
        greyLink: {
            letterSpacing: 0.1,
            fontSize: 18,
            fontWeight: 800,
            textSizeAdjust: '100%',
            fontFamily: 'URW Geometric Ext',
            color: 'rgb(173, 181, 184)',
        },
        disclaimer: {
            fontFamily: 'URW Geometric Ext',
            color: 'rgb(11, 25, 44)',
            fontSize: 12,
        },
        advancedSearch: {
            title: {
                fontFamily: '"URW Geometric Ext", sans-serif',
                fontSize: 28,
                color: '#0b192c',
                fontWeight: 700,
            },
            subtitle: {
                fontFamily: '"URW Geometric Ext", sans-serif',
                fontSize: 20,
                color: '#0b192c',
                fontWeight: 700,
            },
            subtitleII: {
                fontFamily: '"URW Geometric Cond", sans-serif',
                fontSize: 21,
                color: '#0b192c',
                letterSpacing: 0.6,
            },
            placeHolder: {
                '::placeholder': {
                    color: 'black',
                    fontWeight: 800,
                    fontSize: 18,
                    fontFamily: '"URW Geometric Cond", sans-serif',
                },
            },
            handles: {
                fontSize: 14,
                fontWeight: 800,
                fontFamily: '"URW Geometric Ext", sans-serif',
                borderColor: 'transparent',
                textAlign: 'center',
            },
        },
        footerComponent: {
            fontSize: 14,
            fontFamily: '"URW Geometric Ext", sans-serif',
        },
        tableRow: {
            even: {
                backgroundColor: 'white',
            },
            odd: {
                backgroundColor: '#e3eaee',
            },
            fontSize: 15,
            fontfamily: 'URW Geometric Cond',
        },
    },
    sizes: {
        button: {
            default: {
                width: 300,
            },
            mini: {
                width: 'auto',
            },
        },
        numberRoll: {
            width: 30,
        },
        impactArc: {
            width: 30,
        },
        impactScore: {
            large: {
                width: 60,
            },
            small: {
                width: 30,
            },
        },
        component: {
            verticalSpace: 25,
            verticalSpaceNoPadding: 65,
        },
    },
    styles: {
        presets: {
            noselect: {
                '-webkit-touch-callout': 'none' /* iOS Safari */,
                '-webkit-user-select': 'none' /* Safari */,
                '-khtml-user-select': 'none' /* Konqueror HTML */,
                '-moz-user-select': 'none' /* Old versions of Firefox */,
                '-ms-user-select': 'none' /* Internet Explorer/Edge */,
                'user-select': 'none' /* current non-prefixed version */,

                /* note: implement tsx file and change to the following
                WebkitTouchCallout: 'none', // iOS Safari
                WebkitUserSelect: 'none', // Safari
                KhtmlUserSelect: 'none', // Konqueror HTML
                MozUserSelect: 'none', // Old versions of Firefox
                msUserSelect: 'none', // Internet Explorer/Edge
                userSelect: 'none', // current non-prefixed version
                */
            },
            noOutline: {
                '-webkit-appearance': 'none',
                '-moz-appearance': 'none',
                appearance: 'none',
            },
        },

        positioning: {
            body: {
                marginLeft: `calc(50% - ${1400 / 2}px)`,
                width: 1400,
            },
        },
    },
    links: {
        footer: {
            insight: [
                {
                    name: 'Advanced Search',
                    href: `${config.host}/advanced-search`,
                },
                {
                    name: 'Dashboard',
                    href: `${config.host}/dashboard`,
                },
                {
                    name: 'Portfolios',
                    href: `${config.host}/portfolios`,
                },
                {
                    name: 'Engagement Campaigns',
                    href: `${config.host}/engagement`,
                },
                {
                    name: 'Account Settings',
                    href: `${config.host}/settings`,
                },
            ],
            account: [
                {
                    name: 'Plans & Pricing',
                    href: `${config.host}/plans-and-pricing`,
                },
                {
                    name: 'About Insight',
                    href: `${config.host}/about`,
                },
                {
                    name: 'Screening Categories',
                    href: `${config.host}/screening`,
                },
                {
                    name: 'Help & Support',
                    href: `${config.host}/support`,
                },
                {
                    name: 'Contact',
                    href: `mailto:support@inspireinvesting.com`,
                },
            ],
            bottomNav: [
                {
                    name: 'Terms',
                    href: `${config.host}/terms-of-service`,
                },
                {
                    name: 'Privacy',
                    href: `${config.host}/privacy-policy`,
                },
            ],
        },
    },
    util: {
        getRandomString(length?: number) {
            return Math.random().toString(36).substr(2, 9)
        },
        getFixReactStickyHeaderError() {
            return {
                paddingTop: 80,
                marginTop: -80,
            } as Record<string, any>
        },
        getCopyrightString() {
            return '© Copyright - Inspire Investing'
        },
        renderLogo(overrides?: Record<string, any>) {
            return (
                <img
                    src={logo}
                    style={{
                        width: 100,
                        ...overrides,
                    }}
                    alt="Inspire Insight"
                />
            )
        },
        renderIf(isRendered: boolean, node: ReactNode) {
            if (isRendered) return node
            return <></>
        },
        parseIntegers(stringOfNumbers: string, characterLimit: number) {
            const integerFiveDigits = stringOfNumbers
                .replace(/[^0-9]/g, '')
                .replace(/(\..*)\./g, '$1')
                .substring(0, characterLimit)
                .trim()
            return integerFiveDigits
        },
    },
}
