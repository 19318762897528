import React from 'react'

//styles
import './css/App.css'
import 'react-toastify/dist/ReactToastify.css'

//router
import {
    Router,
    Switch,
    Route,
    Redirect,
    RouteComponentProps,
} from 'react-router-dom'
import { store } from './store'

//pages
import InstrumentDetails from './pages/InstrumentDetails'
import Dashboard from './pages/Dashboard'
import InsightPro from './pages/InsightPro'
import Home from './pages/Home'
import NotFound from './pages/NotFound'
import Portfolios from './pages/Portfolios'
import About from './pages/About'
import Activation from './pages/Activate'
import Login from './pages/Login'
import ChangePassword from './pages/ChangePassword'
import Register, { Pages } from './pages/Register'
import ForgotPassword from './pages/ForgotPassword'
import AdvancedSearch from './pages/AdvancedSearch'
import Account from './pages/Account'
import TermsOfService from './pages/TermsOfService'
import PrivacyPolicy from './pages/PrivacyPolicy'
import RegistrationComplete from './pages/RegistrationComplete'
import AccountSettings from './pages/AccountSettings'
import { globals } from './utils/globals'
import ScreeningProfiles from './pages/ScreeningProfiles'
import FAQ from './pages/FAQ'
import HelpAndSupport from './pages/HelpAndSupport'
import ScreeningCategories from './pages/ScreeningCategories'
import PlansAndPricing from './pages/PlansAndPricing'

import createBrowserHistory from 'history/createBrowserHistory'
import { IAppState } from './models/state'

function PrivateRoute({ children, isAuthenticated, ...rest }) {
    return (
        <Route
            {...rest}
            render={({ location }) =>
                isAuthenticated ? (
                    children
                ) : (
                    <Redirect
                        to={{
                            pathname: '/register',
                            state: { from: location },
                        }}
                    />
                )
            }
        />
    )
}

function HiddenRouteWhenSignedIn({ children, isAuthenticated, ...rest }) {
    return (
        <Route
            {...rest}
            render={({ location }) =>
                !isAuthenticated ? (
                    children
                ) : (
                    <Redirect
                        to={{
                            pathname: '/dashboard',
                            state: { from: location },
                        }}
                    />
                )
            }
        />
    )
}

function AffiliateRoute({ ...props }) {
    return (
        <Route
            {...props}
            render={() => (
                <Redirect
                    to={{
                        pathname: `/register${props.location.pathname}`,
                    }}
                />
            )}
        />
    )
}

function OldSiteCompatibility() {
    let url = window.location.href
    if (url.includes('1784')) {
        return <Redirect to="/BIBL" />
    } else if (url.includes('1842')) {
        return <Redirect to="/BLES" />
    } else if (url.includes('63092514')) {
        return <Redirect to="/WWJD" />
    } else if (url.includes('126649618')) {
        return <Redirect to="/RISN" />
    } else if (url.includes('184419619')) {
        return <Redirect to="/GLRY" />
    } else if (url.includes('4797')) {
        return <Redirect to="/IBD" />
    } else if (url.includes('184419459')) {
        return <Redirect to="/FEVR" />
    } else if (url.includes('5120')) {
        return <Redirect to="/ISMD" />
    }
    return <></>
}

export const history = createBrowserHistory()
export default class App extends React.Component<{ store: IAppState }> {
    componentDidMount() {
        document.body.style.backgroundColor = globals.colors.inspire.background
    }
    render() {
        const auth = store.getState().isLogged
        return (
            <Router history={history}>
                {window.location.href.includes('#') && <OldSiteCompatibility />}
                <Switch>
                    <Route exact path="/">
                        <Home />
                    </Route>
                    <PrivateRoute
                        isAuthenticated={auth}
                        exact
                        path="/dashboard"
                    >
                        <Dashboard view="standard" />
                    </PrivateRoute>
                    <PrivateRoute
                        isAuthenticated={auth}
                        exact
                        path="/portfolios/:id?"
                    >
                        <Portfolios />
                    </PrivateRoute>
                    <Route exact path="/insightpro">
                        <InsightPro />
                    </Route>
                    <PrivateRoute isAuthenticated={auth} exact path="/account">
                        <Account />
                    </PrivateRoute>
                    <Route exact path="/plans-and-pricing">
                        <PlansAndPricing />
                        {/* <Register /> */}
                    </Route>
                    <PrivateRoute
                        isAuthenticated={auth}
                        exact
                        path="/advanced-search"
                    >
                        <AdvancedSearch />
                    </PrivateRoute>
                    <Route exact path="/checkout">
                        <Register defaultPage={Pages.CHECKOUT} />
                    </Route>
                    <Route exact path="/404">
                        <NotFound />
                    </Route>
                    <Route exact path="/about">
                        <About />
                    </Route>
                    <Route exact path="/login">
                        <Login />
                    </Route>
                    <PrivateRoute
                        isAuthenticated={auth}
                        exact
                        path="/screening-profiles"
                    >
                        <ScreeningProfiles />
                    </PrivateRoute>
                    <HiddenRouteWhenSignedIn
                        isAuthenticated={auth}
                        exact
                        path="/register/:code?"
                    >
                        <Register />
                    </HiddenRouteWhenSignedIn>
                    {/* <Route exact path="/register"> </Route> */}
                    <Route exact path="/forgot-password">
                        <ForgotPassword />
                    </Route>
                    <Route path="/change-password/:key?">
                        <ChangePassword />
                    </Route>
                    <Route path="/activate/:key" children={<Activation />} />
                    <Route exact path="/terms-of-service">
                        <TermsOfService />
                    </Route>
                    <Route exact path="/privacy-policy">
                        <PrivacyPolicy />
                    </Route>
                    <Route exact path="/registration-complete">
                        <RegistrationComplete />
                    </Route>
                    <PrivateRoute
                        isAuthenticated={auth}
                        exact
                        path="/account-settings"
                    >
                        <AccountSettings />
                    </PrivateRoute>
                    <Route exact path="/faq">
                        <FAQ />
                    </Route>
                    <Route exact path="/help-and-support">
                        <HelpAndSupport />
                    </Route>
                    <Route exact path="/screening-categories">
                        <ScreeningCategories />
                    </Route>
                    <AffiliateRoute path="/metaxes" />
                    <Route
                        // exact
                        path="/:ticker/:location?"
                        children={<InstrumentDetails />}
                    />
                </Switch>
            </Router>
        )
    }
}
