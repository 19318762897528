import { globals } from '../utils/globals'
import { Component, ReactNode } from 'react'
import { css } from 'glamor'

interface ToolTipProps {
    size?: number
    value?: string
    icon?: ReactNode
}

export default class ToolTip extends Component<ToolTipProps> {
    onAfter: Record<string, any> = {
        '::after': {
            content: '',
            position: 'absolute',
            top: '100%',
            left: '50%',
            marginLeft: '-5px',
            borderWidth: '5px',
            borderStyle: 'solid',
            borderColor: '#555 transparent transparent transparent',
        },
    }

    render() {
        const h: number = this.props.size ? this.props.size : 20
        const h2: number = 400
        return (
            <div
                {...css({
                    //backgroundColor: 'whitesmoke',
                    borderRadius: '100%',
                    width: h,
                    height: h,
                    display: 'inline-block',
                    verticalAlign: 'top',
                    marginTop: '-10px',
                })}
            >
                <div
                    style={{
                        display: 'inline-block',
                    }}
                >
                    <div>{this.props.icon ? this.props.icon : <></>}</div>
                </div>
                <div
                    {...css({
                        paddingTop: 20,
                        //backgroundColor: 'blue',
                        marginTop: -20,
                        width: h,
                        height: 10,
                        marginLeft: 0,
                        position: 'absolute',
                        overflow: 'hidden',
                        ':hover': {
                            backgroundColor: 'transparent',
                            width: h2 + h,
                            height: 90,
                            overflow: 'visible',
                        },
                    })}
                >
                    <div
                        {...css({
                            //backgroundColor: 'red',
                            width: h2,
                            marginTop: '0px',
                            height: 'auto',
                            marginLeft: h,
                            position: 'absolute',
                            ':active': {
                                display: 'inline-block',
                            },
                        })}
                    >
                        <p
                            style={{
                                ...globals.typeface.miniButtonText,
                                marginTop: '-5%',
                                padding: 15,
                                backgroundColor: globals.colors.positive.bg,
                                border: `2px solid ${globals.colors.positive.blue}`,
                                borderRadius: 5,
                                color: globals.colors.inspire.darknavy,
                                cursor: 'default',
                            }}
                        >
                            {this.props.value
                                ? this.props.value
                                : 'Define ToolTip text with value prop'}
                        </p>
                    </div>
                </div>
            </div>
        )
    }
}
