import React from 'react'
import { IDetailsAPI } from '../../models/api'
import { Row } from 'react-flexbox-grid'
import { globals } from '../../utils/globals'
import { RouteComponentProps, withRouter } from 'react-router-dom'

interface ViolationDetailsProps extends RouteComponentProps {
    details: Array<IDetailsAPI>
}

class ViolationDetails extends React.Component<ViolationDetailsProps> {
    render() {
        const { details } = this.props
        return (
            <div
                style={{
                    height: 300,
                    padding: 20,
                    overflow: 'auto',
                }}
            >
                {details.map((item: IDetailsAPI) => (
                    <div
                        style={{
                            borderBottom: '#9a9a9a',
                            boxShadow: '6px 6px 6px 6px #f8f8f8',
                            padding: 12,
                            marginBottom: 10,
                        }}
                    >
                        <Row>
                            <span style={globals.typeface.wideButtonText}>
                                {item.title}
                            </span>
                        </Row>
                        <Row>
                            <span
                                style={{
                                    ...globals.typeface.searchBar,
                                    fontWeight: 500,
                                }}
                            >
                                {item.date}
                            </span>
                        </Row>
                        <Row style={{ marginTop: 12 }}>
                            <span style={globals.typeface.searchBar}>
                                {item.detail}
                            </span>
                        </Row>
                        <Row style={{ marginTop: 12 }}>
                            <span style={globals.typeface.searchBar}>
                                Source: {item.source}
                            </span>
                        </Row>
                        <Row style={{ marginTop: 15 }}>
                            <a
                                style={{
                                    ...globals.typeface.searchBar,
                                    color: '#50a0be',
                                    cursor: 'pointer',
                                }}
                                href={item.url}
                                target="_blank"
                            >
                                {item.url}
                            </a>
                        </Row>
                    </div>
                ))}
            </div>
        )
    }
}

export default withRouter(ViolationDetails)
