import React, { useRef, useState, useMemo, useEffect } from 'react'
import { scaleTime, scaleLinear } from '@visx/scale'
import { Brush } from '@visx/brush'
import { Bounds } from '@visx/brush/lib/types'
import { PatternLines } from '@visx/pattern'
import { max, extent } from 'd3-array'
import BaseBrush from '@visx/brush/lib/BaseBrush'
import AreaChart from './AreaChart'
import { Row, Col } from 'react-flexbox-grid'
import Button from '../insightui/Button'
import { ArrowUp } from '@styled-icons/feather/ArrowUp'
import { ArrowDown } from '@styled-icons/feather/ArrowDown'
import { isMobile, isTablet } from 'react-device-detect'

// Initialize some variables
const brushMargin = { top: 10, bottom: 15, left: 50, right: 20 }
const chartSeparation = 30
const PATTERN_ID = 'brush_pattern'
export const accentColor = '#0B192C'
export const background = '#ffff'
export const background2 = '#2c8aae'
// const selectedBrushStyle = {
//     fill: `url(#${PATTERN_ID})`,
//     stroke: 'white',
// }

const now: any = new Date()
const start: any = new Date(now.getFullYear(), 0, 0)
const diff =
    now -
    start +
    (start.getTimezoneOffset() - now.getTimezoneOffset()) * 60 * 1000
const oneDay = 1000 * 60 * 60 * 24

const RANGEMAP: any = {
    '1M': 21,
    '3M': 63,
    '6M': 126,
    YTD: Math.floor(diff / oneDay),
    '1Y': 252,
    '2Y': 504,
    '5Y': 1250,
}
// accessors
const getDate = (d: { date: string; close: number }) => new Date(d.date)
const getStockValue = (d: { date: string; close: number }) => d.close

const Performance = (props: {
    filteredStock: Array<{ date: string; close: number }>
}) => {
    const { filteredStock } = props
    const end = filteredStock[filteredStock.length - 1].close
    const start = filteredStock[0].close

    const calculatePercentChange = () => {
        return (((start - end) / ((start + end) / 2)) * -100)
            .toFixed(2)
            .toString()
            .replace(/\B(?=(\d{3})+(?!\d))/g, ',')
    }

    return (
        <Row style={{ color: end > start ? 'green' : 'red', paddingLeft: 25 }}>
            <Col>
                {' '}
                {end > start ? (
                    <ArrowUp width={18} />
                ) : (
                    <ArrowDown width={18} />
                )}
            </Col>
            <Col style={{ marginRight: 20 }}>
                $
                {(end - start)
                    .toFixed(2)
                    .toString()
                    .replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
            </Col>
            <Col>{calculatePercentChange()}%</Col>
        </Row>
    )
}

export type SelectionType = '1M' | '3M' | '6M' | '1Y' | '2Y' | '5Y'

export type BrushProps = {
    width: number
    height: number
    margin?: { top: number; right: number; bottom: number; left: number }
    compact?: boolean
    compare?: boolean
    data: Array<{
        date: string
        close: number
    }>
    onTimePeriodChange?: (period: SelectionType) => void
}

function BrushChart({
    compact = false,
    width,
    height,
    data,
    compare,
    margin = {
        top: 20,
        left: 50,
        bottom: 0,
        right: 20,
    },
    onTimePeriodChange,
}: BrushProps) {
    const stock = data
    const brushRef = useRef<BaseBrush | null>(null)
    const [filteredStock, setFilteredStock] = useState(stock)
    const [activeSelection, setActiveSelection] = useState('5Y')

    const onBrushChange = (domain: Bounds | null) => {
        if (!domain) return
        const { x0, x1, y0, y1 } = domain
        const stockCopy = stock.filter((s) => {
            const x = getDate(s).getTime()
            const y = getStockValue(s)
            return x > x0 && x < x1 && y > y0 && y < y1
        })
        setFilteredStock(stockCopy)
    }

    const innerHeight = height - margin.top - margin.bottom
    const topChartBottomMargin = compact
        ? chartSeparation / 2
        : chartSeparation + 10
    const topChartHeight = 0.8 * innerHeight - topChartBottomMargin
    const bottomChartHeight = innerHeight - topChartHeight - chartSeparation

    let closePrices: Array<number> = []

    stock.forEach((item) => {
        closePrices.push(item.close)
    })

    // bounds
    const xMax = Math.max(width - margin.left - margin.right, 0)
    const yMax = Math.max(topChartHeight, 0)
    const yMin = Math.min.apply(Math, closePrices)

    const xBrushMax = Math.max(width - brushMargin.left - brushMargin.right, 0)
    const yBrushMax = Math.max(
        bottomChartHeight - brushMargin.top - brushMargin.bottom,
        0
    )

    const setToTimePeriod = (item: string) => {
        setActiveSelection(item)
        //find the proper ranges

        const rangeLength = stock.length - RANGEMAP[item]

        let start: number = rangeLength > 0 ? rangeLength : 0
        let end: number = stock.length

        setFilteredStock(stock.slice(start, end))
    }

    // scales
    const dateScale = useMemo(
        () =>
            scaleTime<number>({
                range: [0, xMax],
                domain: extent(filteredStock, getDate) as [Date, Date],
            }),
        [xMax, filteredStock]
    )

    const stockScale = useMemo(
        () =>
            scaleLinear<number>({
                range: [yMax, 0],
                domain: [yMin, max(filteredStock, getStockValue) || 0],
                nice: true,
            }),
        [yMax, filteredStock, yMin]
    )

    const brushDateScale = useMemo(
        () =>
            scaleTime<number>({
                range: [0, xBrushMax],
                domain: extent(stock, getDate) as [Date, Date],
            }),
        [xBrushMax]
    )

    const brushStockScale = useMemo(
        () =>
            scaleLinear({
                range: [yBrushMax, 0],
                domain: [0, max(stock, getStockValue) || 0],
                nice: true,
            }),
        [yBrushMax, stock]
    )

    const initialBrushPosition = useMemo(
        () => ({
            start: {
                x: brushDateScale(
                    getDate(stock[stock.length > 365 ? stock.length - 252 : 0])
                ),
            },
            end: { x: brushDateScale(getDate(stock[stock.length - 1])) },
        }),
        [brushDateScale, stock]
    )

    return (
        <div>
            <Row style={{ paddingTop: 20, paddingLeft: 10 }}>
                <Col sm={3} xs={12}>
                    <h2 style={{ margin: '0 0 10px' }}>
                        $
                        {stock[stock.length - 1].close
                            .toFixed(2)
                            .toString()
                            .replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                    </h2>
                </Col>
                <Col xs={12} sm={9}>
                    {['1M', '3M', '6M', 'YTD', '1Y', '2Y', '5Y'].map((item) => (
                        <div
                            style={{
                                marginRight: compare ? 2 : 5,
                                display: 'inline-block',
                                marginBottom: isMobile && !isTablet ? 5 : 0,
                            }}
                        >
                            <Button
                                type="mini"
                                style={
                                    activeSelection === item
                                        ? {
                                              backgroundColor: '#2c8aae',
                                              color: 'white',
                                              borderColor: '#2c8aae',
                                              padding: compare
                                                  ? '8px 12px'
                                                  : '10px 20px',
                                          }
                                        : {
                                              padding: compare
                                                  ? '8px 12px'
                                                  : '10px 20px',
                                          }
                                }
                                onClick={() => {
                                    if (onTimePeriodChange) {
                                        onTimePeriodChange(
                                            item as SelectionType
                                        )
                                    }
                                    setToTimePeriod(item)
                                }}
                            >
                                {item}
                            </Button>
                        </div>
                    ))}
                </Col>
            </Row>
            <Row style={{ marginTop: 10 }}>
                <Col>
                    {' '}
                    <Performance filteredStock={filteredStock} />
                </Col>
            </Row>
            <svg width={width} height={height - 130}>
                <AreaChart
                    hideBottomAxis={compact}
                    data={filteredStock}
                    width={width}
                    margin={{ ...margin, bottom: topChartBottomMargin }}
                    yMax={yMax}
                    xScale={dateScale}
                    yScale={stockScale}
                    gradientColor={background2}
                />
                {/* <AreaChart
                    hideBottomAxis
                    hideLeftAxis
                    data={stock}
                    width={width}
                    yMax={yBrushMax}
                    xScale={brushDateScale}
                    yScale={brushStockScale}
                    margin={brushMargin}
                    top={topChartHeight + topChartBottomMargin + margin.top}
                    gradientColor={background2}
                >
                    <PatternLines
                        id={PATTERN_ID}
                        height={8}
                        width={8}
                        stroke={'#fff'}
                        strokeWidth={1}
                        orientation={['diagonal']}
                    />
                    <Brush
                        xScale={brushDateScale}
                        yScale={brushStockScale}
                        width={xBrushMax}
                        height={yBrushMax}
                        margin={brushMargin}
                        handleSize={8}
                        innerRef={brushRef}
                        resizeTriggerAreas={['left', 'right']}
                        brushDirection="horizontal"
                        initialBrushPosition={initialBrushPosition}
                        onChange={(bounds) => {
                            console.log(bounds)
                            console.log(stock)
                            onBrushChange(bounds)
                            console.log(brushRef.current)
                        }}
                        onClick={() => {
                            setFilteredStock(stock)
                        }}
                        selectedBoxStyle={selectedBrushStyle}
                        useWindowMoveEvents
                    />
                </AreaChart> */}
            </svg>
            <div>
                <Row style={{ padding: 20, paddingLeft: 55 }}></Row>
            </div>
        </div>
    )
}

export default BrushChart
