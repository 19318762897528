import React from 'react'
import ReactDOM from 'react-dom'
import './css/index.css'
import App from './App'
import { Provider } from 'react-redux'
import * as serviceWorker from './serviceWorker'
import { store, persistor } from './store'
import { CookiesProvider } from 'react-cookie'
import { PersistGate } from 'redux-persist/integration/react'
import ScaleLoader from 'react-spinners/ScaleLoader'

//dispatch
ReactDOM.render(
    <Provider store={store}>
        <CookiesProvider>
            <PersistGate
                loading={
                    <div style={{ paddingLeft: '50%', paddingTop: '25%' }}>
                        <ScaleLoader color={'black'} loading={true} />
                    </div>
                }
                persistor={persistor}
                onBeforeLift={() => {}}
            >
                <App store={store.getState()} />
            </PersistGate>
        </CookiesProvider>
    </Provider>,
    document.getElementById('root')
)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()
