import React from 'react'
import { Row, Col } from 'react-flexbox-grid'
import { css } from 'glamor'
import Header from '../insightui/Header'
import Footer from '../insightui/Footer'
import { isMobile, isTablet } from 'react-device-detect'
import { config } from '../models/config'

import { QuestionSquare } from '@styled-icons/bootstrap/QuestionSquare'
import { ChatRightDots } from '@styled-icons/bootstrap/ChatRightDots'
import { At } from '@styled-icons/bootstrap/At'
import { RouteComponentProps, withRouter } from 'react-router-dom'

class HelpAndSupport extends React.Component<RouteComponentProps> {
    supportContainer = css({
        ':hover': {
            border: '1px solid black !important',
            transition: 'border-color 0.5s',
        },
    })
    launchChat() {
        let iframe = document.querySelector('#jsd-widget') as HTMLObjectElement
        let button = iframe.contentDocument.querySelector(
            '#help-button'
        ) as HTMLObjectElement
        button.click()
    }

    render() {
        return (

            <div style={{ overflowX: 'hidden' }}>
                <Header logo subscriberExempt />
                <Row
                    style={{
                        paddingTop: isMobile && !isTablet ? 160 : 120,
                        paddingLeft: 50,
                    }}
                >
                    <h1 style={{ fontSize: isMobile ? 30 : 50, lineHeight: 1 }}>
                        Help & Support
                    </h1>
                </Row>
                <Row style={{ paddingLeft: 50 }}>
                    <Col xs={12} lg={5}>
                        <p
                            style={{
                                fontSize: 20,
                            }}
                        >
                            Our dedicated team will help you with any questions
                            or assistance you may need
                        </p>
                    </Col>
                </Row>
                <Row style={{ padding: 40 }} between="xs">
                    <Col
                        {...this.supportContainer}
                        lg
                        xs={12}
                        style={{
                            backgroundColor: 'white',
                            padding: 40,
                            border: '1px solid #fff',
                            transition: 'border-color 0.3s',
                            margin: '0 20px 20px 0',
                            cursor: 'pointer',
                        }}
                        onClick={() => {
                            this.props.history.push('/faq')
                        }}
                    >
                        <Row>
                            <Col xs />
                            <Col>
                                <QuestionSquare width={30} />
                            </Col>
                        </Row>
                        <Row>
                            <h3 style={{ fontSize: 25 }}>
                                Frequency asked questions
                            </h3>
                        </Row>
                        <Row>
                            <span
                                style={{
                                    fontFamily: 'URW Geometric Cond',
                                    fontSize: 18,
                                }}
                            >
                                Get answers on our most frequently asked
                                questions
                            </span>
                        </Row>
                    </Col>
                    <Col
                        {...this.supportContainer}
                        lg
                        xs={12}
                        style={{
                            backgroundColor: 'white',
                            margin: '0 20px 20px 0',
                            border: '1px solid #fff',
                            transition: 'border-color 0.3s',
                            padding: 40,
                            cursor: 'pointer',
                        }}
                        onClick={this.launchChat}
                    >
                        <Row>
                            <Col xs />
                            <Col>
                                <ChatRightDots width={30} />
                            </Col>
                        </Row>
                        <Row>
                            <h3 style={{ fontSize: 25 }}>Chat support</h3>
                        </Row>
                        <Row>
                            <span
                                style={{
                                    fontFamily: 'URW Geometric Cond',
                                    fontSize: 18,
                                }}
                            >
                                Communicate live through our chat support right
                                here on our website
                            </span>
                        </Row>
                    </Col>
                    <Col
                        {...this.supportContainer}
                        lg
                        xs={12}
                        style={{
                            backgroundColor: 'white',
                            border: '1px solid #fff',
                            transition: 'border-color 0.3s',
                            margin: '0 20px 20px 0',
                            padding: 40,
                            cursor: 'pointer',
                        }}
                        onClick={() => {
                            document.getElementById('supportEmailLink').click()
                        }}
                    >
                        <Row>
                            <Col xs />
                            <Col>
                                <At width={38} />
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <h3 style={{ fontSize: 25 }}>
                                    Send us an email
                                </h3>
                                <span
                                    style={{
                                        fontFamily: 'URW Geometric Cond',
                                        fontSize: 18,
                                    }}
                                >
                                    Send us an email at{' '}
                                    <a
                                        href="mailto:support@inspireinsight.com"
                                        style={{
                                            textDecoration: 'none',
                                            color: '#6BA8C4',
                                            fontWeight: '800',
                                            fontFamily: 'inherit',
                                        }}
                                        id="supportEmailLink"
                                    >
                                        support@inspireinsight.com
                                    </a>
                                    . We will provide email support Monday
                                    through Friday. We'll get back to you within
                                    1-3 business days.
                                </span>
                            </Col>
                        </Row>
                    </Col>
                </Row>
                <Footer />
            </div>
        )
    }
}

export default withRouter(HelpAndSupport)
