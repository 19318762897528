import React from 'react'
import { withRouter, RouteComponentProps } from 'react-router'
import { LoadingOutlined } from '@ant-design/icons'
import Layout from '../components/Layout'
import axios from 'axios'
import { Grid, Row, Col } from 'react-flexbox-grid'
import { config } from '../models/config'
import Footer from '../insightui/Footer'
import { isMobile } from 'react-device-detect'
import {
    IStockApi,
    UniversalSearchResultItem,
    IFundHoldingApi,
    ChartItem,
    ChartResponse,
    FundHoldingsTable,
    IPortfolio,
} from '../models/api'
import ScaleLoader from 'react-spinners/ScaleLoader'
import { CloseO } from '@styled-icons/evil/CloseO'

import ESGCriterion from '../components/InstrumentDetails/ESGCriterion'
import ESGCriterionFund from '../components/InstrumentDetails/ESGCriterionFund'
import { DetailsHeader } from '../components/InstrumentDetails/DetailsHeader'

import Table from '../insightui/Table'
import Fundamentals from '../components/InstrumentDetails/Fundamentals'
import BrushChart from '../insightui/BrushChart'
import { ParentSize } from '@visx/responsive'
import Modal from '../components/Modal'
import ViolationDetails from '../components/InstrumentDetails/ViolationDetails'
import { IDetailsAPI } from '../models/api'
import { store } from '../store'
import ScreeningProfileModal from '../components/ScreeningProfileModal'
import Button from '../insightui/Button'
import News from '../components/InstrumentDetails/News'
import AddToPortfolio from '../components/Portfolios/AddToPortfolio'
import { toast } from 'react-toastify'
import PlansAndPricing from './PlansAndPricing'
import { Cookies } from 'react-cookie'
import engagement from '../models/engagement.json'
import { indeterminate } from 'glamor'
import PendingViolations from '../components/InstrumentDetails/PendingViolations'

const MAPS: any = {
    '1M': 'ONE_MONTH',
    '3M': 'THREE_MONTHS',
    '6M': 'SIX_MONTHS',
    YTD: 'YEAR_TO_DATE',
    '1Y': 'ONE_YEAR',
    '2Y': 'TWO_YEARS',
    '5Y': 'FIVE_YEARS',
}

type PathParams = {
    ticker: string
    location?: string
}

type InstrumentDetailsState = {
    ticker: string
    instrumentDetails: IStockApi
    searchResults: UniversalSearchResultItem
    ready: boolean
    constituents: any
    chart: Array<any>
    description: string | undefined | null
    expanded: boolean
    addToModal: boolean
    divRef: any
    dateSelection: string
    fetched: boolean
    displayDetails: any
    displayModal: boolean
    profileSwitch: boolean
    id: number | null
    addTo: boolean
    portfolios: Array<IPortfolio>
}

type PropsType = RouteComponentProps<PathParams> & {}

class InstrumentDetails extends React.Component<PropsType> {
    state: InstrumentDetailsState = {
        ticker: '',
        searchResults: {} as UniversalSearchResultItem,
        instrumentDetails: {} as IStockApi,
        ready: false,
        constituents: [],
        chart: [],
        description: '',
        expanded: false,
        addToModal: false,
        divRef: null,
        dateSelection: '1Y',
        fetched: false,
        displayDetails: null,
        displayModal: false,
        profileSwitch: false,
        id: null,
        addTo: false,
        portfolios: [],
    }

    getInstrumentId = (
        searchResults: Array<UniversalSearchResultItem>,
        tickerRaw: string,
        exchange?: string
    ) => {
        let id
        const ticker = tickerRaw.toUpperCase()

        if (!searchResults.length) {
            this.props.history.push('/404')
        }

        if (exchange) {
            //exchange is specified.
            searchResults.forEach((item: UniversalSearchResultItem) => {
                if (
                    item.exchangeLocation === `${exchange.toUpperCase()}` &&
                    item.ticker === ticker
                ) {
                    id = item.id
                }
            })
        } else {
            //no exchange is specified

            //strip all companies out with a different ticker
            let resultSet = searchResults.filter(
                (item) => item.ticker === ticker
            )

            //check if there is US company to default to
            resultSet.forEach((result) => {
                if (result.exchangeLocation === 'UNITED_STATES_OF_AMERICA') {
                    id = result.id //return the result if so
                }
            })

            //if there are no US Companies to default to, simply return the first result
            if (!id && resultSet.length) {
                id = resultSet[0].id
            }
        }

        if (id) {
            this.setState({ id: id })
            return id
        } else {
            //if no id has been found, redirect to the 404 page
            this.props.history.push('/404')
        }
    }

    async componentWillMount() {
        //gets the symbol and exchange from the url params, and loads the page accordingly

        const symbol = this.props.match.params.ticker
        const exchange = this.props.match.params.location
        this.fetchPortfolios()

        await axios
            .get(`${config.api}/api/search?query=${symbol}`, {})
            .then((response) => {
                const searchResults =
                    response.data as UniversalSearchResultItem[]

                const id = this.getInstrumentId(
                    searchResults,
                    symbol,
                    exchange
                ) as number
                this.fetchDetailsById(id)
                this.fetchChartById(id)
                this.fetchConstituents(id)
            })
    }

    fetchDetailsById = async (id: number) => {
        await axios.get(`${config.api}/api/tickers/${id}`).then((response) => {
            this.setState({ instrumentDetails: response.data, fetched: true })
        })
    }

    displayDetails = (details: IDetailsAPI) => {
        this.setState({ displayDetails: details, displayModal: true })
    }

    fetchChartById = async (id: number, date: string = 'FIVE_YEARS') => {
        this.setState({ ready: true })
        await axios
            .get(`${config.api}/api/tickers/${id}/chart?period=${date}`)
            .then((response) => {
                let responseData: ChartResponse = response.data

                let chartSeries: Array<{ date: string; close: number }> = []

                responseData.chart.forEach((item: ChartItem) => {
                    chartSeries.push({
                        date: item.label,
                        close: item.sharePrice,
                    })
                })

                this.setState({
                    chart: chartSeries,
                })
            })
    }

    fetchConstituents = async (id: number) => {
        await axios
            .get(`${config.api}/api/tickers/${id}/constituents?size=10000`)
            .then((response: any) => {
                let responseData: IFundHoldingApi[] = response.data.holdings
                let newData = []
                for (const [key, value] of Object.entries(responseData)) {
                    let x: any = value
                    x['holdingpercentage'] = value.holdingPercentage.toString()
                    delete x.financialInstrumentId
                    newData.push(x)
                }
                this.setState({ constituents: newData })
            })
    }

    handleAddToPortfolio = (portfolioList: Array<number>) => {
        const requestData = {
            financialInstrumentId: (this.state.instrumentDetails as any)
                .financialInstrumentId,
            portfolioIds: portfolioList,
        }

        axios
            .post(`${config.api}/api/portfolios/holdings`, requestData, {
                headers: {
                    Authorization: `Bearer ${store.getState().token}`,
                },
            })
            .then((response) => {
                toast.success(
                    `${
                        this.state.instrumentDetails.ticker
                    } has been added to your ${
                        portfolioList.length === 1
                            ? 'portfolio.'
                            : 'portfolios.'
                    }`
                )
                this.setState({ addTo: false })
            })
            .catch((err) => {
                toast.error(
                    `Error, ${this.state.instrumentDetails.ticker} cannot be added to your portfolio`
                )
                this.setState({ addTo: false })
            })
    }

    fetchPortfolios = async () => {
        axios
            .get(`${config.api}/api/portfolios`, {
                headers: {
                    Authorization: `Bearer ${store.getState().token}`,
                },
            })
            .then((response: any) => {
                let data = response.data.portfolios
                let newData: any = []
                data.forEach((item: any) => {
                    item['checked'] = false
                    newData.push(item)
                })

                this.setState({ portfolios: newData })
            })
    }

    handleOnClick = (items: Array<any>) => {
        this.props.history.push(`/${items[0]}`)
    }

    render() {
        const ticker = this.props.history.location.pathname
            .split('/')
            .filter((item) => item)[0]
            .toLowerCase()

        if (
            this.state.fetched &&
            !(ticker === this.state.instrumentDetails.ticker.toLowerCase()) &&
            !(new Cookies().get('ttr_1') === 'set')
        ) {
            //create a new cookies to ensure the page doesn't reset in a loop
            let currentTime = new Date()
            currentTime.setSeconds(currentTime.getSeconds() + 3) // cookie will expire in 5 seconds
            new Cookies().set('ttr_1', 'set', { expires: currentTime })
            window.location.reload() //finally, reload the page
        }

        return (
            <div style={{ backgroundColor: '#f7f7f7' }}>
                <Layout sider={false} subscriberExempt>
                    <ScreeningProfileModal
                        isActive={this.state.profileSwitch}
                        onClose={() => this.setState({ profileSwitch: false })}
                    />
                    {this.state.portfolios.length > 0 && (
                        <AddToPortfolio
                            isActive={this.state.addTo}
                            addToPortfolio={this.handleAddToPortfolio}
                            onClose={() => {
                                this.setState({ addTo: false })
                            }}
                        />
                    )}
                    <Modal
                        isActive={this.state.displayModal}
                        onClose={() => {
                            this.setState({ displayModal: false })
                        }}
                    >
                        <Row>
                            <Col xs />
                            <Col>
                                <span
                                    onClick={() =>
                                        this.setState({
                                            displayModal: false,
                                        })
                                    }
                                    style={{ cursor: 'pointer' }}
                                >
                                    <CloseO width={25} />
                                </span>
                            </Col>
                        </Row>
                        <ViolationDetails details={this.state.displayDetails} />
                    </Modal>
                    {this.state.ready ? (
                        <Grid style={{ marginTop: 60 }}>
                            {this.state.fetched ? (
                                <DetailsHeader
                                    mode={
                                        this.state.instrumentDetails
                                            .hasConstituents
                                            ? 'fund'
                                            : 'security'
                                    }
                                    onAdd={() => {
                                        this.setState({ addTo: true })
                                    }}
                                    instrumentDetails={
                                        this.state.instrumentDetails
                                    }
                                    constituents={this.state.constituents}
                                    description={this.state.description}
                                    {...this.props}
                                />
                            ) : (
                                <></>
                            )}
                            <hr
                                style={{
                                    margin: '30px 0 10px',
                                    border: '.15px solid #e3eaee',
                                }}
                            />
                            {store.getState().isLogged ? (
                                <Row style={{ marginBottom: 20 }}>
                                    <Col style={{ padding: 10 }}>
                                        <p
                                            style={{
                                                fontWeight: 300,
                                                fontSize: 18,
                                                lineHeight: '40px',
                                                display: 'inline',
                                            }}
                                        >
                                            Active Screening Profile:{' '}
                                            <strong>
                                                {
                                                    store.getState()
                                                        .activeProfile.name
                                                }
                                            </strong>
                                        </p>
                                        <Button
                                            type="outlined"
                                            style={{
                                                width: 'auto',
                                                padding: '8px 12px',
                                                marginLeft: 15,
                                                display: 'inline-block',
                                            }}
                                            onClick={() =>
                                                this.setState({
                                                    profileSwitch: true,
                                                })
                                            }
                                        >
                                            <strong>Change</strong>
                                        </Button>
                                    </Col>
                                </Row>
                            ) : (
                                <></>
                            )}
                            <PendingViolations id={this.state.id} />
                            {Object.keys(this.state.instrumentDetails).includes(
                                'esgIssueCriteriaRollup'
                            ) ? (
                                <>
                                    {this.state.fetched ? (
                                        <ESGCriterionFund
                                            instrument={
                                                this.state.instrumentDetails
                                            }
                                        />
                                    ) : (
                                        <></>
                                    )}
                                </>
                            ) : (
                                <>
                                    {this.state.fetched ? (
                                        <ESGCriterion
                                            instrument={
                                                this.state.instrumentDetails
                                            }
                                            displayDetails={this.displayDetails}
                                        />
                                    ) : (
                                        <></>
                                    )}
                                </>
                            )}
                            <Row style={{ paddingTop: 40 }}>
                                <Col xs={12} lg={4}>
                                    {this.state.id ? (
                                        <Fundamentals
                                            ready={this.state.ready}
                                            isFund={Object.keys(
                                                this.state.instrumentDetails
                                            ).includes(
                                                'esgIssueCriteriaRollup'
                                            )}
                                            ticker={this.state.ticker}
                                            financialInstrumentId={
                                                this.state.id
                                            }
                                        />
                                    ) : (
                                        <></>
                                    )}
                                </Col>
                                <Col lg={8} xs={12}>
                                    <div
                                        style={{
                                            padding: '15px 25px',
                                            color: 'white',
                                            fontWeight: '1000',
                                            fontSize: '18px',
                                            margin: 0,
                                            backgroundColor: '#2c8aae',
                                        }}
                                    >
                                        Performance
                                    </div>
                                    <div>
                                        {this.state.chart.length < 5 ? (
                                            <></>
                                        ) : (
                                            <ParentSize
                                                style={{
                                                    backgroundColor: 'white',
                                                }}
                                            >
                                                {(parent) => (
                                                    <BrushChart
                                                        width={parent.width}
                                                        height={600}
                                                        data={[
                                                            ...this.state.chart,
                                                        ]}
                                                    />
                                                )}
                                            </ParentSize>
                                        )}
                                    </div>
                                </Col>
                            </Row>
                            <Row className="condensed-font-style">
                                {Object.keys(
                                    this.state.instrumentDetails
                                ).includes('esgIssueCriteriaRollup') ? (
                                    <>
                                        {this.state.constituents.length ? (
                                            <Table
                                                value="Holdings"
                                                headers={FundHoldingsTable}
                                                dataSource={
                                                    this.state.constituents
                                                }
                                                onClick={this.handleOnClick}
                                                style={{
                                                    cursor: 'pointer',
                                                    padding: 8,
                                                }}
                                            />
                                        ) : (
                                            <p>
                                                {' '}
                                                <LoadingOutlined /> Loading
                                                holdings...
                                            </p>
                                        )}
                                    </>
                                ) : (
                                    <></>
                                )}
                            </Row>
                            <Row style={{ height: 'auto', padding: 8 }}>
                                {this.state.id ? (
                                    <News
                                        financialInstrumentId={this.state.id}
                                        {...this.props}
                                    />
                                ) : (
                                    <></>
                                )}
                            </Row>
                        </Grid>
                    ) : (
                        <div style={{ paddingLeft: '50%', paddingTop: '20%' }}>
                            <ScaleLoader color={'black'} loading={true} />
                        </div>
                    )}
                    <Footer marginTop={20} />
                </Layout>
            </div>
        )
    }
}

export default withRouter(InstrumentDetails)
