import React from 'react'
import { Row, Col } from 'react-flexbox-grid'
import { config } from '../../models/config'

import axios from 'axios'
import Tag from '../../insightui/Tag'
import { IPortfolio } from '../../models/api'
import Tooltip from '../../images/info-circle-light.svg'
import ToolTip from '../../insightui/Tooltip'

interface FundamentalsProps {
    isFund?: boolean
    ready?: boolean
    ticker?: string
    financialInstrumentId?: number
    portfolioResponse?: IPortfolio
}

interface FundResponse {
    peRatio: number | null
    beta: number | null
    yearHigh: number | null
    yearLow: number | null
    dividendYield: number | null
    ttmDividendRate: number | null
    marketCap: number | null
    avgVolume: number | null
}

interface SecurityResponse {
    peRatio: number | null
    beta: number | null
    yearHigh: number | null
    yearLow: number | null
    dividendYield: number | null
    ttmDividendRate: number | null
    marketCap: number | null
    avgVolume: number | null
    currentDebt: number | null
    debtToEquity: number | null
    grossProfit: number | null
    priceToBook: number | null
    profitMargin: number | null
    totalCash: number | null
    employees: number | null
    priceToSales: number | null
}

const TITLES = {
    peRatio: 'P/E Ratio',
    beta: 'Beta',
    yearHigh: '1 Year High',
    yearLow: '1 Year Low',
    dividendYield: 'Dividend Yield',
    ttmDividendRate: 'TTM Dividend Rate',
    marketCap: 'Market Cap',
    avgVolume: 'Average 30 Day Volume',
    currentDebt: 'Current Debt',
    debtToEquity: 'Debt To Equity Ratio',
    grossProfit: 'Gross Profit',
    priceToBook: 'Price To Book Ratio',
    profitMargin: 'Profit Margin',
    totalCash: 'Total Cash',
    employees: 'Employees',
    priceToSales: 'Price To Sales Ratio',
    alpha: 'Alpha',
    corr: 'Correlation',
    downSideDeviation: 'Downside Deviation',
    ftAlpha: 'FT Alpha',
    rSquared: 'R Squared',
    standardDeviation: 'Standard Deviation',
    sortino: 'Sortino',
    treynor: 'Treynor',
    ui: 'UI',
    upi: 'UPI',
}

const definitions = {
    peRatio:
        'Price to earnings ratio is calculated as previous day close price divided by the trailing twelve month earnings per share.',
    beta: 'Beta is a measure used in fundamental analysis to determine the volatility of an asset or portfolio in relation to the overall market. Levered beta calculated with 1 year historical data and compared to SPY.',
    yearHigh:
        'Highest fully adjusted price observed during trading hours over the past 52 calendar weeks',
    yearLow:
        'Lowest fully adjusted price observed during trading hours over the past 52 calendar weeks',
    dividendYield:
        'The ratio of trailing twelve month dividend compared to the previous day close price. The dividend yield is represented as a percentage calculated as (ttmDividendRate) / (previous day close price)',
    ttmDividendRate: 'Trailing twelve month dividend rate per share',
    marketCap:
        'Market cap of the security calculated as shares outstanding * previous day close.',
    avgVolume: 'Average 30 day volume based on calendar days',
    currentDebt: 'Short Term Debt',
    debtToEquity: 'Total Liabilities divided by Shareholders Equity',
    grossProfit:
        'Represents the difference between sales or revenues and cost of goods sold and depreciation.',
    priceToBook:
        'The price-to-book ratio, or P/B ratio, is a financial ratio used to compare a company’s current market price to its book value',
    profitMargin:
        'A measure of profitability by finding the net profit as a percentage of the revenue',
    totalCash: 'Current and Operating Cash',
    employees: 'Number of employees',
    priceToSales:
        'Price–sales ratio, P/S ratio, or PSR, is a valuation metric for stocks. It is calculated by dividing the company’s market capitalization by the revenue in the most recent year; or, equivalently, divide the per-share stock price by the per-share revenue',
    alpha: 'Alpha is used in finance as a measure of performance, indicating when a strategy, trader, or portfolio manager has managed to beat the market return over some period.',
    corr: 'Correlation',
    downSideDeviation:
        'Downside deviation is a measure of downside risk that focuses on returns that fall below a minimum threshold or minimum acceptable return.',
    ftAlpha: 'FT Alpha',
    rSquared:
        "R-squared (R2) is a statistical measure that represents the proportion of the variance for a dependent variable that's explained by an independent variable or variables in a regression model",
    standardDeviation:
        'The standard deviation is a statistic that measures the dispersion of a dataset relative to its mean and is calculated as the square root of the variance.',
    sortino:
        "The Sortino ratio is a variation of the Sharpe ratio that differentiates harmful volatility from total overall volatility by using the asset's standard deviation of negative portfolio returns—downside deviation—instead of the total standard deviation of portfolio returns",
    treynor:
        'The Treynor ratio, also known as the reward-to-volatility ratio, is a performance metric for determining how much excess return was generated for each unit of risk taken on by a portfolio.',
    ui: 'UI',
    upi: 'UPI',
}

export const numberFormatter = (
    num: number,
    label: string,
    portfolioResponse?: IPortfolio
): any => {
    if (portfolioResponse) {
        return num
    }
    const BREAKPOINTS: any = {
        '1000': 'K',
        '1000000': 'M',
        '1000000000': 'B',
        '1000000000000': 'T',
    }
    let dollarTerms = ['high', 'low', 'market', 'current', 'cash']

    let returnString = ''
    let formattedValues: any = []
    Object.keys(BREAKPOINTS).forEach((breakpoint: string) => {
        if (num / parseInt(breakpoint) >= 1) {
            formattedValues.push(
                (num / parseInt(breakpoint)).toFixed(2).toString() +
                    BREAKPOINTS[breakpoint]
            )
        }
    })
    if (formattedValues.length) {
        returnString = formattedValues[formattedValues.length - 1]
    } else {
        try {
            returnString = num.toFixed(2).toString()
        } catch {
            returnString = ''
        }
    }

    //check if the item should be a dollar value:
    dollarTerms.forEach((item) => {
        if (label.toLowerCase().includes(item) && !returnString.includes('$')) {
            returnString = returnString.replace(/^/, '$')
        }
    })

    return returnString
}

export default class Fundamentals extends React.Component<FundamentalsProps> {
    state: { data: FundResponse | SecurityResponse | IPortfolio } = {
        data: {} as SecurityResponse,
    }

    removeNullValues = (obj: Object) =>
        Object.fromEntries(Object.entries(obj).filter(([_, v]) => v != null))

    componentDidMount() {
        if (this.props.portfolioResponse) {
            this.setState({
                data: this.removeNullValues(this.props.portfolioResponse),
            })
        } else {
            axios
                .get(
                    `${config.api}/api/tickers/${this.props.financialInstrumentId}/fundamentals`
                )
                .then((response) => {
                    this.setState({
                        data: this.removeNullValues(response.data),
                    })
                })
        }
    }

    render() {
        return (
            <div>
                <Row>
                    <Tag
                        color="blue"
                        style={{
                            color: 'white',
                            fontWeight: '1000',
                            padding: '18px 25px',
                            margin: '0 8px',
                            letterSpacing: '2px',
                            fontSize: 15,
                            textTransform: 'uppercase',
                            width: '100%',
                        }}
                    >
                        Key Facts
                    </Tag>
                </Row>
                {this.props.ready && !Object.keys(this.state.data).length && (
                    <Row
                        style={{
                            backgroundColor: 'white', //'#e3eaef
                            margin: 0,
                        }}
                    >
                        <Col xs style={{ padding: 10 }}>
                            <span
                                style={{
                                    fontSize: 15,
                                    fontWeight: 300,
                                }}
                            >
                                There are no known key facts to report.
                            </span>
                        </Col>{' '}
                    </Row>
                )}
                {this.props.portfolioResponse && (
                    <Row
                        style={{
                            height: 35,
                            paddingTop: 8,
                            margin: '0',
                            backgroundColor: 'white', //'#e3eaef
                        }}
                    >
                        <Col xs={7}>
                            <span style={{ fontSize: 14 }}>
                                <strong>Benchmark</strong>
                            </span>
                        </Col>
                        <Col xs={4}>
                            <span
                                style={{
                                    fontSize: 15,
                                    fontWeight: 300,
                                }}
                            >
                                S&P 500 Div Adj
                            </span>
                        </Col>{' '}
                    </Row>
                )}
                {Object.keys(this.state.data)
                    .filter(
                        (key) =>
                            key != 'chart' &&
                            (this.state.data as any)[key] != 'NaN'
                    )
                    .map((key, index) => (
                        <>
                            <Row
                                style={{
                                    height: 35,
                                    paddingTop: 8,
                                    margin: 0,
                                    backgroundColor:
                                        index % 2 === 0 ? '#e3eaef' : 'white',
                                }}
                            >
                                <Col xs={7}>
                                    <span style={{ fontSize: 14 }}>
                                        <strong>{(TITLES as any)[key]}</strong>
                                    </span>
                                    <span>
                                        <ToolTip
                                            size={18}
                                            icon={
                                                <img
                                                    style={{
                                                        marginTop: 12,
                                                        marginLeft: 10,
                                                    }}
                                                    src={Tooltip}
                                                    width={14}
                                                    height={14}
                                                    alt=""
                                                />
                                            }
                                            value={definitions[key]}
                                        />
                                    </span>
                                </Col>
                                <Col xs={4}>
                                    <span
                                        style={{
                                            fontSize: 15,
                                            fontWeight: 300,
                                        }}
                                    >
                                        {/* {(this.state.data as any)[key]} */}
                                        {numberFormatter(
                                            (this.state.data as any)[key],
                                            (TITLES as any)[key],
                                            this.props.portfolioResponse
                                        )}
                                    </span>
                                </Col>
                            </Row>
                        </>
                    ))}
            </div>
        )
    }
}
